import CheckIcon from "@mui/icons-material/Check";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { Checkmark } from "react-checkmark";
import MenuAutocomplete from "src/components/denseForm/MenuAutocomplete";
import { NewTextFormField } from "src/components/form/TextField";
import useLocales from "src/hooks/useLocales";
import { ReviewContext } from "../../context/ReviewContext";
import useAsyncExpectedValues from "../../hooks/useAsyncExpectedValues";
import { ExpectedValues } from "../../types/reviewContext";
import { Item } from "../../types/reviewResponse";

const EditDataDescription = ({
  bgColor,
  title,
  setEdit,
  isHighlighted,
  item,
  getFieldValue,
  optionRef,
  ...props
}: any) => {
  const { translate } = useLocales();
  const [copied, setCopied] = useState(false);
  let editValue = structuredClone(item);
  const [value, setValue] = useState<any>(editValue.value);
  const [multiSelectValue, setMultiSelectValue] = useState<string[]>(
    editValue?.value?.split(",") ?? []
  );
  const reviewContext = useContext(ReviewContext);
  const { reviewResponse } = reviewContext;
  const { dispatch } = reviewResponse;
  const {
    hasExpectedValues,
    expectedValuesOption,
    selectOption,
    setSelectOption,
    getASyncExpectedValues,
    totalCount,
    loading,
    searchFields,
    optionErrorMsg,
    onOpen,
    enableSearch,
  } = useAsyncExpectedValues({
    editValue,
    getFieldValue,
  });

  const getTranslatedFields = (title: string) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") === -1) {
      return result;
    }
    return title;
  };

  const handleClick = useCallback(() => {
    setCopied(true);
    if (editValue.isMultiSelect) {
      if (multiSelectValue && editValue.isFieldReviewRequired) {
        editValue.confidence = 0.9;
      }
      editValue.value = multiSelectValue?.filter?.((m) => m)?.join(",");
    } else {
      if (value && editValue.isFieldReviewRequired) {
        editValue.confidence = 0.9;
      }
      editValue.value = value;
    }
    editValue.expectedFieldLabel = selectOption?.label ?? "";
    props.setFieldValue(props.name, { ...editValue });
    if (item.value !== value) {
      if (
        editValue.itemsFields == null ||
        ["AccountClass", "DocumentClass"]?.includes(editValue.name)
      ) {
        dispatch({
          type: "ADD_FIELD",
          payload: [
            {
              name: editValue.name,
              value: editValue.value,
              pageNumber: editValue.pageNumber,
              confidence: 0.9,
              isRemoved: false,
              isNew: false,
              itemsFields: null,
              boundingPolygon: editValue.boundingPolygon,
            },
          ],
        });
      } else {
        let keys = editValue.key?.split(".");
        let row = getFieldValue(
          `fields.${keys[1]}.itemsFields.${keys[3]}.item`
        );
        let items: Item[] = [];
        row.forEach((item: Item) => {
          let item_: Item = {
            boundingPolygon: item.boundingPolygon,
            confidence: item.confidence,
            isRemoved: item.isRemoved,
            isNew: false,
            name: item.name,
            value: item.value,
            pageNumber: item.pageNumber,
            expectedFieldLabel: item.expectedFieldLabel,
          };
          if (item.name === editValue.name) {
            item_.value = editValue.value;
          }
          items.push(item_);
        });
        dispatch({
          type: "ADD_FIELD",
          payload: [
            {
              name: editValue.category,
              value: null,
              pageNumber: editValue.pageNumber,
              confidence: null,
              isRemoved: false,
              isNew: false,
              itemsFields: [
                {
                  index: editValue.itemsFieldIndex,
                  isRemoved: false,
                  isNew: false,
                  item: items,
                },
              ],
            },
          ],
        });
      }
    }
    setTimeout(() => {
      setCopied(false);
      setEdit(false);
    }, 2000);
  }, [value, multiSelectValue, editValue]);

  return (
    <>
      <TableCell sx={{ width: "3%", p: 0.5 }}>
        <div
          style={{
            height: "15px",
            width: "15px",
            background: bgColor,
            borderRadius: "50%",
            marginRight: "0.2rem",
          }}
        ></div>
      </TableCell>
      <TableCell sx={{ width: "40%" }} onClick={() => setEdit(false)}>
        <Typography
          style={{ fontSize: "11px", wordBreak: "break-word" as "break-word" }}
        >
          {getTranslatedFields(title)}
        </Typography>
      </TableCell>
      <TableCell sx={{ pl: 0, pr: 1, width: "50%" }}>
        {hasExpectedValues ? (
          <>
            {editValue.isMultiSelect ? (
              <FormControl variant="outlined" size="small" fullWidth>
                <InputLabel
                  id={props.name}
                  sx={{
                    fontSize: "12px",
                  }}
                  className="form-label"
                >
                  {"Value"}
                </InputLabel>
                <Select
                  label="Value"
                  onChange={(e) => {
                    setMultiSelectValue(
                      typeof e.target.value === "string"
                        ? e.target.value.split(",")
                        : e.target.value
                    );
                  }}
                  value={multiSelectValue}
                  fullWidth
                  multiple
                  size="small"
                >
                  {expectedValuesOption.map(
                    (item: ExpectedValues, index: number) => {
                      return (
                        <MenuItem
                          sx={{
                            fontSize: "11px",
                          }}
                          key={index}
                          value={item.value}
                        >
                          {translate(item.description)}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            ) : (
              <MenuAutocomplete
                ref={optionRef}
                dense="dense"
                value={selectOption}
                variant={"secondary"}
                optionTitle={getTranslatedFields(title)}
                getOptionLabel={(option: any) => option?.label ?? ""}
                onChange={(event, newValue) => {
                  if (newValue != null && typeof newValue !== "string") {
                    setValue(newValue.value);
                    setSelectOption({
                      label: newValue.label,
                      value: newValue.value,
                    });
                  } else if (newValue === null) {
                    setValue("");
                    setSelectOption({
                      label: "",
                      value: "",
                    });
                  }
                }}
                onSearch={getASyncExpectedValues}
                totalCount={totalCount}
                loading={loading}
                options={expectedValuesOption}
                optionErrorMsg={translate(optionErrorMsg, {
                  parentField: searchFields.parentField,
                  field: searchFields.field,
                })}
                onOpen={onOpen}
                enableSearch={enableSearch}
              />
            )}
          </>
        ) : (
          <NewTextFormField
            multiline
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        )}
      </TableCell>
      <TableCell sx={{ width: "4%", p: 0.5 }}>
        {copied ? (
          <Checkmark size="small" sx={{ m: 0, p: 0 }} />
        ) : (
          <CheckIcon
            sx={{
              height: "18px",
              cursor: "pointer",
              color: "#2e435e",
              "&:hover": { color: "blue" },
            }}
            onClick={handleClick}
          />
        )}
      </TableCell>
    </>
  );
};

export default EditDataDescription;
