import { Box } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import MenuAutocomplete from "src/components/denseForm/MenuAutocomplete";
import NewTextField from "src/components/form/TextField";
import useLocales from "src/hooks/useLocales";
import { ReviewContext } from "../../context/ReviewContext";
import useAsyncExpectedValues from "../../hooks/useAsyncExpectedValues";

export default function LowConfidence({
  title,
  item,
  getFieldValue,
  setFieldValue,
}) {
  const { translate } = useLocales();
  let value = getFieldValue(item.key);
  const [inputValue, setInputValue] = useState(value?.value || "");
  const getTranslatedFields = (title) => {
    let result = translate(`review_screen.review_fields.${title}`);
    if (result.indexOf("review_screen") === -1) {
      return result;
    }
    return title;
  };
  useEffect(() => {
    setInputValue(value?.value || "");
    setSelectOption({
      label: value?.expectedFieldLabel,
      value: value?.value,
    });
  }, [value]);
  const reviewContext = useContext(ReviewContext);
  const { reviewResponse } = reviewContext;
  const { dispatch } = reviewResponse;

  const {
    hasExpectedValues,
    expectedValuesOption,
    selectOption,
    setSelectOption,
    getASyncExpectedValues,
    totalCount,
    loading,
    searchFields,
    optionErrorMsg,
    onOpen,
    enableSearch,
  } = useAsyncExpectedValues({
    editValue: value,
    getFieldValue,
  });
  return (
    <>
      <Box className="mt-2 me-2">
        {hasExpectedValues ? (
          <MenuAutocomplete
            multiline={false}
            value={selectOption}
            label={getTranslatedFields(title)}
            optionTitle={getTranslatedFields(title)}
            getOptionLabel={(option: any) => option?.label ?? ""}
            onChange={(event, newValue) => {
              if (newValue != null && typeof newValue !== "string") {
                setInputValue(newValue.value);
                setFieldValue(`${item.key}`, {
                  ...value,
                  value: newValue.value,
                  expectedFieldLabel: newValue?.label,
                  confidence: 0.9,
                });
                setSelectOption({
                  label: newValue.label,
                  value: newValue.value,
                });
                dispatch({
                  type: "ADD_FIELD",
                  payload: [
                    {
                      name: item.name,
                      value: newValue.value,
                      pageNumber: item.pageNumber,
                      confidence: 0.9,
                      isNew: false,
                      isRemoved: false,
                      itemsFields: null,
                      boundingPolygon: item.boundingPolygon,
                    },
                  ],
                });
              } else if (newValue === null) {
                setInputValue("");
                setSelectOption({
                  label: "",
                  value: "",
                });
                setFieldValue(`${item.key}`, {
                  ...value,
                  value: "",
                  expectedFieldLabel: "",
                  confidence: 0.9,
                });
                dispatch({
                  type: "ADD_FIELD",
                  payload: [
                    {
                      name: item.name,
                      value: "",
                      pageNumber: item.pageNumber,
                      confidence: 0.9,
                      isNew: false,
                      isRemoved: false,
                      itemsFields: null,
                      boundingPolygon: item.boundingPolygon,
                    },
                  ],
                });
              }
            }}
            error={!inputValue ? translate("This is a required field") : ""}
            onSearch={getASyncExpectedValues}
            totalCount={totalCount}
            loading={loading}
            options={expectedValuesOption}
            optionErrorMsg={translate(optionErrorMsg, {
              parentField: searchFields.parentField,
              field: searchFields.field,
            })}
            onOpen={onOpen}
            enableSearch={enableSearch}
          />
        ) : (
          <NewTextField
            label={getTranslatedFields(title)}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onBlur={() => {
              setFieldValue(`${item.key}`, {
                ...value,
                value: inputValue,
                confidence: 0.9,
              });
              if (inputValue !== value?.value) {
                dispatch({
                  type: "ADD_FIELD",
                  payload: [
                    {
                      name: item.name,
                      value: inputValue,
                      pageNumber: item.pageNumber,
                      confidence: 0.9,
                      isNew: false,
                      isRemoved: false,
                      itemsFields: null,
                      boundingPolygon: item.boundingPolygon,
                    },
                  ],
                });
              }
            }}
            value={inputValue}
            error={!inputValue ? translate("This is a required field") : ""}
            helperText={
              !inputValue ? translate("This is a required field") : ""
            }
          />
        )}
      </Box>
    </>
  );
}
