import { Theme } from "@mui/material/styles";
//
import {
  CheckboxCheckedIcon,
  CheckboxIcon,
  CheckboxIndeterminateIcon,
} from "./CustomIcons";

// ----------------------------------------------------------------------

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
        indeterminateIcon: <CheckboxIndeterminateIcon />,
      },

      styleOverrides: {
        root: {
          padding: theme.spacing(0.5),
          "&.Mui-disabled": {
            color: theme.palette.action.disabled, // Apply the disabled color
            opacity: 0.5, // Make it visually distinct
          },
          "&.Mui-checked.Mui-disabled": {
            color: theme.palette.action.disabled, // Customize checked + disabled
            opacity: 0.5,
          },
          "& .MuiSvgIcon-fontSizeMedium": {
            width: 24,
            height: 24,
          },
          "& .MuiSvgIcon-fontSizeSmall": {
            width: 20,
            height: 20,
          },
        },
      },
    },
  };
}
