import csv from "src/assets/icon/fileExt/csvIcon.png";
import { ReactComponent as Doc } from "src/assets/icon/fileExt/doc.svg";
import { ReactComponent as File } from "src/assets/icon/fileExt/file.svg";
import { ReactComponent as Image } from "src/assets/icon/fileExt/img.svg";
import { ReactComponent as Mp3 } from "src/assets/icon/fileExt/mp3.svg";
import { ReactComponent as Mp4 } from "src/assets/icon/fileExt/mp4.svg";
import { ReactComponent as PDF } from "src/assets/icon/fileExt/pdf.svg";
import xml from "src/assets/icon/fileExt/xml.png";

export const FileExtension = ({ ext, ...props }) => {
  switch (ext?.toLowerCase()) {
    case "pdf":
      return <PDF {...props} />;
    case "doc":
      return <Doc {...props} />;
    case "docx":
      return <Doc {...props} />;
    case "mp3":
      return <Mp3 {...props} />;
    case "mp4":
      return <Mp4 {...props} />;
    case "png":
      return <Image {...props} />;
    case "jpg":
      return <Image {...props} />;
    case "jpeg":
      return <Image {...props} />;
    case "gif":
      return <Image {...props} />;
    case "svg":
      return <Image {...props} />;
    case "xml":
      return (
        <img
          src={xml}
          width={"28px"}
          height={"24px"}
          style={{ marginLeft: "-2px" }}
        />
      );
    case "csv":
      return (
        <img
          src={csv}
          width={"40px"}
          height={"38px"}
          style={{ marginLeft: "-2px" }}
        />
      );
    default:
      return <File {...props} />;
  }
};
