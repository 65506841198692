import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  styled,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Fragment, useState } from "react";
import EditIcon from "src/assets/icon/edit";
import AlertIllustration from "src/assets/illustrations/infoalertIllustrations";
import CloseButton from "src/components/CloseButton";
import MenuAutocomplete from "src/components/denseForm/MenuAutocomplete";
import { NewTextFormField } from "src/components/textField";
import useLocales from "src/hooks/useLocales";
import useAsyncExpectedValues from "src/pages/formvalidation/hooks/useAsyncExpectedValues";
import DeleteIcon from "../../../../../assets/icon/delete";

export default function TableValues(props: {
  row: any;
  header: Array<any>;
  deleteRow: any;
  i: number;
  rows: any;
  dispatch: any;
  length: number;
  page: number;
  enableDeleteColumn?: boolean;
  getFieldValue;
  getTranslatedFields;
}) {
  const {
    row,
    i,
    rows,
    dispatch,
    enableDeleteColumn,
    getFieldValue,
    getTranslatedFields,
  } = props;
  const { translate } = useLocales();
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    setOpen(true);
  };

  const theme = useTheme();

  return (
    <TableRow
      onDoubleClick={() => {
        setEdit(!edit);
      }}
      key={i + 1 + "_"}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <CloseButton handleClose={handleClose} />
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <AlertIllustration />
          </Box>
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ fontSize: "13px", color: "text.greyLight" }}
          >
            {translate("Are you sure you want to delete this row?")}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            pt: 1,
          }}
        >
          <Button size="small" variant="outlined" onClick={handleClose}>
            {translate("Cancel")}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              props.deleteRow(i + props.page);
              handleClose();
            }}
            autoFocus
          >
            {translate("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <TableCell
        sx={{
          fontSize: 10,
          fontWeight: "light",
        }}
      >
        {props.page + i + 1}
      </TableCell>
      {props.header?.map((k, idx) => {
        return (
          <Fragment key={"cell-" + k + "-" + idx}>
            <TableCell key={row?.line + "_" + k} align="left">
              <Box
                sx={{
                  maxWidth: 300,
                  minWidth: 100,
                  width: "max-content",
                }}
              >
                {edit ? (
                  <EditValue
                    {...{
                      value: row[k]?.value,
                      row,
                      k,
                      idx,
                      dispatch,
                      rows,
                      i,
                      getFieldValue,
                      getTranslatedFields,
                    }}
                  />
                ) : (
                  <Typography
                    sx={{
                      fontSize: 11,
                      fontWeight: "light",
                    }}
                  >
                    {row[k]?.expectedFieldLabel || row[k]?.value}
                  </Typography>
                )}
              </Box>
            </TableCell>
            {enableDeleteColumn && <TableCell></TableCell>}
          </Fragment>
        );
      })}
      <TableCell align="right">
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          {!edit ? (
            <IconButton
              aria-label="edit"
              onClick={() => {
                setEdit(true);
              }}
              size="small"
            >
              <EditIcon
                height={20}
                width={20}
                customColor={theme.palette.action.active}
              />
            </IconButton>
          ) : (
            <></>
          )}
          <IconButton
            aria-label="delete"
            onClick={() => handleDelete()}
            size="small"
          >
            <DeleteIcon
              height={20}
              width={20}
              customColor={theme.palette.action.active}
            />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

const EditValue = ({
  value,
  row,
  k,
  dispatch,
  i,
  getFieldValue,
  getTranslatedFields,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const { translate } = useLocales();
  const {
    hasExpectedValues,
    expectedValuesOption,
    selectOption,
    setSelectOption,
    getASyncExpectedValues,
    totalCount,
    loading,
    searchFields,
    optionErrorMsg,
    onOpen,
    enableSearch,
  } = useAsyncExpectedValues({
    editValue: {
      name: k,
      value: row?.[k]?.value,
      expectedFieldLabel: row?.[k]?.expectedFieldLabel,
      expectedValues: row?.[k]?.expectedValues,
      key: row?.[k]?.key,
    },
    getFieldValue,
    row,
  });
  const handleBlur = () => {
    console.log("onblur");
    dispatch({
      type: "SET_ROW",
      payload: {
        row,
        k,
        i,
        inputValue,
        expectedFieldLabel: "",
      },
    });
    dispatch({
      type: "EDIT_FIELD",
      payload: {
        key: row[k].key,
        name: k,
        value: inputValue,
      },
    });
  };

  if (hasExpectedValues) {
    return (
      <MenuAutocomplete
        value={selectOption}
        dense={"dense"}
        sx={{
          width: "200px",
        }}
        variant="secondary"
        optionTitle={getTranslatedFields(k)}
        disableClearable
        onChange={(event, newValue) => {
          if (newValue != null && typeof newValue !== "string") {
            setInputValue(newValue.value);
            setSelectOption({
              label: newValue.label,
              value: newValue.value,
            });
          } else if (newValue === null) {
            setInputValue("");
            setSelectOption({
              label: "",
              value: "",
            });
          }
          dispatch({
            type: "SET_ROW",
            payload: {
              row,
              k,
              i,
              inputValue: newValue?.value ?? "",
              expectedFieldLabel: newValue?.label ?? "",
            },
          });
          dispatch({
            type: "EDIT_FIELD",
            payload: {
              key: row[k].key,
              name: k,
              value: newValue?.value ?? "",
              expectedFieldLabel: newValue?.label ?? "",
            },
          });
        }}
        onSearch={getASyncExpectedValues}
        totalCount={totalCount}
        loading={loading}
        options={expectedValuesOption}
        optionErrorMsg={translate(optionErrorMsg, {
          parentField: searchFields.parentField,
          field: searchFields.field,
        })}
        onOpen={onOpen}
        enableSearch={enableSearch}
      />
    );
  }
  return (
    <NewTextFormField
      value={inputValue}
      multiline
      sx={{
        width: "200px",
        padding: "5px 10px 5px 10px",
      }}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      onBlur={handleBlur}
    />
  );
};

const StyledPaper = styled(Paper)({
  width: "max-content",
  minWidth: "300px",
});
