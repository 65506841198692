import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import { ReactComponent as HistoryIcon } from "src/assets/icon/history.svg";
import { FileExtension } from "src/components/FileExtension";
import useLocales from "src/hooks/useLocales";
import OppositeContentTimeline from "./Timeline";

const HistoryDrawer = ({
  openDrawer,
  selectedRow,
  selectedRowId,
  handleDrawerClose,
}) => {
  const theme = useTheme();
  const { translate } = useLocales();
  let { fileName, id } = selectedRow || {};
  return (
    <>
      <Drawer
        open={openDrawer}
        variant="persistent"
        anchor="right"
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              sm: 500,
              md: 500,
              lg: 500,
              xl: 500,
            },
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
            bgcolor: "background.paper",
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.standard,
              }),
            boxShadow: (theme) => theme.customShadows.z24,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
          >
            <HistoryIcon stroke={theme.palette.action.active} />
            <Typography variant="h6">
              {translate("Document History")}
            </Typography>
          </Box>
          <IconButton sx={{ width: 10, height: 8 }} onClick={handleDrawerClose}>
            <CloseModalButton />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            overflow: "auto",
            height: "calc(100vh - 64px)",
          }}
        >
          <Box
            sx={{
              padding: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <Box p={0.25}>
                <FileExtension
                  ext={fileName?.split(".").pop()}
                  height="25px"
                  width={"25px"}
                  style={{
                    color: theme.palette.text.greyLight,
                  }}
                />
              </Box>
              <Typography variant="subtitle2">{fileName}</Typography>
            </Box>
          </Box>
          <OppositeContentTimeline id={selectedRowId} />
        </Box>
      </Drawer>
    </>
  );
};

export default HistoryDrawer;
