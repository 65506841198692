import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import { Box, MenuItem, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DocumentsEntity } from "src/@types/processedDocuments";
import { DocumentIcon } from "src/assets/icon/Document";
import { OpenIcon } from "src/assets/icon/Open";
import ChevronRightIcon from "src/assets/icon/chevron/right";
import DeleteIcon from "src/assets/icon/delete";
import CSVIcon from "src/assets/icon/documentType/CSV";
import ExcelIcon from "src/assets/icon/documentType/ExcelIcon";
import TemplateIcon from "src/assets/icon/documentType/TemplateIcon";
import TXTIcon from "src/assets/icon/documentType/TxtIcon";
import XMLIcon from "src/assets/icon/documentType/XMLIcon";
import ZIPIcon from "src/assets/icon/documentType/ZipIcon";
import { DownloadIcon } from "src/assets/icon/download";
import EyeOpenIcon from "src/assets/icon/eye/On";
import { ReactComponent as HistoryIcon } from "src/assets/icon/history.svg";
import JsonIcon from "src/assets/icon/json";
import { GetAsync } from "src/common/httpRequests";
import MenuPopover from "src/components/MenuPopover";
import { TableMoreMenu } from "src/components/table";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import { store } from "src/redux/store";
import { applicationTypeMap, exportTemplateExtensionMap } from "./table";

interface TableMenuProps {
  review: (fileId: string) => Promise<void>;
  checkDisable: boolean;
  row: DocumentsEntity;
  onExportDocument: () => void;
  GetBlobUrlFrom: (url: string) => Promise<void>;
  onDeleteSingleRow: VoidFunction;
  onViewDiscrepancyDrawer: (row: DocumentsEntity, id: string) => void;
  onViewHistory: (row: DocumentsEntity, id: string) => void;
  onMakePayments: (docId: string) => void;
  isDeleted: boolean;
  permissionHandle: (permissions: string[]) => boolean;
}

const FileDownload = require("js-file-download");

const TableMenu = ({
  review,
  checkDisable,
  row,
  onExportDocument,
  GetBlobUrlFrom,
  onDeleteSingleRow,
  onViewDiscrepancyDrawer,
  onViewHistory,
  onMakePayments,
  isDeleted,
  permissionHandle,
}: TableMenuProps) => {
  const {
    id,
    fileName,
    exportTemplateExtension,
    enableDiscrepancyDetection,
    documentStatus,
    url,
  } = row;

  const theme = useTheme();

  const {
    org: { enablePaymentFlow },
  } = store.getState()?.organization;
  const paymentWrite = permissionHandle(["Payments_Write"]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [downloadSubmenuAnchorEl, setDownloadSubmenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const handleDownloadSubmenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setDownloadSubmenuAnchorEl(event.currentTarget);
  };

  const handleDownloadSubmenuClose = () => {
    setDownloadSubmenuAnchorEl(null);
  };

  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const exportCSV = async (id: string) => {
    await GetAsync(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/${id}/csv`,
      true
    )
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          let extension =
            applicationTypeMap[response.headers?.["content-type"]] ?? "csv";
          let file: Blob | string;
          if (extension == "zip") {
            const url = new Blob([response.data], { type: "application/zip" });
            FileDownload(url, `${fileName}.zip`);
            return;
          }
          if (extension == "json") {
            file = `data:text/json;charset=utf-8,${encodeURIComponent(
              response?.data?.text?.() ?? JSON.stringify(response.data)
            )}`;
          } else {
            let blob = new Blob([response.data], {
              type: response.headers?.["content-type"] ?? "application/csv",
            });
            file = window.URL.createObjectURL(blob);
          }
          const link = document.createElement("a");
          link.setAttribute("href", file);
          link.setAttribute("download", `${fileName}.${extension ?? "csv"}`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          enqueueSnackbar(response?.data?.Detail, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar(translate(`something_went_wrong`), {
          variant: "error",
        });
      });
  };

  const exportJSON = async (id: string) => {
    await GetAsync(
      `${API_GATEWAY}/api/documentservice/DocumentDownload/${id}/json`
    )
      .then((response) => {
        console.log("response", response);
        if (response.status === 200) {
          const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(response.data)
          )}`;
          const link = document.createElement("a");
          link.setAttribute("href", jsonString);
          link.setAttribute("download", `${fileName}.json`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          enqueueSnackbar(response?.data?.Detail, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar(translate(`something_went_wrong`), {
          variant: "error",
        });
      });
  };

  return (
    <>
      <MenuPopover
        disabledArrow
        open={Boolean(downloadSubmenuAnchorEl)}
        anchorEl={downloadSubmenuAnchorEl}
        onClose={handleDownloadSubmenuClose}
        anchorOrigin={{ vertical: "top", horizontal: -10 }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorReference="anchorEl"
        arrow="right-top"
        sx={{
          mt: -1,
          width: 230,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            "& svg": { mr: "16px !important", width: 20, height: 20 },
            fontWeight: "bold",
          },
        }}
      >
        <MenuItem
          disabled={checkDisable}
          onClick={() => {
            GetAsync(
              `${API_GATEWAY}/api/documentservice/DocumentDownload/${id}/downloadfile`,
              true,
              undefined,
              {
                responseType: "blob",
              }
            ).then((response) => {
              if (response.status === 200) {
                console.log("response", response);
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                console.log("url", url);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${fileName}`);
                document.body.appendChild(link);
                link.click();
              } else {
                enqueueSnackbar(response?.data?.Detail, {
                  variant: "error",
                });
              }
            });
            handleDownloadSubmenuClose();
            handleCloseMenu();
          }}
        >
          <DocumentIcon />
          {translate("Download document")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportCSV(id);
            handleDownloadSubmenuClose();
            handleCloseMenu();
          }}
          disabled={checkDisable}
        >
          {exportTemplateIconMap[exportTemplateExtension.toLowerCase()] ?? (
            <DownloadIcon />
          )}
          {`${translate("Download")} ${
            translate(
              exportTemplateExtensionMap[exportTemplateExtension.toLowerCase()]
            ) ?? "CSV"
          }`}
        </MenuItem>
        <MenuItem
          disabled={checkDisable}
          onClick={() => {
            exportJSON(id);
            handleDownloadSubmenuClose();
            handleCloseMenu();
          }}
        >
          <JsonIcon disableDimension />
          {translate("Download JSON")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onExportDocument();
            handleDownloadSubmenuClose();
            handleCloseMenu();
          }}
        >
          <TemplateIcon disableDimension />
          {translate("download_via_template")}
        </MenuItem>
      </MenuPopover>

      <TableMoreMenu
        open={anchorEl}
        onOpen={handleOpenMenu}
        onClose={handleCloseMenu}
        actions={
          <>
            <MenuItem
              disabled={checkDisable}
              onClick={() => {
                review(id);
                handleCloseMenu();
              }}
            >
              <EyeOpenIcon disableDimentions />
              {translate("Review document")}
            </MenuItem>
            <MenuItem
              onClick={handleDownloadSubmenuOpen}
              selected={Boolean(downloadSubmenuAnchorEl)}
            >
              <DownloadIcon />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                {translate("Download")}
                <ChevronRightIcon
                  height={20}
                  width={20}
                  sx={{
                    "& .MuiMenuItem-root": {
                      px: 1,
                      typography: "body2",
                      borderRadius: 0.75,
                      "& svg": { mr: 0, width: 20, height: 20 },
                      fontWeight: "bold",
                    },
                  }}
                />
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                GetBlobUrlFrom(url);
                handleCloseMenu();
              }}
            >
              <OpenIcon disableDimension />
              {translate("View document")}
            </MenuItem>
            {enableDiscrepancyDetection && (
              <MenuItem
                disabled={checkDisable}
                onClick={() => {
                  onViewDiscrepancyDrawer(row, id);
                  handleCloseMenu();
                }}
              >
                <DocumentIcon />
                {translate(`view_discrepancies`)}
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                onViewHistory(row, row.id);
                handleCloseMenu();
              }}
            >
              <HistoryIcon stroke={theme.palette.action.active} />
              {translate("View history")}
            </MenuItem>
            {row?.documentClass === "Invoice" &&
              enablePaymentFlow &&
              paymentWrite && (
                <MenuItem
                  onClick={() => {
                    onMakePayments(id);
                    handleCloseMenu();
                  }}
                >
                  <EuroSymbolIcon />
                  {translate(`make_payment`)}
                </MenuItem>
              )}
            <MenuItem
              disabled={isDeleted}
              onClick={() => {
                handleCloseMenu();
                onDeleteSingleRow();
              }}
            >
              <DeleteIcon
                customColor={theme.palette.action.active}
                disableDimension
              />
              {translate("delete")}
            </MenuItem>
          </>
        }
      />
    </>
  );
};
export default TableMenu;
export const exportTemplateIconMap = {
  csv: <CSVIcon disableDimension />,
  xlsx: <ExcelIcon disableDimension />,
  xml: <XMLIcon disableDimension />,
  txt: <TXTIcon disableDimension />,
  json: <JsonIcon disableDimension />,
  zip: <ZIPIcon disableDimension />,
};
