import { exportConfiguration } from "./exportConfiguration";
import { global } from "./global";
import { mappings } from "./mappings";
import { suppliers } from "./suppliers";
import { transactions } from "./transactions";
const es = {
  ...global,
  suppliers: { ...suppliers },
  mappings: { ...mappings },
  exportConfiguration: { ...exportConfiguration },
  transactions: {
    ...transactions,
  },
};

export default es;
