import { Box, SvgIconProps, useTheme } from "@mui/material";

export const UploadIcon = ({
  color = "action",
  height = 24,
  width = 24,
  customColor,
}: SvgIconProps & {
  customColor?: string;
}) => {
  const theme = useTheme();
  return (
    <Box>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.5469 5.19682L11.9985 1.64844L11.9985 13.9056M8.45138 5.19683L11.9998 1.64844M17 9.0027H18C20.2091 9.0027 22 10.7936 22 13.0027V17.0027C22 19.2118 20.2091 21.0027 18 21.0027H6C3.79086 21.0027 2 19.2118 2 17.0027L2 13.0027C2 10.7936 3.79086 9.0027 6 9.0027H7"
          stroke={customColor ?? theme.palette?.[color]?.active}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};
