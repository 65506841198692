export const global = {
  complete: `Completar`,
  address: `Dirección`,
  email: `Correo electrónico`,
  accepted: `Aceptado`,
  not_accepted: `No aceptado`,
  individual_information: `Información individual`,
  full_name: `Nombre completo`,
  nationality: `Nacionalidad`,
  document: `Documento`,
  terms_and_conditions: `Términos y condiciones`,
  updated_by: `Actualizado por`,
  updated_on: `Actualizado el`,
  last_updated_by: `Última actualización realizada por {{updatedBy}} el {{lastUpdated}}`,
  last_updated_on: `Última actualización: {{lastUpdated}}`,
  connecting: `Conectando`,
  today: `Hoy`,
  load_more: "Cargar más",
  download_csv: "Descargar CSV",
  end_of_result: `Fin del resultado`,
  recipient: `Beneficiario`,
  note: `Nota`,
  total_balance: "Saldo total",
  file_name: `Nombre del archivo`,
};
