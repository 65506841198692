import { ElementType, Suspense, lazy } from "react";
import { useLocation } from "react-router";
import { CatalogIcon } from "src/assets/icon/Catalog";
import MoneyIcon from "src/assets/icon/Money";
import UsersIcon from "src/assets/icon/users";
import { getActive } from "src/components/nav-section";
import usePermissions from "src/hooks/usePermissions";
import useDefaultQuery from "src/pages/Documents/Finance/hooks/useDefaultQuery";
import { store } from "src/redux/store";
import {
  PATH_CATALOG,
  PATH_CONFIGURATION,
  PATH_DASHBOARD,
  PATH_PAYMENTS,
} from "../../../routes/paths";
export const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  );
};

const FileIcon = Loadable(lazy(() => import("src/assets/icon/file")));
const SettingsIcon = Loadable(
  lazy(() => import("./../../../assets/icon/settings"))
);
const GitForkIcon = Loadable(lazy(() => import("src/assets/icon/git-fork")));
const BoxIcon = Loadable(lazy(() => import("src/assets/icon/box")));
const FilterIcon = Loadable(lazy(() => import("src/assets/icon/filter")));
const AnalyticsIcon = Loadable(lazy(() => import("src/assets/icon/Analytics")));

export default () => {
  const {
    org: {
      enablePaymentFlow,
      enablePayments,
      enableCatalogManagement,
      enableAsyncMappingUpload,
      enableNewDocumentConfiguration,
      enableAnalytics,
    },
  } = store.getState()?.organization;
  const { permissionHandle } = usePermissions();
  const { path: documentPath } = useDefaultQuery();

  const { pathname } = useLocation();

  const navConfig = [
    {
      show: true,
      items: [
        {
          title: "documents",
          path: documentPath,
          icon: (
            <FileIcon
              color={getActive(documentPath, pathname) ? "#ffcc00" : "#657482"}
            />
          ),
          role: permissionHandle(["Documents_Read", "Documents_Write"]),
        },
        {
          title: "configuration",
          path: "/configuration",
          icon: (
            <SettingsIcon
              color={
                getActive("/configuration", pathname) ? "#ff6600" : "#657482"
              }
            />
          ),
          role: permissionHandle([
            "MappingConfigurations_Read",
            "MappingConfigurations_Write",
            "DocumentConfigurations_Read",
            "DocumentConfigurations_Write",
            "ExportConfigurations_Read",
            "ExportConfigurations_Write",
            "ExportTemplates_Read",
            "ExportTemplates_Write",
          ]), // || enableDiscrepancyDetection,
          children: [
            {
              title: "Document",
              path: enableNewDocumentConfiguration
                ? `${PATH_CONFIGURATION.general.documentConfiguration}-v2`
                : PATH_CONFIGURATION.general.documentConfiguration,
              role: permissionHandle([
                "DocumentConfigurations_Read",
                "DocumentConfigurations_Write",
              ]),
              color: getActive("/configuration", pathname)
                ? "#ff6600"
                : "#657482",
            },
            {
              title: "Mapping",
              path: enableAsyncMappingUpload
                ? PATH_CONFIGURATION.general.inputCSVMappingV2
                : PATH_CONFIGURATION.general.inputCSVMapping,
              role: permissionHandle([
                "MappingConfigurations_Read",
                "MappingConfigurations_Write",
              ]),
              color: getActive("/configuration", pathname)
                ? "#ff6600"
                : "#657482",
            },
            {
              title: "Export",
              path: PATH_CONFIGURATION.general.outputCSVMapping,
              role: permissionHandle([
                "ExportConfigurations_Read",
                "ExportConfigurations_Write",
                "ExportTemplates_Read",
                "ExportTemplates_Write",
              ]),
              color: getActive("/configuration", pathname)
                ? "#ff6600"
                : "#657482",
            },
            {
              title: "ERP Sync",
              path: PATH_CONFIGURATION.general.erpSyncConfiguration,
              role: false, // enableDiscrepancyDetection,
              color: getActive("/configuration", pathname)
                ? "#ff6600"
                : "#657482",
            },
          ],
        },
        {
          title: "integration",
          path: PATH_DASHBOARD.general.integration,
          icon: (
            <GitForkIcon
              color={
                getActive(PATH_DASHBOARD.general.integration, pathname)
                  ? "#38b6ff"
                  : "#657482"
              }
            />
          ),
          role: permissionHandle(["Integrations_Read", "Integrations_Write"]),
        },
        {
          title: "workflow",
          path: PATH_DASHBOARD.general.workflow,
          icon: (
            <FilterIcon
              color={
                getActive(PATH_DASHBOARD.general.workflow, pathname)
                  ? "#a569bd"
                  : "#657482"
              }
            />
          ),
          role: permissionHandle([
            "WorkflowManagement_Read",
            "WorkflowManagement_Write",
            "WorkflowApproval_Read",
            "WorkflowApproval_Write",
          ]),
        },
        {
          title: "suppliers.suppliers",
          path: PATH_DASHBOARD.general.suppliers,
          icon: (
            <BoxIcon
              color={
                getActive(PATH_DASHBOARD.general.suppliers, pathname)
                  ? "#784212"
                  : "#657482"
              }
            />
          ),
          role: permissionHandle(["Suppliers_Read", "Suppliers_Write"]),
        },
        {
          title: "Customers",
          path: PATH_DASHBOARD.general.customers,
          icon: (
            <UsersIcon
              color={
                getActive(PATH_DASHBOARD.general.customers, pathname)
                  ? " #ff33cc"
                  : "#657482"
              }
            />
          ),
          role: permissionHandle(["Customers_Read", "Customers_Write"]),
        },
        {
          title: "catalog",
          path: "/catalog",
          icon: (
            <CatalogIcon
              color={
                getActive(PATH_CATALOG.general.items, pathname)
                  ? "#c0392b"
                  : "#657482"
              }
            />
          ),
          role:
            enableCatalogManagement &&
            permissionHandle([
              "CatalogManagement_Read",
              "CatalogManagement_Write",
            ]),
          children: [
            {
              title: "items",
              path: PATH_CATALOG.general.items,
              role: true,
              color: getActive(PATH_CATALOG.general.items, pathname)
                ? "#c0392b"
                : "#657482",
            },
          ],
        },
        {
          title: "payments",
          path: "/payments",
          icon: (
            <MoneyIcon
              color={
                getActive(PATH_PAYMENTS.root, pathname) ? "#55C329" : "#657482"
              }
            />
          ),
          role:
            (enablePaymentFlow || enablePayments) &&
            permissionHandle(["Payments_Read", "Payments_Write"]),
          children: [
            {
              title: "invoice_payment",
              path: PATH_PAYMENTS.general.invoicePayments,
              role: true,
              color: getActive(PATH_PAYMENTS.root, pathname)
                ? "#55C329"
                : "#657482",
            },
            {
              title: "bank_transactions",
              path: PATH_PAYMENTS.general.invoiceTransactions,
              role: true,
              color: getActive(PATH_PAYMENTS.root, pathname)
                ? "#55C329"
                : "#657482",
            },
          ],
        },
        {
          title: "analytics",
          path: PATH_DASHBOARD.general.analytics,
          icon: (
            <AnalyticsIcon
              color={
                getActive(PATH_DASHBOARD.general.analytics, pathname)
                  ? ["#FEC96B", "#C1EE92", "#FF5D5D"]
                  : "#657482"
              }
              isActive={getActive(PATH_DASHBOARD.general.analytics, pathname)}
            />
          ),
          role: enableAnalytics,
        },
      ],
    },
  ];
  return { navConfig };
};
