// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const es = {
  demo: {
    title: `Spanish`,
    introduction: `Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux de texte pour réaliser un livre spécimen de polices de texte. Il n'a pas fait que survivre cinq siècles, mais s'est aussi adapté à la bureautique informatique, sans que son contenu n'en soit modifié. Il a été popularisé dans les années 1960 grâce à la vente de feuilles Letraset contenant des passages du Lorem Ipsum, et, plus récemment, par son inclusion dans des applications de mise en page de texte, comme Aldus PageMaker.`,
  },
  docs: {
    hi: `Bonjour`,
    description: `Besoin d'aide? \n Consultez notre documentation.`,
    documentation: `documentation`,
  },
  authentication_screen: {
    signin_title: "Inicia sesión a Dost",
    signin_text: `Completa tus datos a continuación.`,
    email_address: `Dirección de email`,
    password: `Contraseña`,
    remember_me: `Recuérdame`,
    forgot_password: `He olvidado mi contraseña`,
    signin_header_text: `¿He olvidado mi contraseña?`,
    get_started: `Crear cuenta`,
    login_btn: `Inicia sesión`,
    forgot_password_title: `¿Has olvidado tu contraseña?`,
    forgot_password_text: `Por favor, escribe la dirección de email asociada a tu cuenta`,
    forgot_password_text_second: `te enviaremos un email con el link de restaurar contraseña`,
    reset_password: `Restablecer contraseña`,
    back_txt: `Volver`,
    continue_txt: `Seguir`,
    email_helper_txt: `Debe ser un correo electrónico profesional.`,
    password_helper_txt: `Debe tener al menos 8 caracteres.`,
    get_started_title: `Empieza gratis.`,
    get_started_text: `Gratis para siempre. No es necesaria tarjeta de crédito.`,
    pre_reg_text: `Al iniciar sesión, acepto los`,
    pre_reg_text_one: `y las `,
    terms_text: `Términos de Uso`,
    privacy_policy: `Políticas de Privacidad de Dost`,
    pre_reg_header_text: `¿Ya tienes una cuenta? `,
    pre_reg_btn: `Empezar gratis`,
    login_txt: `Inicia sesión`,
    register_title: `Comience absolutamente gratis`,
    first_name: `Nombre`,
    last_name: `Apellidos`,
    phone_number: `Número de teléfono`,
    organization: `Empresa`,
    role: `¿Qué papel te describe mejor?*`,
    sector: `¿En qué sector operas?*`,
    register_btn: `Crear mi cuenta`,
    pre_verify_title: `Por favor, revisa tu email`,
    pre_verify_text_one: `Te hemos enviado una confirmación a`,
    pre_verify_text_two: `Por favor, revisa tu carpeta de spam sí no ha llegado a tu bandeja de entrada`,
    pre_verify_email_text: `¿No has recibido ningún email? Por favor, pulsa en Reenviar Email`,
    resend_email_txt: `Reenviar Email`,
    login_with_google: `Inicia sesión con Google`,
    login_with_outlook: `Inicia sesión con Outlook`,
    something_went_wrong: `Algo salió mal. Por favor, vuelva a intentarlo`,
    plan: `Plan Suscrito`,
    account_created: `Cuenta Creada Exitosamente`,
    "Please, use a professional email address to create a Dost account": `Por favor, usa un correo profesional para crear una cuenta en Dost.`,
    receivedInvoices: `¿Cuántas facturas recibe cada mes?`,
    signup_with_google: `Regístrese con Google`,
    signup_with_outlook: `Regístrese con Outlook`,
    use_professional_account: `Por favor, usa un correo profesional para crear una cuenta en Dost`,
    alreadyExistError: `El nombre de usuario ya existe`,
    email_required: `El correo electrónico es obligatorio`,
    email_invalid: `El correo electrónico debe ser una dirección de correo electrónico válida`,
    or_signup_with: `o inscríbete con`,
    or_login_with: `o inicia sesión con`,
    country_name: `País de la empresa`,
    first_name_schema: `No se permiten tíldes ni símbolos especiales en este campo.`,
    last_name_schema: `No se permiten tíldes ni símbolos especiales en este campo.`,
    password_schema: `La contraseña debe contener al menos 8 caracteres y contener una letra mayúscula, una letra minúscula, un número y un símbolo`,
    "Invalid Email or Password": `Correo electrónico o contraseña no válidos`,
    "Username or password did not match": `El nombre de usuario o la contraseña no coinciden`,
    reset_password_title: `Reiniciar contraseña`,
    reset_password_text: `Por favor ingrese su nueva contraseña`,
  },
  dashboard: {
    dashboard: `Salpicadero`,
    Timesaved: `Tiempo ahorrado`,
    Moneysaved: `Costes reducidos`,
    number_of_docs_processed: `Número de documentos procesados​`,
    data_processed: `Datos procesados`,
    processed: `Procesado`,
    needs_review: `Necesita revisión`,
    error: `Error`,
    document_processed: `Documentos Procesados`,
    recent_documents: `Documentos Recientes`,
    top_vendors: `Top 5 Proveedores`,
    filename: `Nombre del archivo`,
    processed_at: `Procesado`,
    status: `Estado`,
    your_plan: "Tu plan",
    view_all: "ver todo",
    upgrade: `Mejora`,
    document_types: `Tipos de documentos`,
    total_documents_count: `Recuento total de documentos`,
  },
  finance_screen: {
    email_text: `Tu email dedicado al envio de documentos`,
    docs_processed: `Documentos Procesados`,
    email_csv_export: `Exportar CSV en email`,
    selected_files: `Archivo(s) Seleccionados`,
    remove_all: `Eliminar todo`,
    upload_files: `Cargar Archivos`,
    duplicate_files_are_not_allowed: `No se permiten archivos duplicados`,
    files_cannot_be_more_than_10: `Los archivos no pueden ser más de 10`,
    document_uploaded_successfully: `Documento subido correctamente`,
    extract_now: `Extraer ahora`,
    all: `Todo`,
    Invoice: `Facturas`,
    PurchaseOrder: `Ordenes de compra`,
    credit_memo: `Abono`,
    DeliveryNote: `Albaranes`,
    Reciepts: `Recibos`,
    filename: `Nombre del archivo`,
    processed_at: `Procesado el`,
    output: `Salida`,
    status: `Estado`,
    creeated_in_erp: `Creado en el ERP`,
    no_data: `Sin Datos`,
    dense: `Justificado`,
    rows_per_page: `Líneas por página:`,
    retry_button: `Reintentar`,
    yes_button: `Sí`,
    no_button: `No`,
    start_date: `Fecha de inicio`,
    end_date: `Fecha final`,
    search: `Buscar`,
    document_upload_unsuccessful: `Error al cargar el documento`,
    "Document not supported": `Documento no compatible`,
    "Number of pages exceed": `Número de páginas excedido`,
    "Limit Exceed": `Límite de documentos excedido`,
    document_name: `Nombre del documento`,
    SimpleNote: `Nota Simple`,
    WorkContract: `Contrato de trabajo`,
    Payroll: `Nómina`,
    DNI: `DNI`,
    Passport: `Pasaporte`,
    NIE: `NIE`,
    SimulatedInvoice: `Factura Simulada`,
    output_data: `Datos de salida`,
    CreditNote: `Nota de crédito`,
    document_deleted_successfully: `Documento(s) eliminado correctamente`,
    document_delete_unsuccessful: `Error en la eliminación del documento`,
    documentdelete_document_extraction_inprocess: `Extracción del documento en proceso`,
    documentdelete_document_already_deleted: `El documento ya fue eliminado`,
    documentdelete_disable_delete_integrated_document: `Deshabilitar la eliminación integrada del documento`,
    documentdelete_document_payment_inprocess: `El pago del documento está en proceso`,
    documentdelete_document_paid: `El documento fue pagado`,
  },
  integrations_screen: {
    integrations: `Integraciones`,
    dashboard_text: `Dashboard`,
    integration_text: `Integración`,
    new_integration_button_text: `Agregar una nueva integración`,
    add_integration: `Agregar una integración`,
    all: `Todo`,
    data_sources: `Fuentes de Datos`,
    data_sinks: `receptores de datos`,
    erp: `erps`,
    paltform: `plataforma`,
    type: `Tipo`,
    status: `Estado`,
    plan: `Plan`,
    dense: `Justificado`,
    rows_per_page: `Filas por página:`,
    upgrade_modal_title: `Actualice su plan haciendo clic a continuación`,
    upgrade_btn: `Mejora`,
    integrations_connected: `Integraciones conectadas`,
    integration_not_connected: `Aún no tienes ninguna integración conectada`,
    erp_connected: `ERP conectado`,
    disconnect: `Desconectar`,
    data_source_connected: `Fuente de datos conectada`,
    available_integrations: `Integraciones disponibles para conectar`,
    ERP: `ERP`,
    connect: `Conectar`,
    payment_gateways: `Pasarelas de pago`,
  },
  new_integrations_screen: {
    integrations: `Integratións`,
    dashboard_text: `Dashboard`,
    integration_text: `Integratión`,
    list: `Lista`,
    business_system: `Sistemas de gestión empresarial`,
    data_sources: "Fuentes de Datos",
    data_sinks: `receptores de datos`,
    tooltip_dialogbox_text:
      "Pulsa en las tarjetas que hay a continuación para conectar tu ERP",
  },
  review_screen: {
    meta_data: `Metadatos`,
    title: `Revisión`,
    value: `Valor`,
    update: `Actualizar`,
    of: `de`,
    add_meta_data: `Añadir metadatos`,
    next: `Siguiente`,
    finish: `Finalizar`,
    extraction: "Extracción",
    post_processing: "Post Procesamiento",
    new_review_alert: "Para volver a la versión anterior",
    old_review_alert_1: "Hay una nueva versión disponible, échale un vistazo",
    old_review_alert_2:
      "Ten en cuenta: esta versión no es compatible con documentos antiguos",
    now: `Ahora`,
    click_here: `Pulsa aquí`,
    email_text: `Tu email dedicado al envio de documentos`,
    docs_reviewed: `Documentos Procesados`,
    email_csv_export: `Exportar CSV en email`,
    upload_document: `Cargar Documento`,
    extract_now: `Extraer ahora`,
    all: `Todo`,
    invoices: `Facturas`,
    purchase_orders: `Ordenes de compra`,
    credit_memo: `Abono`,
    delivery_note: `Albaranes`,
    reciepts: `Recibos`,
    filename: `Nombre del archivo`,
    processed_at: `Procesado el`,
    output: `Salida`,
    status: `Estado`,
    creeated_in_erp: `Creado en el ERP`,
    no_data: `Sin Datos`,
    no_review: `No se necesita revisión`,
    dense: `Justificado`,
    rows_per_page: `Líneas por página:`,
    add_button: `Agregar`,
    finish_button: `Finalizar`,
    review_required: `Revisión Requerida`,
    back_button: `Atrás`,
    tooltip_dialogbox_textone:
      "Tras enviar el documento, pulsa el botón revisar.",
    tooltip_dialogbox_texttwo:
      "Haga clic en el archivo de abajo para empezar a revisarlo.",
    refresh_btn: `Actualizar`,
    dialog_box: {
      label: "Etiqueta",
      value: "Valor",
      row_number: "Número de fila",
      row: "Fila",
      field_required: "Campo es obligatorio",
      itemindex_required: "Se requiere el número de fila",
      item_required: "Campo es obligatorio",
      save: "Guardar",
      remove: "Eliminar",
    },
    review_fields: {
      DocumentCategory: "Categoría de Documento",
      Discrepancies: "Discrepancias",
      DetectedDiscrepancy: "Discrepancia detectada",
      AccountClass: "Clase de cuenta",
      DiscrepancyDetected: "Discrepancia detectada",
      DocumentGuid: "GUID del documento",
      taxes: "Impuestos",
      InvoiceID: `ID Factura`,
      InvoiceGuid: `GUID Factura`,
      CustomerCompanyName: `Nombre del cliente`,
      CustomerCompanyID: `CIF del cliente`,
      CustomerCompanyAddress: `Dirección del cliente`,
      VendorCompanyName: `Nombre del proveedor`,
      VendorCompanyID: `CIF del proveedor`,
      VendorCompanyAddress: `Dirección del proveedor`,
      ShipmentAddress: `Dirección de envío`,
      BillingCustomerCompanyName: `Nombre del cliente facturado`,
      BillingCustomerCompanyID: `CIF del cliente facturado`,
      BillingCustomerAddress: `Dirección del cliente facturado`,
      PoNumber: `Número de pedido`,
      InvoiceDate: `Fecha de factura`,
      DueDate: `Fecha de vencimiento`,
      CurrentDate: `Fecha actual`,
      TotalWithoutTax: `Total neto`,
      TotalTax: `Total IVA`,
      TotalAmount: `Total factura`,
      CustomerEmail: `Email cliente`,
      VendorEmail: `Email proveedor`,
      PhoneNumber: `Número de teléfono`,
      ContactPerson: `Persona de contacto`,
      DeliveryNote: `Albarán`,
      ConstructionCode: `Código de construcción`,
      ProviderCode: `Código interno`,
      ReferenceCode: `Código de referencia`,
      Description: `Descripción`,
      Unit: `Unidad`,
      UnitPrice: `Precio unitario`,
      Quantity: `Cantidad`,
      ValueAddedTaxPercentage: `Porcentaje IVA`,
      ValueAddedTax: `IVA`,
      DtoPercentage: `Porcentaje de descuento`,
      Dto: `Descuento`,
      DiscountPercentage: `Porcentaje de descuento`,
      Discount: `Descuento`,
      CommissionPercentage: `Porcentaje de comisión`,
      Commission: `Comisión`,
      SubTotalAmount: `Subtotal`,
      BaseAmount: `Base imponible`,
      CustomerAddressRecipient: `Dirección del cliente facturado`,
      PaymentTerm: `Condiciones de pago`,
      "Base-Amount": `Base imponible`,
      VAT: `IVA`,
      "VAT%": `IVA %`,
      BillingAddressRecipient: "Dirección de facturación",
      TotalwithoutTax: `Total neto`,
      ProductCode: `Código de producto`,
      Currency: `Divisa `,
      DocumentType: `Tipo de documento`,
      "Reference/Code": `Código referencia`,
      PONumber: `Número de pedido`,
      Total_after_Tax: `Total con IVA`,
      VendorAddressRecipient: `Dirección adicional del proveedor`,
      "VAT %": `IVA %`,
      ShippingCost: `Coste de envío`,
      WithholdingTax: `Retención`,
      general: "General",
      Items: "Artículos",
      PaymentDetails: "Detalles de Pago",
      "Items-table-general": `Artículos - General`,
      "taxes-table": `Tabla de impuestos`,
      "ShippingCost-table": `Tabla de costes de envío`,
      UtilityTaxes: `Impuestos de utilidad`,
      VendorId: `ID del proveedor`,
      TaxType: `Tipo de impuesto`,
      TaxValue: `Valor del impuesto`,
      TaxValueType: `Tipo de valor del impuesto`,
      BaseAmountFieldName: `Nombre del campo de la base imponible`,
      TaxRate: `Porcentaje de impuesto`,
      VATPercentage: `Porcentaje de IVA`,
      SubTotal: `Subtotal`,
      un_labelled: `Sin etiqueta`,
      DocumentId: `ID de documento`,
      Batch: `Lote`,
      SizeReference: `Referencia de tamaño`,
      Wholesale: `Venta al por mayor`,
      RRP: `PVP`,
      TotalWholesale: `Venta al por mayor total`,
      TotalRRP: `PVP total`,
      QuantitySize: `Cantidad Tamaño`,
      VendorCulture: `Cultura del proveedor`,
      DocumentStatus: `Estado del documento`,
      DocumentURL: `URL del documento`,
      ProcessedDate: `Fecha de procesamiento`,
      CreationDate: `Fecha de creación`,
      LastModifiedDate: `Fecha de última modificación`,
      DocumentHash: `Hash del documento`,
      DocumentSource: `Origen del documento`,
      FileName: `Nombre del archivo`,
      UserId: `ID de usuario`,
      DocumentClass: `Clase de documento`,
      InternalCompanyCode: `Código interno de la empresa`,
      DocumentNumber: `Número del Documento`,
      DocumentDate: `Fecha del documento`,
      actions: `Acciones`,
      TariffCode: `Código de tarifa`,
      OriginCountry: `País de origen`,
      Weight: `Peso`,
      ContactEmail: `Email del contacto`,
      DeliveryAddress: `Dirección de entrega`,
      PaymentMethod: `Método de pago`,
      TotalDiscount: `Descuento total`,
      DeliveryDate: `Fecha de entrega`,
      ShippingAddress: `Dirección de envío`,
      PaymetMethod: `Método de pago`,
      ShippingMethod: `Método de envío`,
      Taxes: `Impuestos`,
      TotalAfterTax: `Total con IVA`,
      PaymentTerms: `Condiciones de pago`,
      Accounts: "Cuentas",
      AmountPercentage: "Porcentaje de importe",
      GLAccount: "Cuenta Contable",
      CostCenter: "Centro de coste",
      RefCriteria: "Criterio de reparto",
      Amount: "Importe",
    },
  },
  coming_soon_screen: {
    coming_soon: "Próximamente",
    coming_soon_text: "Actualmente estamos trabajando en esta página",
  },
  support_screen: {
    subject: `Asunto`,
    text: `Escribe un mensaje`,
    button_text: `enviar`,
  },
  account_screen: {
    "Company ID": `ID de la empresa`,
    registrationError: `El ID de registro es obligatorio`,
    registrationSpecialChrError: `El ID de registro no debe contener caracteres especiales`,
    registrationSpaceError: `El ID de registro no debe contener espacios`,
    registrationId: "ID de registro",
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Correo electrónico",
    phoneNumber: "Número de teléfono",
    organization: "Organización",
    other: "Otro",
    account_name: `Mi cuenta`,
    users: `Usuarios`,
    account_settings: `Ajustes de la cuenta`,
    general: `general`,
    "change password": `Cambiar contraseña`,
    name: `Nombre`,
    phone_number: `Teléfono`,
    country: `País`,
    city: `Ciudad`,
    about: `Sobre`,
    email_address: `Correo electrónico`,
    address: `Dirección`,
    state: `Estado/Región`,
    zipcode: `ZIP/CP`,
    save_changes: `Guardar cambios`,
    name_validation: `Se requiere el Nombre`,
    phone_number_validation: `Se requiere Teléfono`,
    country_validation: `Se requiere País`,
    city_validation: `Se requiere Ciudad`,
    about_validation: `Se requiere Sobre`,
    email_address_validation: `Se requiere Correo electrónico`,
    address_validation: `Se requiere Dirección`,
    state_validation: `Se requiere Estado/Región`,
    zipcode_validation: `Se requiere ZIP/CP`,
    firstNameError: "El nombre es obligatorio",
    lastNameError: "El apellido es obligatorio",
    emailError: "El correo electrónico es obligatorio",
    passwordError: "El contraseña es obligatorio",
    phoneNumberError: "El número de teléfono es obligatorio",
    organizationError: "El organización es obligatoria",
    countryError: "El país es obligatorio",
    otherError: "El campo Otro es obligatorio",
    answerError: "La respuesta es obligatoria",
    otherAnswerError: "La respuesta en Otro es obligatoria",
    lettersOnlyError: "Solo se permiten letras",
    roleInOrganization: "¿Cuál es tu rol?",
    sectorInOrganization: "¿En qué sector operas?",
    otherSectorInOrganization: "Otro sector",
    otherRoleInOrganization: "Otro rol",
    CFO: "Director financiero",
    "Financial Manager": "Gerente financiero",
    Accountant: "Contador",
    Freelance: "Autónomo",
    Other: "Otro",
    Construction: "Construcción",
    Shipping: "Transporte",
    Food: "Alimentación",
    "Not sure": "No estoy seguro",
    "organization setting": "configuración de la organización",
    money_saved: `Dinero Ahorrado`,
    time_saved: `Tiempo ahorrado en minutos`,
    save: `Salvar`,
    currency: `Moneda`,
    "One or more validation errors occurred.":
      "Ocurrieron uno o más errores de validación.",
    image_error: "La altura y el ancho del logotipo deben ser de 1200 píxeles.",
    hide_email: `Ocultar Correo Electrónico`,
    hide: `Esconder`,
    show: `Espectáculo`,
    something_went_wrong: `Algo salió mal`,
    updated_successfully: `Actualizado con éxito`,
    update_fail: `Actualización fallida!`,
    upload_logo: `Cargar el logotipo de la organización`,
    roleError: `El role es obligatorio`,
    sectorError: "El sector es obligatorio",
    invoicesError: "Las facturas recibidas son obligatorias",
    webhook: `Webhook`,
    notificationEmail: `Correo electrónico de notificación`,
    notification_email_message_0: `Si esto está configurado, todas las notificaciones se enviarán en este correo electrónico`,
    notification_email_message_1: `(de forma predeterminada, todas las notificaciones por correo electrónico van al correo electrónico de la cuenta)`,
  },
  password_screen: {
    old_password: `Contraseña anterior`,
    new_password: `Nueva contraseña`,
    confirm_password: `Confirmar nueva contraseña`,
  },
  low_confidence_modal: {
    please_review_the_processed_value: "Por favor revisa el valor procesado",
    extracted_value: "Valor extraído",
    new_value: "Nuevo valor",
    save: "Salvar",
    cancel: "Cancelar",
  },
  meta_data_modal: {
    meta_data: "META DATOS",
    title: "Título",
    value: "Valor",
  },
  holded_modal: {
    title_text: `Conecta Dost con tu servicio de Holded`,
    paragraph_text_one: `Por favor,dirigete al enlace:`,
    paragraph_text_two: `copia y pega tu código de Holded a continuación:`,
    apikey_text: `Clave API`,
    button_text_connect: `Conectar`,
    button_text_disconnect: `Desconectar`,
    validation: `Se requiere API`,
  },
  sapb_one_modal: {
    title_text: `Conecta Dost con tu cuenta de SAP B1`,
    details_text: `Completa los detalles a continuación:`,
    server_address: `Dirección del servidor`,
    port: `Puerto`,
    user_name: `Nombre de usuario`,
    password_text: `Contraseña`,
    company_db: `Base de datos de la empresa`,
    uploading_mapping: `Sube el mapping`,
    select_file: `Arrastra o selecciona un archivo`,
    select_file_paragraph: `Arrastra archivos o pulsa en`,
    select_file_paragraph_second: `para subir un archivo 
      de tu directorio local`,
    browse: `Explorar`,
    button_text: `Conectar`,
    button_text_disconnect: `Desconectar`,
    server_validation: `Se requiere la dirección del servidor`,
    port_validation: `Se requiere puerto`,
    name_validation: `Se requiere nombre de usuario`,
    password_validation: `se requiere contraseña`,
    db_validation: `Se requiere la base de datos de la empresa`,
    file_validation: `Se requiere el archivo Csv`,
  },
  delsol_modal: {
    delsol_title: `Connecta Dost con tu cuenta de DELSOL`,
    delsol_subtitle: `Por favor,completa la información para configurar la
       integración con tu cuenta de DELSOL`,

    codigo_fabrica: `Código de fábrica`,
    codigo_cliente: `Código de cliente`,
    base_datos: `Base de datos`,
    password: `Contraseña`,
    type_codigo_fabrica: `Código de fábrica`,
    type_codigo_cliente: `Código de cliente`,
    type_codigo_base_datos: `Código de datos`,
    type_password: `Contraseña`,
    button_text: `Conectar`,
    button_text_disconnect: `Desconectar`,
    codigo_fabrica_validation: `Se requiere Código de fábrica`,
    codigo_cliente_validation: `Se requiere Código de cliente`,
    codigo_datos_validation: `Se requiere Código de datos`,
    password_validation: `Se requiere Contraseña`,
  },
  onedrive_modal: {
    onedrive_text: `Conecta Dost con tu cuenta OneDrive`,
    button_text: `Conectar`,
    button_text_disconnect: `Desconectar`,
    open_folder: `Carpeta abierta`,
  },
  gdrive_modal: {
    heading: `Conecta Dost con tu cuenta Google Drive`,
    connect: `Conectar`,
    disconnect: `Desconectar`,
    open_folder: `Carpeta abierta`,
  },
  coming_soon_modal: {
    coming_soon: "Próximamente",
  },
  cardoner_modal: {
    cardoner_title: `Conecta Dost con tu WEBSERVICE`,
    cardoner_text: `Proporcione los detalles a continuación para enviar datos a su servicio web`,
    address: `Dirección`,
    port: "Puerto",
    user_name: "Nombre de usuario",
    password: "Contraseña",
    button_text: `Conectar`,
    button_text_disconnect: `Desconectar`,
    button_text_batch: `Lote`,
    address_validation: `Se requiere Dirección`,
    port_validation: `Se requiere Puerto`,
    password_validation: `Se requiere Contraseña`,
    user_name_validation: "Se requiere Nombre de usuario",
  },
  ftp_output: {
    FtpHost: "host ftp",
    FtpPort: "Puerto",
    FtpUserId: "Usuario",
    FtpPassword: "Contraseña",
    FtpPath: "Ruta",
    button_text_connect: "Conectar",
    button_text_disconnect: "Desconectar",
    ftp_output_text:
      "Proporcione los detalles a continuación para conectarse a su servidor FTP",
    tenant: "Inquilino",
    clientId: "ID de cliente",
    clientSecret: "Secreto del cliente",
    environmentName: "Nombre del entorno",
    companyId: "ID de la empresa",
    apiPublisher: "Editor de API",
    apiGroup: "Grupo de API",
    apiVersion: "Versión de API",
    validation: {
      FtpHost: "Se requiere Anfitrión",
      FtpPort: "Se requiere Puerto",
      FtpUserId: "Se requiere Usuario",
      FtpPassword: "Se requiere Contraseña",
      FtpPath: "Se requiere Ruta",
    },
  },
  notification_modal: {
    success_message: "Conexión completada",
    unsuccess_message: "Desconexión completada",
    in_process: "En progreso",
  },
  password_validation: {
    old_password: `Se requiere contraseña antigua`,
    new_password: `La contraseña debe contener al menos 8 caracteres y contener una letra mayúscula, una letra minúscula, un número y un símbolo`,
    confirm_password: `Las contraseñas deben coincidir`,
    confirm_password_required: `Se requiere confirmar la contraseña`,
    confirm_new_password: `Confirmar nueva contraseña`,
  },

  status: {
    in_process: `En proceso`,
    processed: `Procesado`,
    failed: `Error`,
    error: `Error`,
    need_review: `Revisar`,
    reviewed: `Revisado`,
    duplicate: `Duplicado`,
    connected: `Conectado`,
    not_connected: `No Conectado`,
    success: `Éxito`,
    plan: `libre`,
  },
  upgrade_screen: {
    title: `Mejora tu plan`,
    sub_title_one: `Selecciona un plan`,
    sub_title_two: `Complementos`,
    starter_text: `STARTER`,
    pro_text: `PRO`,
    enterprise_text: `ENTERPRISE`,
    basic_plan_text: `Agregar al plan Básico`,
    pro_plan_text: `Añadir al plan Starter/Pro`,
    month_text: `mes`,
    free_text: `Gratis`,
    sources_text: `Fuentes: OneDrive y Google Drive`,
    scrapping_text: `Extracción de facturas`,
    erp_text: `Integraciones ERP API`,
    documents_text: `200 facturas/mes`,
    support_text: `Soporte`,
    users_text: `usuarios`,
    starter_btn_text: `Actualizar a principiante`,
    pro_starter_text: "Todas las funcionalidades del plan STARTER",
    ai_text: `ENTRENAMIENTO PERSONALIZADO CON IA`,
    approval_text: `3 APROBACIONES WF`,
    fraud_text: `DETECCIÓN DE FRAUDE`,
    validation_text: `VALIDACIÓN DE DATOS`,
    invoices_text: `500 facturas/mes`,
    pro_btn_text: `Actualizar a Pro`,
    dedicated_text: `Gerente de proyecto dedicado`,
    enterprise_btn_text: `Contacta con nosotros`,
    customer_success_text: `Gerente dedicado al éxito del cliente`,
    dost_api_text: `Conexión API DOST (3€/mes)`,
    documents_text_addon: `50 documentos (5€/mes)`,
    wf_approvals: `1 WF APROBACIONES (29€/mes)`,
    values_validation: `VALIDACIÓN DE VALORES`,
    already_subscribed: `Suscrito`,
    buy_now_btn: `Comprar ahora`,
    subscription_btn: `Administrar suscripción`,
    plan_txt: `Tu plan incluye`,
    users_txt: `Usuarios`,
    invoices_txt: `Facturas`,
  },
  sidebar: {
    general: `general`,
    home: `Inicio`,
    documents: `Documentos`,
  },

  checkout_screen: {
    checkout_btn_txt: `Verificar`,
    empty_cart: `Vaciar carrito`,
    clear_cart: `Limpiar carrito con éxito`,
    shop_btn: `Seguir comprando`,
    cart_txt: `Carrito`,
    empty_cart_txt: `El carrito esta vacío`,
    cart_title: `Parece que no tiene artículos en su carrito de compras.`,
    cart_description: `Parece que no tiene artículos en su carrito de compras.`,
    checkout_txt: `Verificar`,
    dashboard_txt: `Tablero`,
    upgrade_txt: `Plan de actualización`,
    summary: `Sumario`,
    subscription: `Subscripción`,
    add_ons: `Complementos`,
    product_text: `Producto`,
    quantity_txt: `Cantidad`,
    price_txt: `Precio`,
    delete_txt:
      "¿Está seguro de que quiere eliminar el plan seleccionado de la cesta?",
    yes_btn: "Sí",
    no_btn: "No",
  },

  Bumper: {
    heading: "Completa los pasos del on-boarding",
    subheading: "Completa estos primeros pasos para sacar rendimiento de Dost",
    titleStep1: "Envía tu primer documento",
    titleStep2: "Revisa tus documentos",
    titleStep3: "Conecta tu ERP",
    discStep1:
      "Envíe su primer documento para enseñarle a su Dost Digital Brain qué tipo de documentos le enviará para procesar automáticamente.",
    discStep2:
      "Ve a la página de Revisión para echar un vistazo a los valores que no se han procesado correctamente. Asegúrese de completar la información pendiente para poder enviarla a su ERP.",
    discStep3:
      "Conecte su ERP o sus fuentes de datos para sacar el máximo partido a la automatización de procesos. De principio a fin.",
    sendBtn1: "Enviar documento",
    sendBtn2: "Ir a la página",
    sendBtn3: "Conectar ERP",
    skipBtn: "Siguiente paso",
    Markascompleted: "Marcar como completado",
    mainHeading: "Comience aquí para obtener lo mejor de Dost",
    mainPara1: "Ha completado",
    mainParaOf: "de los",
    mainPara2: "pasos para finalizar la configuración básica.",
    progressLabel: "Progreso actual",
    financeCardHead: "Envía documentos a tu portal",
    financeCardLine1:
      "Enviar al documento a esta dirección de correo electrónico.",
    financeCardLine2:
      "Haga clic en 'Extraer ahora' después de unos segundos para ver el documento en el portal.",
    financeCardLine3:
      "También puede enviar y olvidar y dost extraerá todos sus documentos cada cinco minutos.",
  },
  supplier_screen: {
    impersonate_supplier: "Personificar al proveedor",
    reset_message:
      "¿Estás seguro de que deseas actualizar este proveedor? Actualizar el proveedor restablecerá el proceso de incorporación",
    update_supplier_form: `Actualizar proveedor`,
    view_supplier: `Ver Proveedor`,
    suppliers: `Proveedores`,
    supplierName: `Nombre del proveedor`,
    supplierEmail: "Email del proveedor",
    supplierUrl: "Dirección del portal del proveedor",
    createSupplier: "Crear Proveedor",
    created: "Proveedor creado con éxito",
    notCreated: "Error creando el proveedor",
    cancel_button: "Cancelar",
    dense: "Justificado",
    Action: "Acciones",
    edit_supplier: `Editar `,
    delete_supplier: `Eliminar `,
    delete_supplier_dialog: `Eliminar proveedor`,
    reset_supplier: `Restablecer proveedor`,
    updated: `Proveedor actualizado exitosamente`,
    deleted: `Proveedor eliminado exitosamente`,
    reset_approval: `¿Está seguro de que desea restablecer la incorporación de este proveedor`,
    reset_successful: `La incorporación de proveedores se restableció correctamente`,
    reset_onboarding: `Restablecer la incorporación`,
    reset_supplier_onboarding: `Restablecer la incorporación de proveedores`,
  },
  action: "Acciones",
  users_screen: {
    users: "Usuarios",
    invite_users: "Invitar usuarios",
    send_invite: "Enviar invitación",
    email_address: "Dirección de email",
    cancel_button: "Cancelar",
    invite_sent_successfully: "Invitación enviada exitosamente",
    something_went_wrong: `Algo salió mal`,
    dense: "Justificado",
    update_profile: `Por favor, actualice el perfil para enviar una invitación.`,
    user_id: `ID de usuario`,
    role: `Rol`,
  },

  interval_error: `El intervalo no puede ser inferior a 30 minutos`,
  interval_required: `Se requiere intervalo`,
  interval_integer_not_allowed: `El intervalo debe ser un número entero`,

  configuration_modal: {
    add_custom_field: `Añadir Campo Personalizado`,
    save: `Guardar`,
    cancel: `Cancelar`,
    add_vendor_config: `Agregar configuración de proveedor`,
    vendor_id: `ID del vendedor`,
    date_format: `Formato de fecha`,
    add: `Agregar`,
    update: `Actualizar`,
    duplicate: `Duplicado`,
    add_customer_config: "Agregar configuración de cliente",
    customer_id: "Identificación del cliente",
  },

  app: `application`,
  user: `Usuario`,
  list: `liste`,
  edit: `éditer`,
  shop: `boutique`,
  blog: `blog`,
  post: `poste`,
  mail: `e-mail`,
  chat: `discuter`,
  cards: `cartes`,
  posts: `des postes`,
  create: `créer`,
  kanban: `kanban`,
  general: `GENERAL`,
  banking: `bancaire`,
  booking: `réservation`,
  profile: `Perfil`,
  account: `compte`,
  product: `produit`,
  invoice: `Factura`,
  details: `Detalles`,
  checkout: `vérifier`,
  calendar: `calendrier`,
  analytics: `Analítica`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `niveau menu 1`,
  menu_level_2: `niveau menu 2`,
  menu_level_3: `niveau menu 3`,
  item_disabled: `élément désactivé`,
  item_label: `étiquette de l'article`,
  item_caption: `légende de l'article`,
  description: `Descripción`,
  other_cases: `autres cas`,
  item_by_roles: `élément par rôles`,
  only_admin_can_see_this_item: `seul l'administrateur peut voir cet élément`,
  home: `Inicio`,
  admin: `Administración`,
  documents: `Documentos`,
  integrations: `Integraciones`,
  review: `Revisiones`,
  data: `datos`,
  support: `Soporte técnico`,
  finance: `Finanzas`,
  operations: `operaciones`,
  "data analytics": `Análisis`,
  "resource saved": `Recursos ahorrados`,
  upgrade: `Mejora Tu Plan`,
  workflow: "Flujo de trabajo",
  settings: `Ajustes`,
  logout: `Cerrar sesión`,
  suppliers: `Proveedores`,
  configuration: `Configuración`,
  users: "Usuarios",
  integration: "Integraciones",
  email: `Correo electrónico`,
  ftp: `FTP`,
  email_receive_type: `Tipo de recepción`,
  email_receive_type_required: `El tipo de correo electrónico recibido es obligatorio`,
  host_required: `Se requiere anfitrión`,
  port_required: `Se requiere puerto`,
  port_validation: `El puerto solo debe contener número`,
  username_required: `Se requiere nombre de usuario`,
  password_required: `Password is required`,
  path_required: `Se requiere ruta`,
  host: `Anfitrión`,
  port: `puerto`,
  userName: `Nombre de usuario`,
  password: `Contraseña`,
  path: `Camino`,
  Field: "Campo",
  "General Table": `Tabla General`,
  discrepancies: `Discrepancias`,
  view_discrepancies: `Ver discrepancia`,
  select_document: `Por favor seleccione un documento`,
  document_name: `Nombre del Documento`,
  document_id: `Documento de identificación`,
  messages: `Mensajes`,
  delivery_notes: `Albaranes`,
  delivery_note: `Albaran`,
  delivery_note_number: `Número de albarán`,
  vendor_name: `Nombre del vendedor`,
  vendorId: `ID del proveedor`,
  issue_date: `Fecha de asunto`,
  totalAmount: `Cantidad total`,
  reference_code: `Código de referencia`,
  quantity: `Cantidad`,
  no_discrepancies: `Sin discrepancias`,
  invoices: `Facturas`,
  delivery_note_items: `Elementos de nota de entrega`,
  invoice_items: `artículos de factura`,
  a_maximum_of_10_files_are_allowed_in_a_single_upload: `Puede cargar máximo 10 documentos a al vez`,
  only_one_document_is_allowed_to_upload: `Sólo se permite subir un archivo`,

  // Discrepancy Messages
  po_number_not_found_in_any_items_general: `Número de orden de compra no encontrado en ningún artículo`,
  delivery_note_not_found_in_any_items_general: `Albarán de entrega no encontrado en ningún artículo.`,
  no_item_found_in_invoice_general: `No se encontraron artículos en Factura.`,
  delivery_note_not_found_general: `Albarán de entrega no encontrado`,
  invoice_item_count_miss_match_general: `Artículos de factura no coinciden en el recuento.`,
  invoice_item_not_found_in_deliverynote_general: `Artículo de factura no encontrado en el albarán de entrega`,
  invoice_item_quantity_greaterthan_general: `Cantidad de artículos de factura mayor que la cantidad de albarán de entrega`,
  delivery_notes_related_to_general: `Albaranes relacionados con`,
  documents_related_to_general: `Documentos relacionados con`,
  document_number: `Número del Documento`,
  invoice_data: `Datos de Factura`,
  items_with_conflicts: `Artículos con conflicto`,
  delivery_note_not_attached_general: `Albarán de entrega no adjunto`,
  item_descriptionandcode_not_attached: `La descripción del artículo y el código de referencia no se adjuntan en la factura n.° {{0}} ni en la nota de entrega n.°{{1}}`,
  item_quantity_is_zero: `La cantidad de "{{0}}" en el Albarán de Entrega #{{1}} para la Factura #{{2}} es cero.`,
  // Invoice & Delivery Note Discrepancy
  no_item_found_in_invoice: `No se encontró ningún artículo en la factura n.°{{0}}`,
  delivery_note_not_found: `Falta el abarán n.°{{0}} al que hace referencia la factura n.°{{1}}.`,
  delivery_note_not_found_in_any_items: `No hay ningun albarán relacionado con la factura n.°{{0}}`,
  invoice_item_count_miss_match: `El recuento de artículos de la factura n.º{{1}} no es el mismo que el recuento de artículos del albarán n.°{{0}}.`,
  delivery_note_not_attached: `El albarán de entrega no está adjunto a la factura n.°{{0}}.`,
  delivery_note_item_descriptionandcode_not_attached: `Las descripciones y codigos de los artículos no concuerdan entre la factura n.º {{0}} y el albaran n.º {{1}}.`,
  invoice_item_not_found_in_deliverynote: `El artículo con descripción "{{0}}" en la factura n.º{{2}} no se encuentra en el albarán n.º{{1}}`,
  delivery_note_item_quantity_is_zero: `La cantidad del artículo "{{0}}" en el albarán n.º{{1}} para la Factura n.º{{2}} es cero.`,
  invoice_item_quantity_greaterthan: `La cantidad del artículo "{{0}}" en el albarán n.º{{1}} es de {{2}} unidades. No coincide con la factura {{3}} donde se indican {{4}} unidades.`,
  invoice_item_quantity_lessthan: `La cantidad del artículo "{{0}}" en el albarán n.º{{1}} es de {{2}} unidades. No coincide con la factura {{3}} donde se indican {{4}} unidades.`,
  // PO & Invoice Discrepancy
  po_not_found: `Falta la orden de compra n.º {{0}}, a la que se hace referencia en la factura n.º {{1}}.`,
  po_not_found_in_any_items: `Orden de compra no encontrada en ningún artículo al que se hace referencia en la factura n.°{{0}}`,
  po_invoice_item_count_miss_match: `El recuento de artículos en la Factura n.°{{0}} no coincide con el recuento de artículos en la Orden de compra n.°{{1}}.`,
  po_not_attached: `La orden de compra no está adjunta a la factura n.°{{0}}.`,
  po_item_descriptionandcode_not_attached: `Las descripciones y códigos de los artículos no están adjuntos a la factura n.°{{0}} ni a la nota de entrega n.°{{1}}.`,
  invoice_item_not_found_in_po: `El artículo con descripción "{{0}}" en la factura n.°{{1}} no se encuentra en el nota de entrega n.°{{2}}`,
  po_item_quantity_is_zero: `La cantidad de "{{0}}" en el nota de Entrega n.°{{1}} para la Factura n.°{{2}} es cero.`,
  invoice_item_quantity_greaterthan_po: `La cantidad del artículo "{{0}}" en la factura n.º {{1}} es {{2}} unidades, lo que no coincide con la orden de compra n.º {{3}} donde se indica como {{4}} unidades.`,
  po_number_not_found_in_any_items: `Orden de compra no encontrada en ningún artículo en la Factura n.°{{0}}`,
  amount_not_match: `El importe de la factura {{0}} no coincide con el importe de la factura simulada {{1}}`,
  consumption_not_match: `El consumo de facturas {{0}} no coincide con el consumo de facturas simulado {{1}}`,
  po_invoice_amount_not_match: `El monto {{2}} mencionado en la Orden de Compra #{{1}}, no coincide con el monto {{3}} mencionado en la Factura #{{0}}`,
  discrepancy_po_empty: `El número de orden de compra está vacío en el documento # {{0}}`,
  discrepancy_invoice_po_not_found: `PO #{{1}} no encontrado en el documento #{{0}}`,
  discrepancy_invoice_po_amount_not_match: `El importe {{3}} en la orden de compra {{1}} no coincide con el importe {{2}} en la factura {{0}}`,
  // PO & Delivery Note Discrepancy
  delivery_note_quantity_greaterthan_po: `La cantidad del artículo "{{0}}" en la Nota de Entrega #{{3}} es {{4}} unidades, lo cual no coincide con la Orden de Compra #{{1}} donde se indica como {{2} } unidades`,

  delivery_note_quantity_lessthan_po: `La cantidad del artículo "{{0}}" en la Nota de Entrega #{{3}} es {{4}} unidades, lo cual no coincide con la Orden de Compra #{{1}} donde se indica como {{2} } unidades`,
  po_dn_matching_po_number_not_found_in_delivery_note: `Orden de compra no encontrada en la nota de entrega #{{0}}`,
  po_dn_matching_po_number_not_found_against_delivery_note_po_number: `Falta la orden de compra n.° {{0}}, a la que se hace referencia en la nota de entrega n.° {{1}}.`,
  po_dn_matching_po_and_delivery_note_items_count_not_matched: `El recuento de artículos en la Nota de entrega n.° {{0}} no coincide con el recuento de artículos en la Orden de compra n.° {{1}}.`,
  po_dn_matching_item_description_and_refrence_code_not_found_in_delivery_note: `La descripción del artículo y el código de referencia de la orden de compra n.°{{0}} no se encuentran en la nota de entrega n.°{{1}}.`,
  po_dn_matching_po_item_not_found_in_deliverynote: `El artículo con descripción "{{0}}" en la Orden de compra n.°{{2}} no se encuentra en la Nota de entrega n.°{{1}}`,
  po_dn_matching_po_items_not_found: `Artículos no encontrados en la orden de compra #{{1}}`,
  po_dn_matching_delivery_note_item_quantity_is_zero: `La cantidad de "{{0}}" en la Nota de Entrega #{{3}} para la Orden de Compra #{{1}} es cero.`,
  discrepancy_dn_empty: `El albarán de entrega está vacío en el documento # {{0}}`,
  discrepancy_invoice_dn_not_found: `Albarán de entrega #{{1}} no encontrado en el documento #{{0}}`,
  discrepancy_invoice_dn_amount_not_match: `El importe {{3}} en el albarán de entrega {{1}} no coincide con el importe {{2}} en la factura {{0}}`,
  // Catalog Discrepancy
  tax_baseamount_sum_notequal_totalwithoutTax: `La suma del 'Monto Base' del impuesto no es igual a 'Total sin impuestos'`,
  item_price_didnot_match: `El precio unitario {{2}} del artículo con descripción "{{0}}" y código de referencia {{1}} no coincide con el precio de variación {{3}} en el catálogo.`,
  item_variation_not_found_in_catalog: `Variación de artículo {{0}} con código de referencia {{1}} no encontrada en el catálogo`,
  item_not_found_in_catalog: `Artículo {{0}} con código de referencia {{1}} no encontrado en el catálogo`,
  item_code_empty: `El código de referencia del artículo {{0}} {{1}} está vacío`,
  PO_item_not_found: `No se encontró ningún artículo en la orden de compra n.°{{0}}`,
  // Workflow Invoice & Delivery Note Discrepancy
  workflow_discrepancy_InvoiceDNMatching_DeliveryNoteNotFound:
    "No se encontró la nota de entrega (IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_ItemCountNotMatched:
    "El recuento de artículos no coincide (IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_DeliveryNoteItemsNotFound:
    "No se encontraron los artículos de la nota de entrega (IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_InvoiceItemDescriptionAndCodeNotAttached:
    "No se adjunta la descripción y el código del artículo de la factura (IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_InvoiceItemNotFoundInDN:
    "No se encontró el artículo de la factura en la nota de entrega (IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_ItemQuantityGreaterThan:
    "La cantidad de artículos es mayor que la esperada (IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_ItemQuantityLessThan:
    "La cantidad de artículos es menor que la esperada (IN <> DN)",
  workflow_discrepancy_InvoiceDNMatching_PaymentTerms_DueDate:
    "No coinciden las condiciones de pago o la fecha de vencimiento (IN <> DN)",
  workflow_discrepancy_InvoicePOMatching_AmountNotMatch:
    "El importe no coincide con la orden de compra (IN <> PO)",
  workflow_discrepancy_PODNMatching_PONumberNotFound:
    "No se encontró el número de orden de compra (PO <> DN)",
  workflow_discrepancy_PODNMatching_PONotFound:
    "No se encontró la orden de compra (PO <> DN)",
  workflow_discrepancy_PODNMatching_ItemCountNotMatched:
    "El recuento de artículos no coincide con la orden de compra (PO <> DN)",
  workflow_discrepancy_PODNMatching_POItemsNotFound:
    "Artículos de la orden de compra no encontrados (PO <> DN)",
  workflow_discrepancy_PODNMatching_DNItemDescriptionAndRefrenceCodeNotFound:
    "No se encontró la descripción y el código de referencia del artículo de la nota de entrega (PO <> DN)",
  workflow_discrepancy_PODNMatching_DelveryNoteItemNotFoundInPO:
    "Artículo de la nota de entrega no encontrado en la orden de compra (PO <> DN)",
  workflow_discrepancy_PODNMatching_DeliveryNoteItemQuantityZero:
    "La cantidad del artículo de la nota de entrega es cero (PO <> DN)",
  workflow_discrepancy_PODNMatching_DeliveryNoteItemQuantityGreaterthan:
    "La cantidad del artículo de la nota de entrega es mayor que la esperada (PO <> DN)",
  workflow_discrepancy_PODNMatching_DeliveryNoteItemQuantityLessThan:
    "La cantidad del artículo de la nota de entrega es menos de lo esperado(PO <> DN)",
  // Volotea Discrepancy
  volotea_discrepancy_item_matericaldesc_notfound: `Descripción del material '{{3}}' no encontrada para el artículo n.° '{{0}}'`,
  volotea_discrepancy_item_po_notfound: `No se encontró el pedido`,
  volotea_discrepancy_item_unitprice_mismatch: `El precio unitario '{{6}}' en la orden de compra '{{3}}-{{4}}' no coincide con el precio unitario '{{5}}'`,
  volotea_validation_item_sum_amount_totalamount_mismatch: `La suma del importe del artículo '{{0}}' no coincide con el importe total '{{1}}'`,
  volotea_validation_item_sum_amount_totalwithouttax_mismatch: `La suma del importe del artículo '{{0}}' no coincide con el importe total sin impuestos '{{1}}'`,
  // Exotica Discrepancy
  exotica_discrepancy_item_bookrefr_empty: `Falta la referencia de reserva en un artículo`,
  exotica_discrepancy_item_netsuiteitemid_empty: `El elemento de Netsuite no está adjunto a un elemento`,
  exotica_discrepancy_item_po_notfound: `No se encontró la orden de compra con referencia de reserva '{{2}}'`,
  exotica_discrepancy_item_amount_mismatch: `El importe {{2}} en el artículo no coincide con el importe {{3}} en la orden de compra con referencia de reserva {{4}}`,
  exotica_discrepancy_totalamount_not_match: `El importe total '{{1}}' no coincide con el importe total de la orden de compra '{{0}}'`,
  exotica_discrepancy_item_po_item_matching_notfound: `La orden de compra con referencia de reserva '{{2}}' no tiene este Id. de artículo de Netsuite '{{3}}'`,
  exotica_discrepancy_item_po_not_exist: `La orden de compra con referencia de reserva '{{2}}' no existe`,
  // Account Class Segregation Discrepancy
  invoicednmatching_account_class_required: `La clase de cuenta es necesaria para hacer coincidir la factura n.° {{0}} con la nota de entrega`,
  invoicepomatching_account_class_required: `La clase de cuenta es necesaria para hacer coincidir la factura n.° {{0}} con la orden de compra`,
  podnmatching_account_class_required: `La clase de cuenta es necesaria para hacer coincidir la orden de compra n.° {{0}} con la nota de entrega`,
  InvoiceDNMatching_ValidateAccountClass: `Validar clase de cuenta`,
  InvoicePOMatching_ValidateAccountClass: `Validar clase de cuenta`,
  PODNMatching_ValidateAccountClass: `Validar clase de cuenta`,
  close: `Cerrar`,
  deleted_document: `Documento eliminado con éxito`,
  delete: `Eliminar`,
  document: `Documento`,
  delete_document_approval: `¿Seguro que quieres eliminar este documento?`,
  delete_documents_approval: `¿Seguro que quieres eliminar este documentos?`,
  delete_all_documents: `¿Estás seguro de que quieres eliminar todos los documentos?`,
  delete_documents_warning: `Los documento(s) eliminados no se pueden recuperar.`,
  cancel_button: `Cancelar`,
  deleting: `Borrando`,
  actions: `Acciones`,
  view: `Vista`,

  signIn_impersonate: `Iniciar sesión Suplantar`,
  impersonate_error: `Por favor cierre sesión para hacerse pasar por una cuenta`,
  token_not_found: `token no encontrado`,
  "All item's description is required.": `Se requiere la descripción de todos los artículos.`,
  "All items(s) subtotalamount must be greater than zero.": `El monto subtotal de todos los artículos debe ser mayor que cero.`,
  "Document is reviewed": `Se revisa el documento`,
  mapping: `Cartografía`,
  mappings: `Cartografías`,
  delete_mapping_approval: `¿Seguro que quieres eliminar este Cartografía?`,
  delete_mappings_approval: `¿Seguro que quieres eliminar este Cartografías?`,
  delete_all_mappings: `¿Estás seguro de que quieres eliminar todos los Cartografías?`,
  delete_mappings_warning: `Los Cartografía(s) eliminados no se pueden recuperar.`,
  successfully_approved: `Aprobado aprobado`,
  successfully_rejected: `Aprobado rechazado`,
  user_id: `ID de usuario`,
  email_address: `Dirección de email`,
  pending_invitation: `Invitación pendiente`,
  send_invite: "Enviar invitación",
  password_changed_successfully: `Contraseña cambiada con éxito`,
  recovery_email_sent: `Correo electrónico de recuperación enviado`,
  change_password: `Cambiar contraseña`,
  resend_invite: `Reenviar invitación`,
  selected: `Seleccionado`,
  minutes: `minutos`,
  Default: `Por defecto`,
  Custom: `Personalizado`,
  view_template: `Ver plantilla`,
  document_type: `Tipo de documento`,
  document_type_format: `Formato de tipo de documento`,
  field_selection: `Selección de campos`,
  mandatory_fields: `Campos obligatorios`,
  optional_fields: `Campos opcionales`,
  purchaseOrder: `Orden de compra`,
  deliveryNote: `Nota de entrega`,
  receipt: `Recibo`,
  unknown: "Desconocido",

  input_format: `Formato De Entrada`,
  date_culture: `Formato de fecha`,
  language_input_format: `Formato de entrada de lenguaje`,
  currency_format: `Formato de moneda`,
  currency_input_format: `Formato de entrada de moneda`,
  dot_is_thousand_comma_is_decimal: `"punto" es separador de miles y "coma" es separador decimal`,
  comma_is_thousand_dot_is_decimal: `"coma" es separador de miles y "punto" es separador decimal`,
  output_format: `Formato de Salida`,
  date: `Fecha`,
  save: `Guardar`,
  successfully_saved: `Guardado Correctamente`,
  something_went_wrong: `Algo salió mal`,
  similar_fields_not_allowed: `Campos similares no permitidos`,
  general_configuration: `Configuraciones Generales`,
  input_CSV_mapping: `Mapeo CSV de entrada`,
  output_CSV_mapping: `Mapeo de CSV de salida`,
  csv_format: `Formato CSV`,
  select_format: `Seleccione un formato`,
  add_custom_field: `Añadir Campo Personalizado`,
  vendor_company_ID: `ID de la empresa proveedora`,
  vendor_wise_config: `Configuración Inteligente de proveedores`,
  vendorId_already_exists: `El ID del proveedor ya existe`,
  customer_company_ID: `ID de la empresa del cliente`,
  customer_wise_config: `Configuración inteligente para el cliente`,
  customerId_already_exists: `El ID del cliente ya existe`,
  upload: `Cargar`,
  Upload: `Subir`,
  file_uploaded_successfully: `Documento cargado exitosamente`,
  upload_CSV_file: `Cargue su archivo CSV para el mapeo`,
  uploaded_file: `Archivo Cargado`,
  download_CSV: `Descargar CSV`,
  file_deleted_successfully: `Archivo eliminado exitosamente`,
  sample: `CSV de muestra`,
  uploaded: `CSV cargado`,
  delete_file: `Eliminar archivo`,
  dialog_title: `Eliminar archivo`,
  dialog_text: `¿Está seguro de que desea eliminar el archivo?`,
  cancel: `Cancelar`,
  yes: `Sí`,
  no: `No`,
  upload_CSV_file_tooltip: `El archivo de mapeo le permite agregar campos y valores en JSON que no forman parte del documento pero que son necesarios en su ERP.`,
  deactivate: `Desactivar`,
  activate: `Activar`,
  time_interval: `Intervalo de tiempo`,
  daily: `Diario`,
  weekly: `Semanal`,
  monthly: `Mensual`,
  activated: `Activado`,
  deactivated: `Desactivado`,
  samplecsv1: `Si VendorCompanyID es igual a A87803355 entonces InternalCompanyCode sería 3359 y ProviderCode sería 7766`,
  samplecsv2: `Si VendorCompanyID es igual a A87803356 entonces InternalCompanyCode sería 3358`,
  samplecsv3: `Si VendorCompanyID es igual a A87803355 y InternalCompanyCode es igual a 3359 y ProviderCode es igual a 'Calle sesea' entonces ProjectCode sería ALUC`,
  sample_eq: `eq = igual`,
  sample_lk: "lk = Me gusta o contiene",
  csv_format_required: `Formato CSV es requerido`,
  email_invalid: `Email inválido`,
  email_required: `Email es requerido`,
  time_interval_required: `Intervalo de tiempo es requerido`,
  template_format: `Formato de plantilla`,
  view_template_format: `Ver formato de plantilla`,
  webhook: `Webhook`,
  destination_URL: `URL del webhook`,
  Enter_Webhook_URL: `Ingrese la URL donde su Webhook debe enviar datos`,
  configure_webhook: `Configurar webhook`,
  webhook_key: `Clave de webhook`,
  select_events: `Eventos para enviar`,
  select_webhook_events: `Seleccione los eventos que deberían activar su webhook`,
  destination_url_required: `La URL de destino es obligatoria`,
  events_required: `Proporcione al menos un evento`,
  configured_webhook: `Webhook configurado con éxito`,
  Activate: `Activar`,
  url_validation: `URL invalida`,
  "Ignored and Mandatory fields must be unique.": `Los campos Ignorados y Obligatorios deben ser únicos.`,
  webhook_key_not_configured: `No configurado`,
  date_format_error: `El formato de fecha está vacío`,
  documentV2: `Documento Configuraciones`,
  Invoice: `Factura`,
  DeliveryNote: `Albarán`,
  PurchaseOrder: `Orden de compra`,
  SimulatedInvoice: `Factura Simulada`,
  custom: `Usanza`,
  timeInterval: `Intervalo de tiempo en minutos`,
  new_mapping: `Nueva configuración de mapeo`,
  bulk_configuration: `Configuración masiva`,
  individual_configuration: `Configuración individual`,
  bulk_csv_upload: `Cargar CSV para mapeo masivo`,
  download: `Descargar`,
  set_mapping_condition: `Establecer condición de mapeo`,
  if_variable: `Si la variable`,
  select_variable: `Seleccione una variable`,
  is_or_contain: `es o contiene`,
  select_condition: `Seleccione una condición`,
  to_value: `al valor`,
  enter_value: `Ingrese un valor`,
  add_condition: `Agregar condición`,
  the_variable: `La variable`,
  would_be: `Sería`,
  then: `Entonces`,
  the_field: `El campo`,
  mapping_configured: `Asignación de entrada configurada`,
  bulk_mapping_first_text: `1. Descargue nuestra plantilla CSV de muestra para la configuración.`,
  bulk_mapping_second_text: `2. Suelte el CSV aquí o haga clic en Examinar en su máquina.`,
  bulk_mapping_third_text: `Mantener datos anteriores`,
  confirm_upload_text: `¿Estás seguro de que quieres cargar este CSV? Una vez cargado el CSV, se perderán los datos anteriores.`,
  sample_csv_template: `CSV de muestra`,
  sample_csv_info: `Se pueden utilizar los siguientes operadores para definir las condiciones de mapeo`,
  eq_equal: `eq = Igual a`,
  lk_like: `lk = Me gusta`,
  sw_startsWith: "sw = Comienza con",
  nsw_notStartsWith: "nsw = No comienza con",
  deleted_mapping: `Mapeo eliminado exitosamente`,
  confirm_upload: `Subir CSV`,
  download_mapping: `Descargar mapeo`,
  add_export_mapping: `Agregar mapeo de exportación`,
  mapping_delete: "Eliminar mapeo",
  delete_mapping_warning: `Las asignaciones eliminadas no se pueden recuperar`,
  id: `ID`,
  receiveType: `Tipo de recepción`,
  template: `Plantilla`,
  deActive: `Desactivado`,
  intervalType: `Tipo de intervalo`,
  customIntervalInMinutes: `Intervalo personalizado en minutos`,
  connector: `Conector`,
  template_required: `Se requiere plantilla`,
  connector_required: `Se requiere conector de almacenamiento`,
  add_export_configuration: `Agregar configuración de exportación`,
  integration_not_connected: `No hay integración conectada, por favor conéctese y vuelva a intentarlo.`,
  connector_differenttype_notallowed: `No se permiten conectores de otro tipo`,
  "You cannot connect more than one output connector.":
    "No se puede conectar más de un conector de salida.",
  "Output csv configured with these configurations.": `CSV de salida configurado con estas configuraciones.`,
  output_mapping_configured: `Mapeo de salida configurado`,
  GreaterThanOrEqual: `Mayor o igual`,
  Equal: `Igual`,
  TotalAmount: `Cantidad total`,
  DocumentStatus: `Estado del documento`,
  Airthmatic: `Aritmética`,
  NeedReview: `Necesita revisión`,
  WorkflowType: `Tipo de flujo de trabajo`,
  Parameter: `Parámetro`,
  Operation: `Operación`,
  InputValue: `Valor de entrada`,
  Approver: `Aprobador`,
  Action: `Acciónes`,
  "Add Workflow": `Agregar flujo de trabajo`,
  name: `Nombre`,
  "Add Condition": `Agregar condición`,
  Document: `Documento`,
  Email: `Correo electrónico`,
  "Review document": `Revisar documento`,
  Submit: `Enviar`,
  Extracted: `Extraído`,
  "Document Status": `Estado del documento`,
  "Total Amount": `Cantidad total`,
  NotEqual: `No igual`,
  GreaterThan: `Mayor que`,
  "Customer Company": `Empresa del cliente`,
  "Invoice Id": `ID de factura`,
  "Invoice Date": `Fecha de factura`,
  "Customer Company Identification": `Identificación de la empresa del cliente`,
  "Total With Out Tax": `Total sin impuestos`,
  "Total Tax": `Impuesto total`,
  "PO Number": `Número de orden de compra`,
  "Customer Company Address": `Dirección de la empresa del cliente`,
  "Vendor Company Name": `Nombre de la empresa del proveedor`,
  "Vendor Company Id": `ID de la empresa del proveedor`,
  "Vendor Company Address": `Dirección de la empresa del proveedor`,
  "Name is required": `El nombre es obligatorio`,
  "Parameter is required": "El parámetro es obligatorio",
  "Operation is required": "La operación es obligatoria",
  "Input Value is required": "El valor de entrada es obligatorio",
  Delete: `Borrar`,
  "Created On": `Creado en`,
  "Workflow Name": `Nombre del flujo de trabajo`,
  disable_workflow_approval: `Deshabilitar la aprobación del flujo de trabajo`,
  workflow_info_0: `Desactivar aprobación`,
  workflow_info_1: `Esto siempre mantendrá el documento adjunto con este flujo de trabajo.`,
  workflow_info_2: `Útil cuando alguien quiere distribuir documentos de la cuenta principal a la subcuenta`,
  disable_approval: `Desactivar aprobación`,
  approval_not_required: `Aprobación no requerida`,
  workflow_created_successfully: `Flujo de trabajo creado exitosamente`,
  review_allowed: `Permitir revision`,
  "Document workflows": `Flujos de trabajo de documentos`,
  "Allow Review Document": `Permitir revisar el documento`,
  Yes: `Sí`,
  No: `No`,
  Review: `Revisar`,
  "Atleast 1 approver is required": `Se requiere al menos 1 aprobador`,
  "New Workflow": `Nuevo flujo de trabajo`,
  "Approval Required": `Aprobación requerida`,
  export_csv: `Exportar CSV`,
  "Export csv(s)": `Exportar csv(s)`,
  review_: `Revisar`,
  AND: `Y`,
  duplicateFieldErrorMessage:
    "el documento '646c5dcac0306b04f7b0df3d' tiene un campo duplicado",
  "Are you sure you want to delete this row?":
    "¿Estás seguro de que quieres eliminar esta fila?",
  "Delete this row?": "¿Eliminar esta fila?",
  "Are you sure you want to delete this field?":
    "¿Estás seguro de que quieres eliminar este campo?",
  "Delete this field?": "¿Eliminar este campo?",
  "Mapping is not valid": `El mapeo no es válido`,
  "50 docs/month": "50 documentos/mes",
  "200 docs/month": "200 documentos/mes",
  "Standard accuracy": "Precisión estándar",
  "Basic support": "Apoyo básico",
  "Unlimited users": "Usuarios ilimitados",
  "600 docs/month": "600 documentos/mes",
  "Priority support": "Asistencia prioritaria",
  "Custom plan": "Plan personalizado",
  "High accuracy": "Alta precisión",
  "Premium support": "Ayuda Premium",
  docs_per_month: " {{count}} documentos/mes",
  "Ad-hoc": "Ad-hoc",
  Free: "Gratis",
  Documents: "Documentos",
  "Click connect to login your Quickbooks account and select company which you want to integrate.\nVendors & Items name should be same for smooth integration.":
    "Haga clic en conectar para iniciar sesión en su cuenta de Quickbooks y seleccione la empresa que desea integrar.\nLos nombres de los proveedores y artículos deben ser los mismos para una integración fluida.",
  "Select your OneDrive folder": "Seleccione su carpeta OneDrive",
  "successfully configured": "configurado con éxito",
  "failed to configure": "no se pudo configurar",
  Configure: "Configurar",
  Disconnect: "Desconectar",
  Connect: "Conectar",
  "Connect Dost with your ": "Conecta Dost con tu ",
  " account": " cuenta",
  "Email send successfully": "Correo electrónico enviado con éxito",
  "Request Partner Assistance to Connect SAPB1":
    "Solicita Ayuda a un Partner para Conectar SAPB1",
  "Dost partners with Artero Consultores (SAP Gold Partner) to ease integration with SAP B1. Specify your version and potential patches (Help > About SAP B1) and request assistance for your integration.":
    "Dost trabaja con Artero Consultores (SAP Gold Partner) para facilitar la integración con SAP B1. Indica la versión y posibles parches (Ayuda > Acerca de SAP B1) y solicita ayuda con tu integración.",
  "Alternatively, you can find the": "O si lo prefieres, aquí tienes la ",
  "technical documentation": "documentación técnica",
  "here to handle the integration yourself.":
    "para hacer tu mismo la integración.",
  "SAP Business 1 version and patches":
    "Versión y parches de tu SAP Business 1",
  "Request Partner Assistance": "Solicita Ayuda a un Partne",
  "desc-businesscentral_output_connector":
    "Conecta Dost con tu cuenta de Microsoft Business Central: <link1></link1>",
  "desc-ftp_upload_output_connector": "Conecta Dost con tu servidor FTP:",
  "desc-holded_output_connector":
    "Vaya al enlace: <link1></link1> y copie/pegue su clave retenida a continuación:",
  "desc-ftp_download_input_connector": "Conecta Dost con tu servidor FTP:",
  "desc-quickbook_output_connector":
    "Haga clic en conectar para iniciar sesión en su cuenta de Quickbooks y seleccione la empresa que desea integrar.\nLos nombres de los proveedores y artículos deben ser los mismos para una integración fluida.",
  "desc-delsol_output_connector": `Proporcione los detalles a continuación para configurar su integración de Delsol`,
  "desc-onedrive_output_connector":
    "Haga clic en Conectar para iniciar sesión en su cuenta y seleccione la carpeta haciendo clic en la opción de configuración",
  "desc-ftp_input_connector": "Conecta Dost con tu servidor FTP:",
  "desc-businesscentral_v2_output_connector":
    "Haz clic en Conectar para iniciar sesión en tu cuenta de Business Central.",
  upload_documents: `Subir documentos`,
  select_language: `Seleccionar idioma`,
  login_tag_line: `Recopila y procesa tus facturas automáticamente garantizando una ejecución sin errores`,
  upload_by_email: `Subir a través del correo electrónico`,
  upload_by_device: `Subir desde el dispositivo`,
  "Drop your files here or browse through your machine.": `Arrastra tus documentos aquí o navega por tu dispositivo.`,
  upload_from_device: `Subir desde el dispositivo`,
  upload_by_supplier: `Subir por proveedor`,
  "Incorrect password.": `Contraseña incorrecta`,
  deliverynotenumber: `Albaran`,
  invoicenumber: `Factura`,
  itemdescription: `Descripción del elemento`,
  deliverynoteitemquantity: `Cantidad de artículos del albarán de entrega`,
  invoiceitemquantity: `Cantidad de artículos de factura`,
  ponumber: `Numero postal`,
  poitemquantity: `Cantidad de artículo de orden de compra`,
  see_related_docs: `Ver documentos relacionados`,
  replace_duplicate: `Reemplazar duplicados`,
  replace_duplicate_explain: `Siempre que se detecte una nueva factura como duplicada en Dost, el sistema reemplazará la factura antigua por la nueva.`,
  erp_sync_configuration: `Configuración de sincronización de ERP`,
  no_erp_connected: `Aún no tienes ninguna ERP conectada`,
  add_an_erp: `Agregar una ERP`,
  created_successfully: `Creado con éxito`,
  updated_successfully: `Actualizado con éxito`,
  all: `Todo`,
  connected_erp_name: `ERP conectado`,
  erp_vendors: `Proveedores de ERP`,
  internalId: `Identificación interna`,
  extractedId: `ID extraída`,
  update_erp_vendors: `Actualizar proveedores de ERP`,
  update: `Actualizar`,
  po_status_mapping: `Mapeo del estado de la orden de compra`,
  Open: `Abierto`,
  Closed: `Cerrado`,
  empty_fields_not_allowed: `El campo de estado es obligatorio`,
  po_status_when_documentType_is_received: `Estado de la orden de compra cuando se recibe {{documentType}}`,
  po_status: `Estado de la orden de compra`,
  disconnected_successfully: `{{erpName}} Desconectado exitosamente`,
  connected_successfully: `{{erpName}} de conexión retenida enviada`,
  open_folder: `Carpeta abierta`,
  generate_auth_key: `Generar clave de autenticación`,
  regenerate: `Regenerado`,
  field_is_required: `Se requiere campo`,
  custom_field: `Campo personalizado`,
  custom_field_validation: `El campo no puede contener espacios en blanco, caracteres especiales ni números.  `,
  select_atleast_one_folder: `Se requiere selección de carpeta`,
  input_mapping_info_0: `Aquí puedes agregar`,
  input_mapping_info_1: `Estático: un número o texto estático. Ejemplo: 12`,
  input_mapping_info_2: `Dinámico: una variable ya existente entre "{ }". Ejemplo: {vendorCompanyID}`,
  input_mapping_info_3: `Fórmula: También puedes crear fórmulas simples con las variables existentes. Utilice variables entre "{ }". Ejemplo: {impuestototal} + 2 x {importetotal}`,
  input_mapping_info: `Aquí puedes agregar
  \\n1. Una variable ya existente entre "{ }". Ejemplo: {vendorCompanyID}
  \\n2. Un número o texto estático. Ejemplo: 12.
  \\n3. También puedes crear fórmulas simples con las variables existentes. Utilice variables entre "{ }". Ejemplo: {impuestototal} + 2 × {montototal}`,
  edit_input_mapping: `Editar mapeo`,
  duplicate_input_mapping: `Duplicar mapeo`,
  delete_input_mapping: "Eliminar asignación",
  original_document: `Documento original`,
  search_field: `Campo de búsqueda`,
  enter_ruleValue: `Ingresar valor de regla`,
  enter_resultValue: `Introduzca el valor del resultado`,
  ruleValue_or_resultValue_required: `Se requiere valor de regla o valor de resultado`,
  searchField_required: `Campo de búsqueda requerido`,
  select_field: `Seleccionar campo`,
  select_your_connector_folder: `Seleccione su carpeta {{connectorName}}`,
  GoogleDrive: `Google Drive`,
  OneDrive: `One Drive`,
  connect_dost_with_your_connector_account: `Conecte Dost con su cuenta {{connectorName}}`,
  no_configuration: `Aún no tienes ninguna configuración`,
  my_account: `Mi cuenta`,
  delete_workflow: `Eliminar flujo de trabajo`,
  delete_workflow_approval: `¿Estás seguro de que deseas eliminar este flujo de trabajo?`,
  delete_workflow_warning: `El flujo de trabajo eliminado no se puede recuperar`,
  // Document Validation Messages
  document_type_unknown: `El tipo de documento es desconocido`,
  mandatory_fields_missing: `Faltan campos obligatorios`,
  mandatory_fields_confidence_low: `La confianza de los campos obligatorios es baja`,
  invoicedate_is_missing: `Falta la fecha de la factura`,
  duedate_is_missing: `Falta la fecha de vencimiento`,
  item_description_missing: `Falta la descripción del artículo`,
  item_amountsum_notequal_totalwithouttax: `La suma del importe del artículo no es igual al total sin impuestos`,
  item_taxsum_notequal_totalttax: `La suma del impuesto del artículo no es igual al impuesto total`,
  vendoraddress_length_greater_130: `La longitud de la dirección del proveedor es superior a 130 caracteres`,
  customeraddress_length_greater_130: `La longitud de la dirección del cliente es superior a 130 caracteres`,
  document_being_processed: `El documento se está procesando`,
  document_is_processing: `El documento se está procesando`,
  document_has_processed: `El documento ha sido procesado`,
  document_is_duplicate: `El documento está duplicado`,
  document_has_failed: `El documento ha fallado`,
  document_need_review: `Es necesario revisar el documento`,
  document_has_reviewed: `El documento ha sido revisado`,
  duedate_missing: `Falta la fecha de vencimiento`,
  ftp_intg_doc_json_disable: "La integración de documentos está deshabilitada.",
  "Document has been integrated": `El documento ha sido integrado`,
  "document is not valid. PDF header signature not found.": `El documento no es válido. No se encontró la firma de encabezado PDF.`,
  "document is not valid. Unknown encryption type R = 5": `El documento no es válido. Tipo de cifrado desconocido R = 5`,
  "document is not valid. This invalid PDF file doesn't have a catalog.": `El documento no es válido. Este archivo PDF no válido no tiene un catálogo.`,
  "Unexpected response from Holded server. Documents limit possibly has been reached.": `Respuesta inesperada del servidor de Holded. Posiblemente se haya alcanzado el límite de documentos.`,
  "Review Document": `Revisar documento`,
  "Folder is not selected. Please select folder first to process document.":
    "La carpeta no está seleccionada. Seleccione primero la carpeta para procesar el documento.",
  "Limit exceeded": `Límite excedido`,
  "Document has been uploaded": `El documento ha sido cargado`,

  // Document Validation Messages with Values
  item_unitprice_missing: `Falta el precio unitario del artículo '{{0}}'`,
  item_quantity_missing: `Falta la cantidad del artículo '{{0}}'`,
  item_amount_missing: `Falta la cantidad del elemento '{{0}}`,
  totalamount_should_be: `El importe TotalAmount debe ser {{0}}`,
  doc_validation_totalwithouttax_should_be: `El total sin impuestos debe ser {{0}}`,
  processed_mandatory_fields_invalid: `{{0}} tiene un valor no válido`,
  doc_table_is_missing: `{{0}} falta`,
  doc_table_is_empty: `{{0}} está vacío`,
  doc_table_field_is_missing: `Falta el campo {{0}} tabla {{2}} en la fila #{{1}}`,
  doc_table_processed_mandatory_fields_invalid: `Datos no válidos en el campo {{0}} tabla {{2}} en la fila #{{1}}`,
  doc_field_validation_formula_incorrect: `Los valores no coinciden en la fórmula {{0}}`,
  doc_validation_item_amount_unitprice_mismatch: `El importe del artículo n.°{{0}} no coincide con (cantidad*precio unitario)`,
  doc_validation_item_sum_amount_totalamount_mismatch: `La suma del importe del artículo no coincide con el importe total`,
  // Workflow
  "Workflow name must be unique":
    "El nombre del flujo de trabajo debe ser único",
  workflow_exists: `El flujo de trabajo ya existe`,
  permission_not_granted: `Permiso no concedido`,
  NeedApproval: `Se requiere aprobación`,
  Approved: "Aprobado",
  Rejected: `Rechazado`,
  // Payments
  payments: `Pagos`,
  payment: `Pago`,
  amount: `Cantidad`,
  createdOn: `Creado en`,
  paymentSource: `Fuente de pago`,
  related_documents: `Documentos relacionados`,
  emailAccount: `Cuenta de correo electrónico`,
  emailDate: `Fecha de correo electrónico`,
  emailPayment: `Pago por correo electrónico`,
  emailAmount: `Importe del correo electrónico`,
  emailProducedBy: `Correo electrónico producido por`,
  payment_details: `Detalles del pago`,
  no_payments_found: `No se encontraron pagos`,
  extracting_documents: `Extraer documentos`,
  no_related_documents_found: `No se encontraron documentos relacionados`,
  discrepancy_detected: `Discrepancia detectada`,
  subject: `Sujeto`,
  subject_required: `Se requiere sujeto`,
  subject_default_text: `Estoy interesado en la Oferta Empresarial`,
  message: `Escriba mensaje`,
  message_required: `Se requiere mensaje`,
  message_defualt_text: ``,
  email_sent: `Se envía correo electrónico`,
  workflow_name: `Nombre del flujo de trabajo`,
  approvedOn: `Aprobado en`,
  approver: `Aprobado por`,
  rejectedOn: `Rechazado el`,
  rejectedBy: `Rechazado por`,
  message_default_text: `Agregue más detalles debajo de esta línea y nos comunicaremos con usted en unas horas.
  <br />
 <h2> --------------------------------------------------------------------------------- </h2>
 <hr></hr>`,
  payment_gateway_connected: `Pasarela de pago conectada`,
  payment_gateway_connected_successfully: `{{paymentGateway}} se conectó correctamente`,
  payment_gateway_connection_request_sent: `{{paymentGateway}}: Solicitud de conexión enviada`,
  create_payment: `Crear pago`,
  payee_details: `Detalles del beneficiario`,
  payee_id: `ID del beneficiario`,
  payee_name: `Nombre del beneficiario`,
  total_documents_amount: `Cantidad total de documentos`,
  document_details: `Detalles del documento`,
  account_details: `Detalles de la cuenta`,
  número_cuenta: `Número de cuenta`,
  account_id: `ID de cuenta`,
  pay_now: `Pagar ahora`,
  pay: `Pagar`,
  accountNumber: `Número de cuenta`,
  accountNumber_required: `El número de cuenta es obligatorio`,
  confirm_paid: `Confirmación de pago`,
  confirm_paid_text: `¿Está seguro de que desea pagar a {{payeeName}} un monto total de {{totalAmount}}`,
  vendorCompanyId: `ID de la empresa proveedora`,
  totalInvoicesAmount: `Importe total de las facturas`,
  paidAmount: `Cantidad pagada`,
  invalid_account_number: `Número de cuenta no válido`,
  make_payment: `Hacer el pago`,
  payment_status: `Estado de pago`,
  payment_is_being_processed: `El pago se está procesando`,
  payment_status_with_value: `Pago {{paymentStatus}}`,
  UnPaid: `No pagado`,
  Paid: `Pagado`,
  Due: `Pendiente`,
  Process: `Tratamiento`,
  Processing: `En proceso`,
  UnKnown: `Desconocido`,
  accountclass_required: `Se requiere clase de cuenta`,
  "#": `n.°`,
  free: `Gratis`,
  no_payment_gateway_connected: `No tienes ninguna pasarela de pago conectada`,
  add_a_payment_gateway: `Agregar pasarela de pago`,
  CardNumber_is_required: `Se requiere el número de tarjeta`,
  Amount_is_required: `Se requiere el monto`,
  CVC_is_required: `Se requiere el CVC`,
  CardNumber: `Número de tarjeta`,
  vendor_details: `Detalles del proveedor`,
  vendor_company_name: `Nombre de la empresa del proveedor`,
  vendor_id: `ID del proveedor`,
  customer_details: `Detalles del cliente`,
  customer_company_name: `Nombre de la empresa del cliente`,
  customer_id: `ID del cliente`,
  payment_method_selection: `Seleccionar método de pago`,
  please_select_a_payment_method: `Por favor seleccione un método de pago`,
  payment_method_details: `Monto a pagar`,
  payment_summary: `Resumen de pago`,
  balance: `Saldo disponible`,
  account_title: `Titulo de cuenta`,
  bank: `Banco`,
  payment_supplier_not_created: `Proveedor no creado`,
  payment_supplier_account_info_not_found: `No se encontró la información de la cuenta del proveedor`,
  payment_invoice_already_paid: `La factura ya se pagó`,
  total_amount: "Total: € {{totalDocumentsAmount}}",
  failed_amount: `Fallido: € {{failedDocumentsAmount}}`,
  transaction_amount: `Éxito: € {{transactionAmount}}`,
  payment_gateway_not_connected: `Pasarela de pago no conectada`,
  "payment_all_selected_document(s)_shouldbe_processed_or_reviewed":
    "Los documentos seleccionados deben procesarse o revisarse",
  payment_account_class_should_be_accountpayable: `La clase de cuenta debe ser cuenta por pagar`,
  "payment_all_selected_document(s)_type_shouldbe_invoice":
    "El tipo de documento seleccionado debe ser factura",
  payment_paid_amunt_equal_selected_document_amount:
    "El importe pagado debe ser igual al importe del documento seleccionado",
  "payment_all_selected_document(s)_type_invoice":
    "Los tipos de documentos seleccionados deben ser factura",
  payment_account_class_shouldbe_account_payable:
    "La clase de cuenta debe ser cuenta por pagar",
  payment_method_is_required: `Se requiere método de pago`,
  payment_invoice_not_found: "Factura no encontrada",
  payment_select_not_deleted_document: `Los documentos eliminados no se pueden seleccionar`,
  vendor_detail: `Detalle del vendedor`,
  process_payment: "Procesar pago",
  payment_successful: `Pago exitoso`,
  your_payment_successfully_processed: `Su pago ha sido procesado exitosamente`,
  total_payment: `Pago total`,
  payment_method: `Método de pago`,
  payment_proces_time: `Tiempo de proceso de pago`,
  sender_name: `Nombre del remitente`,
  transaction_reference_id: `ID de referencia de la transacción`,

  download_receipt: `Descargar recibo`,
  supplier_details: `Detalles del proveedor`,
  create_supplier: `Crear proveedor`,
  payment_has_been_processed: `El pago se procesó exitosamente`,
  payment_you_have_insufficient_balance_to_complete_transaction: `No tiene saldo suficiente para procesar la transacción`,
  created_by: `Creado por`,
  created_on: `Creado el`,
  "payment_all_selected_document(s)_workflow_shouldbe_approved": `El documento(a) seleccionado debe ser aprobado`,
  payment_method_not_found: `Método de pago no encontrado`,
  payment_process_successful: `Proceso de pago exitoso`,
  review_document_payment_has_been_initiated_you_are_not_able_to_review_document: `Se ha iniciado el pago, no se le permite revisar el documento`,
  review_document_document_has_been_paid_you_are_not_able_to_review: `Se ha realizado el pago, no se le permite revisar el documento`,
  transaction_id: `Identificación de transacción`,
  transaction_details: `Detalles de la transacción`,
  processed_transactions: `Transacciones procesadas`,
  failed_transactions: `Transacciones fallidas`,
  successed_transactions: `Transacciones exitosas`,
  transactions: `Actas`,
  count: `Contar`,
  Success: `Éxito`,
  Fail: ``,
  Failed: `Fallido`,

  total_processed: `Total en proceso`,
  total_failed: `Total fallido`,
  total_successed: `Totalmente logrado`,
  total: `Total`,
  reason: `Razón`,
  denied_payment: `Pago denegado`,
  payment_supplier_onboarding_incomplete: `El proveedor no ha completado el onboarding`,
  payment_gateway_account_not_activated: `Cuenta no activada`,
  paymentgateway_account_creation_request_pending: `Solicitud de cuenta pendiente`,
  paymentgateway_notallowed_toconnect_multiplegateways: `No se permite conectar varias pasarelas`,
  paymentgateway_not_found: `No se encontró la pasarela de pago`,
  paymentgateway_account_creation_request_inproces: `Solicitud de creación de cuenta en proceso`,
  paymentgateway_account_creation_request_inprocess: `Solicitud de creación de cuenta en proceso`,
  account_creation_in_process: `Creación de cuenta en proceso`,
  sub_total: `Sub totales`,
  "fee_0%": "Tarifa 0%",
  "Bnak To Bank": `De banco a banco`,
  //  Suppliers
  registration_number: `Número de registro`,
  registration_number_required: `El número de registro es obligatorio`,
  registration_number_validation1: `El número de registro no puede tener más de 20 caracteres`,
  registration_number_validation2: `Se requiere un número de registro válido`,

  account_holder_name: `Nombre del titular de la cuenta`,
  iban: `IBAN`,
  iban_validation_1: `El IBAN debe constar de 24 caracteres`,
  iban_validation_2: `Solo se permiten números y letras`,

  //  Catalog
  catalog: `Catalogar`,
  items: `Elementos`,
  item: `elemento`,
  add_catalog_items: `Agregar elementos`,
  bulk_catalog_items: `Cargar artículos masivos`,
  set_individual_catalog_items: `Agregar artículo individual`,
  catalog_items: `Artículos`,
  no_catalog_items: `Aún no tienes artículos`,
  code: `Código`,
  price: `Precio`,
  item_variations: `Variacións del artículos`,
  item_name: `Nombre del árticulo`,
  item_code: `Código del objeto`,
  item_price: `Precio del articulo`,
  catalog_item_name_required: `Nombre del artículo requerido`,
  catalog_item_code_required: `Código de artículo requerido`,
  catalog_item_price_required: `Artículo Precio requerido`,
  catalog_item_price_validation: `El precio del artículo debe ser un número.`,
  catalog_item_edit: `Editar  elemento`,
  catalog_item_delete: `Eliminar  elemento`,
  catalog_item_deleted_successfully: `Artículo eliminado exitosamente`,
  catalog_item_update_successfully: `Artículo  actualizado exitosamente`,
  catalog_item_added_successfully: `Artículo  agregado exitosamente`,
  add_item_variation: `Agregar variación de artículo`,
  no_item_variation: `No se encontró ninguna variación de artículo`,
  unit_price: `Precio unitario`,
  customerId: `Identificación del cliente`,
  customerId_required: `Se requiere identificación de cliente`,
  add_item: `Añadir artículo`,
  variations: `Variacións`,
  add_variation: `Agregar variación`,
  item_vendorId_required: `Se requiere identificación del proveedor`,
  item_unitPrice_validation: `Se requiere precio unitario`,
  item_variation_edit: `Editar variación`,
  item_variation_delete: `Eliminar variación`,
  item_variation_created: `Variación del artículo agregada exitosamente`,
  item_variation_deleted: `La variación del artículo se eliminó correctamente`,
  edit_item_variations: `Editar variación del artículo`,
  item_variation_edited: `Variación del artículo actualizada correctamente`,
  delete_approval: `Estás seguro de que quieres eliminar esto`,
  delete_approvals: `¿Está seguro de que desea eliminar estos {{deleteEntity}}s?`,
  delete_item_warning: `Si se elimina el elemento, también se eliminará su variación relacionada.`,
  delete_variation_warning: `La variación eliminada no se puede recuperar`,
  bulk_item_first_text: `1. Descargue su plantilla CSV para artículos al por mayor`,
  mail_inbox: `bandeja de entrada de correo`,
  "Either Vendor or Customer ID is required": `Se requiere ID de proveedor o de cliente`,
  "Vendor ID should be empty when Customer ID is filled": `El ID del proveedor debe estar vacío cuando se completa el ID del cliente`,
  "Customer ID should be empty when Vendor ID is filled": `El ID del cliente debe estar vacío cuando se completa el ID del proveedor`,
  "Invalid unit price": `Precio unitario no válido`,
  unitPrice_required: `Se requiere precio unitario`,
  "The amount should be a maximum four digits after decimal": `El importe debe tener un máximo de cuatro dígitos después del decimal.`,
  price_must_be_greater_than_zero: `El precio debe ser mayor que 0`,
  unitPrice_must_be_greater_than_zero: `El precio unitario debe ser mayor que 0`,
  // Export Configuration (Custom Template)
  new_custom_template: `Nueva plantilla personalizada`,
  templateType: `Tipo de plantilla`,
  editTemplate: `Editar plantilla`,
  customTemplate: `Plantilla personalizada`,
  templateNameRequired: `el nombre de la plantilla es obligatorio`,
  fieldNameRequired: `el nombre del campo es obligatorio`,
  fieldDescriptionRequired: `la descripción del campo es obligatoria`,
  SystemDefined: `Sistema definido`,
  templateName: `Nombre de la plantilla`,
  isDefault: `Es predeterminado`,
  fieldName: `Nombre del Campo`,
  fieldDescription: `Campo Descripción`,
  add_field: `Agregue campo`,
  template_cannot_have_duplicate_fields: `La plantilla no puede tener campos duplicados`,
  templateCreatedSuccessfully: `Plantilla creada exitosamente`,
  templateUpdatedSuccessfully: `Plantilla actualizada correctamente`,
  duplicateFieldFound: `Campos duplicados encontrados.`,
  headerFieldFound: `Coloque los campos de la tabla al final de la plantilla.`,
  custom_template_info_0: `Aquí puedes crear una plantilla personalizada`,
  custom_template_info_1: `Se pueden crear varias plantillas`,
  custom_template_info_2: `Sólo uno puede establecerse como predeterminado`,
  custom_template_info_3: `La plantilla predeterminada se utiliza cuando el CSV del documento se descarga en la página de búsqueda de documentos`,
  deleteTemplate: `Eliminar Plantilla`,
  custom_template_delete: `Eliminar {{templateName}} Plantilla`,
  custom_template_deleted: `Plantilla personalizada eliminada correctamente`,
  delete_template_warning: `La plantilla eliminada no se puede recuperar`,
  template_deleted_successfully: `Plantilla eliminada exitosamente`,
  default: `Por defecto`,
  templates: `Plantillas`,
  // Email Inbox
  inbox_is_empty: `La bandeja de entrada está vacía`,
  attachments: `Archivos adjuntos`,
  to: `a`,
  me: `a mí`,
  clear_all: `Limpiar todo`,
  no_attachments: `Sin adjuntos`,
  no_mail: `Sin correo`,
  inbox: `Bandeja de entrada`,
  no_subject: `Sin asunto`,
  no_mail_selected: `No hay correo seleccionado`,
  attachment_name: `Nombre del archivo adjunto`,
  record: `Registro`,
  startDate: `Fecha de inicio`,
  endDate: `Fecha final`,
  search: `Buscar`,
  export_document: `Documento(s) de exportación`,
  template_is_required: `Por favor seleccione una plantilla`,
  download_via_template: `Descargar mediante plantilla`,
  // Create Document
  create_document: `Crear documento`,
  account_information: `Información de la cuenta`,
  document_information: `Información del Documento`,
  customer_information: `Información al cliente`,
  vendor_information: `Informacion del proveedor`,
  items_information: `Información de los artículos`,
  taxes_information: `Información de impuestos`,
  account_class: `Clase de cuenta`,
  document_class: `Clase de documento`,
  optional: `Opcional`,
  account_receivable: `cuenta por cobrar`,
  account_payable: `Cuenta por pagar`,
  accountClass_is_required: `Se requiere clase de cuenta`,
  documentClass_is_required: `Se requiere clase de documento`,
  back: `Atrás`,
  next: `Próximo`,
  invoiceID: `ID de factura`,
  documentNumber: `Document number`,
  poNumber: `Numero postal`,
  "total(WithoutTax)": `Total (sin impuestos)`,
  "tax_amount_not_%": "Impuesto (monto)",
  totalTax: `Total impuestos`,
  taxPercentage: `Porcentaje de impuesto`,
  invoiceDate: `Fecha de la factura`,
  dueDate: `Fecha de vencimiento`,
  invoiceId_is_required: `Se requiere identificación de factura`,
  documentNumber_is_required: `Se requiere el número de documento`,
  poNumber_is_required: `Se requiere el número de orden de compra`,
  totalWithoutTax_is_required: `Se requiere total sin impuestos`,
  totalTax_is_required: `Se requiere el impuesto total`,
  totalAmount_is_required: `Se requiere cantidad total`,
  taxPercentage_is_required: `Se requiere porcentaje de impuesto`,
  deliveryNote_is_required: `Se requiere albarán de entrega`,
  invoiceDate_is_required: `Se requiere fecha de factura`,
  documentDate_is_required: `Se requiere fecha del documento`,

  dueDate_is_required: `Se requiere fecha de vencimiento`,
  customerCompanyName: `Nombre de la empresa del cliente`,
  DocumentCategory: "Categoría de Documento",
  customerCompanyID: `ID de la empresa del cliente`,
  customerCompanyAddress: `Dirección de la empresa del cliente`,
  customerEmail: `Correo electrónico del cliente`,
  customerCompanyName_is_required: `El nombre de la empresa del cliente es obligatorio.`,
  customerCompanyID_is_required: `Se requiere el ID de la empresa del cliente`,
  customerEmail_is_required: `Se requiere de correo electrónico del cliente`,
  customerCompanyAddress_is_required: `Se requiere la dirección de la empresa del cliente`,
  vendorCompanyName: `Nombre de la empresa proveedora`,
  vendorCompanyID: `ID de la empresa proveedora`,
  vendorCompanyAddress: `Dirección de la empresa proveedora`,
  vendorCompanyName_is_required: `Se requiere el nombre de la empresa proveedora`,
  vendorCompanyID_is_required: `Se requiere el ID de la empresa proveedora`,
  vendorCompanyAddress_is_required: `Se requiere la dirección de la empresa proveedora`,
  referenceCode: `Código de referencia`,
  unit: `Unidad`,
  unitPrice: `Precio unitario`,
  vatPercentage: `IVA %`,
  vat: `IVA`,
  dtoPercentage: `DTO %`,
  dto: `DTO`,
  discountPercentage: `% de descuento`,
  discount: `Descuento`,
  subTotalAmount: `Monto subtotal`,
  referenceCode_is_required: `Se requiere código de referencia`,
  description_is_required: `Se requiere descripción`,
  unit_is_required: `Se requiere unidad`,
  unitPrice_is_required: `Se requiere precio unitario`,
  quantity_is_required: `Se requiere cantidad`,
  vatPercentage_is_required: `Se requiere porcentaje de IVA`,
  vat_is_required: `Se requiere IVA`,
  dtoPercentage_is_required: `Se requiere porcentaje de DTO`,
  dto_is_required: `Se requiere DTO`,
  discountPercentage_is_required: `Se requiere porcentaje de descuento`,
  discount_is_required: `Se requiere descuento`,
  subTotalAmount_is_required: `Se requiere el monto subtotal`,
  amount_is_required: `Se requiere cantidad`,
  delete_item: `Eliminar elemento`,
  update_item: `Actualizar elemento`,
  delete_items: `Eliminar objetos`,
  taxes: `Impuestos`,
  baseAmount: `Cantidad base`,
  add_tax: `Agregar impuesto`,
  update_tax: `Actualizar impuesto`,
  submit: `Someter`,
  reset: `Reiniciar`,
  atleast_one_item_is_required: `Se requiere al menos un artículo`,
  atleast_one_tax_is_required: `Se requiere al menos 1 información fiscal`,
  submit_and_create_new_document: `Enviar y crear un nuevo documento`,
  baseAmount_is_required: `Se requiere cantidad base`,
  add_valid_date: `Agregar fecha válida`,
  vendorEmailAddress: `Dirección de correo electrónico del proveedor`,
  vendorEmail_is_required: `Se requiere la dirección de correo electrónico del proveedor`,
  dueDate_should_be_greater_than_documentDate: `La fecha de vencimiento debe ser mayor que la fecha del documento`,
  documentDate_should_be_less_than_dueDate: `La fecha del documento debe ser menor que la fecha de vencimiento`,
  companyName: `Nombre de la empresa`,
  companyID: `ID de empresa`,
  companyAddress: `Dirección de la empresa`,
  companyEmail: `Correo electrónico de la empresa`,
  logo_url: `URL del logotipo`,
  tax: `Impuesto`,
  upload_document: `Subir documento`,
  document_is_required: `El documento es requerido`,
  tax_is_required: `Se requiere impuesto`,
  payable_amount: `Importe a pagar`,
  vendor_status: `Estado del proveedor`,
  documents_attached: `{{totalDocuments}} documento(s)`,

  document_created_successfully: `Documento creado exitosamente`,
  // Analytics
  serial_number: `S.No`,
  documentCount: `Recuento de documentos`,
  top_vendors: `Principales proveedores`,
  uploaded_documents: "Documentos cargados",
  doc_class_distribution: `Distribución de clases de documentos`,
  "Total amount": `Cantidad total`,
  averageDocumentAmountPerMonth: `Cantidad total promedio`,
  totalAmountPerDocumentClass: `Distribución por clase del importe total`,
  chat_component_header_text: `Hola {{username}}! ¿Cómo puedo ayudarte?`,
  try_dosti: `Prueba Dosti`,
  ask_ai: `Pregúntale a la AI`,
  ask_ai_about_documents: `Pregúntale a IA sobre documentos`,
  suggested_prompts: `Sugerido`,
  "How many documents have I uploaded so far?":
    "¿Cuántos documentos he subido hasta ahora?",
  "What are the names of the top vendors?":
    "¿Cuáles son los nombres de los principales proveedores?",
  "What is the total amount of each document?":
    "¿Cuál es la cantidad total de cada documento?",
  // Users
  delete_user: `Eliminar usuario`,
  user_deleted: `Usuario eliminado exitosamente`,
  deactivated_successfully: `Desactivado con éxito`,
  activated_successfully: `Activado exitosamente`,
  delete_user_warning: `¿Estás seguro de que quieres eliminar el usuario '{{username}}'?`,
  isActive: `Está Activo`,
  deactivate_user: `Desactivar usuario`,
  activate_user: `Activar usuario`,
  deactivate_user_warning: `¿Estás seguro de que deseas desactivar el usuario '{{userName}}'?`,
  activate_user_warning: `¿Estás seguro de que quieres activar el usuario '{{userName}}'?`,
  copyright_message: `Reservados todos los derechos`,
  field_must_be_a_number: `El campo debe ser un número`,
  delete_invitation: `Eliminar invitación`,
  delete_invitation_warning: `¿Estás segura de que deseas eliminar la invitación enviada a {{username}}?`,
  invitation_deleted: `Invitación eliminada exitosamente`,
  enable_testing_mode: `Habilitar el modo de prueba`,
  testing_mode_enabled: `Modo de prueba habilitado`,
  testing_mode_disabled: `Modo de prueba deshabilitado`,
  disable_testing_mode: `Deshabilitar el modo de prueba`,
  enable_testing_mode_warning: `¿Está seguro de que desea habilitar el modo de prueba para el usuario {{username}}?`,
  disable_testing_mode_warning: `¿Está seguro de que desea deshabilitar el modo de prueba para el usuario {{username}}?`,
  test_mode: `Modo de prueba`,

  // Self Training Reports
  self_training_reports: `Informe de Autoentrenamiento`,
  start_date: `Fecha de inicio`,
  end_date: `Fecha de finalización`,
  start_date_is_required: `La fecha de inicio es obligatoria`,
  end_date_is_required: `La fecha de finalización es obligatoria`,
  requests: `Solicitudes`,
  tag_name: `Nombre de etiqueta`,
  request_status: `Estado`,
  req_sent_date: `Fecha de envío de la solicitud`,
  new_model: `Nuevo modelo`,
  pending_requests: `Solicitudes pendientes`,
  document_ids: `ID de documentos`,
  filter: `Filtros`,
  end_date_cannot_be_of_future_date: `La fecha de finalización no puede ser una fecha futura`,
  export_as_csv: `Exportar como CSV`,
  in_process: `En proceso`,
  // Export Configuration
  consider_document_when: `Considere el documento cuando`,
  isConsiderDocumentInitialProcessed: `El documento se procesó inicialmente`,
  isConsiderDocumentReviewed: `El documento se revisó`,
  isConsiderDocumentPaymentReceived: `Se recibió el pago del documento`,
  atleast_one_option_must_be_checked: `Se requiere al menos una opción`,
  document_consideration: `Consideración del documento`,
  view_docIds: `Ver los identificadores de los documentos`,
  // Document Listing Page
  to_delete_documents: `Los documentos {{documents}} no se pueden eliminar porque ya están creados en ERP, deselecciónelos y vuelva a intentarlo.`,
  information: `Información`,
  review_document: `Revisar documento`,
  view_discrepancy: `Ver discrepancia`,
  field_operator: `Operador de campo`,
  // Dashbord
  due_amount: "Monto adeudado",
  paid_amount: "Importe pagado",
  documents_processed: `Documentos procesados`,
  document_is_deleted: `El documento ha sido eliminado`,
  document_is_integrated: `El documento está integrado`,
  review_document_payment_paid: `El pago ha sido realizado`,
  review_document_payment_processing: `El pago está siendo procesado`,
  current_month: `Mes actual`,
  // Mapping V2
  upload_requests: `Subir solicitudes`,
  download_requests: `Descargar solicitudes`,
  no_upload_requests_found: `No se encontraron solicitudes de carga`,
  confirm_upload_request: `¿Estás segura de que quieres subir este CSV?`,
  completed_on: `Completado el`,
  upload_bulk_configuration: `Cargar configuración masiva`,
  upload_mapping_rquest_inprocess: `Solicitud de mapeo en proceso`,
  upload_mapping_not_valid: `Mapeo no válido`,
  upload_mapping_csv_format_allowed: `Formato CSV de mapeo permitido`,
  upload_mapping_organization_not_found: `Organización de mapeo no encontrada`,
  mapping_download_request_inprocess: `Solicitud de descarga en proceso`,
  download_mapping_not_found: `Mapeo no encontrado`,
  download_mapping_status_failed: `Estado de mapeo fallido`,
  Uploaded: "Subido",
  add_individual_configuration: `Añadir configuración individual`,
  no_download_requests_found: `No se encontraron solicitudes de descarga`,
  download_request_sent: `Solicitud de descarga enviada`,
  download_bulk_configuration: `Descargar configuraciones en bloque`,
  add_individual_mapping: `Agregar mapeo individual`,
  upload_bulk_mapping: `Subir mapeo masivo`,
  download_bulk_mapping: `Descargar mapeo`,
  generated: `Generado`,
  processing: `En proceso`,
  selected_documents_already_approved_or_rejected: `Los documentos seleccionados ya están aprobados o rechazados`,
  expectedvalue_bulk_import_duplicate_label: `Etiqueta duplicada encontrada`,
  value_is_required: `Se requiere valor`,
  payment_made_successfully: `Pago exitoso`,

  errors: {
    "Server Error": `Error de Servidor`,
    "document type 'Invoice' has duplicate fields.": `el tipo de documento 'Factura' tiene campos duplicados.`,
  },

  workflows_screen: {
    title: "Descubre el poder de los flujos de trabajo",
    workflows: "Flujos de trabajo",
    add: "Agregar flujo de trabajo",
    subtitle: "Flujo de trabajo de facturas",
    alertActivateMessage: "Activado exitosamente",
    alertDeactivateMessage: "Desactivado exitosamente",
    activate: "Activar",
    deactivate: "Desactivar",
    save: "Guardar",
    invoice: "Factura",
    total_amount: "Cantidad total",
    email_to_approve: "Aprobar correo electrónico",
    greater_than: "Mas grande que",
    greater_than_or_equal_to: "Mayor qué o igual a",
    dashboard: "Tablero",
  },
  approval_status: {
    approvalRequired: "Aprobación requerida",
    approved: "Aprobado",
    rejected: "Rechazado",
    reviewRequired: "Revisión requerida",
  },
  approval_screen: {
    review: "Revisar",
    title: "Documentos pendientes de aprobación",
    filename: "Nombre del archivo",
    totalAmount: "Cantidad total",
    status: "Estado",
    all: "Todo",
    invoices: "Facturas",
    reciepts: "Ingresos",
    processedDate: "Fecha de procesamiento",
    yes: "Sí",
    no: "No",
    action: "Acciones",
    approve: "Aprobar",
    reject: "Rechazar",
    alertRejectMessage: "¿Estás seguro de que quieres rechazar este documento?",
    alertApproveMessage: "¿Estás seguro de que quieres aprobar este documento?",
    alertRejectTitle: "Rechazar",
    alertApproveTitle: "Aprobar",
    workflowName: `Nombre del flujo de trabajo`,
  },
  configure: "Configurar",
  "approve / reject": "Aprobar / rechazar",
  verification: {
    title: "Verificar tu cuenta",
    success: "Tu cuenta ha sido verificada exitosamente",
    error: "No se pudo verificar tu cuenta",
    loading: "Verificando tu cuenta...",
    retry: "Reenviar correo de verificación",
  },
  "verification link is expired. please re-generate verification link.":
    "El enlace de verificación ha caducado. Por favor, vuelva a generar el enlace de verificación.",
  "account is already verified": "La cuenta ya está verificada",
  navbar_account: {
    trial: "Prueba",
    pro_trial: "Prueba Pro",
    pro: "Pro",
    Pro: "Pro",
    days_left: "Días restantes",
    of: "de",
    Unverified: "Cuenta no verificada",
    starter: "Inicial",
    Starter: "Inicial",
    basic: "Básico",
    Basic: "Básico",
    tooltip_text: "Haz clic para ver más detalles",
  },
  Enterprise: "Empresa",
  Basic: "Básico",
  reviewToolbar: {
    previous_page: "Página anterior",
    next_page: "Página siguiente",
    zoomin: "Acercar",
    zoomout: "Alejar",
    deselect_multiple: "Deseleccionar múltiples",
    select_multiple: "Seleccionar múltiples",
    done: "Hecho",
    hide_info: "Ocultar información",
    show_info: "Mostrar información",
  },

  delsol_output_connector: {
    factoryCode: `Código de fábrica`,
    customerCode: `Código de cliente`,
    database: `Database`,
    password: `Password`,
  },

  ftp_upload_output_connector: {
    FtpHost: "Host",
    FtpPort: "Puerto",
    FtpUserId: "Usuario",
    FtpPassword: "Contraseña",
    FtpPath: "Ruta",
    IsTLS: "Es TLS",
    IsSFTP: "Es SFTP",
  },
  ftp_output_connector: {
    FtpHost: "Host",
    FtpPort: "Puerto",
    FtpUserId: "Usuario",
    FtpPassword: "Contraseña",
    FtpPath: "Ruta",
    IsTLS: "Es TLS",
    IsSFTP: "Es SFTP",
    DisableIndividualDocumentIntegration:
      "Deshabilitar la integración de documentos individuales.",
    DisableIndividualDocumentIntegration_hint:
      "Cuando está habilitado, desactivará la integración de documentos individuales. Esto es útil si solo se requiere la exportación a CSV.",
  },
  ftp_input_connector: {
    FtpHost: "Host",
    FtpPort: "Puerto",
    FtpUserId: "Usuario",
    FtpPassword: "Contraseña",
    FtpPath: "Path",
    IsTLS: "Es TLS",
    IsSFTP: "Es SFTP",
  },
  ftp_download_input_connector: {
    FtpHost: "Host",
    FtpPort: "Puerto",
    FtpUserId: "Usuario",
    FtpPassword: "Contraseña",
    FtpPath: "Ruta",
  },
  businesscentral_output_connector: {
    tenant: "Inquilino",
    clientId: "ID de cliente",
    clientSecret: "Secreto del cliente",
    environmentName: "Nombre del entorno",
    companyId: "ID de la empresa",
    apiPublisher: "Editor de API",
    apiGroup: "Grupo de API",
    apiVersion: "Versión de API",
  },
  holded_output_connector: {
    APIKey: "Clave API",
    enableLegacyHoldedIntegration: "Habilitar integración heredada de Holded",
    apiKey: "Clave API",
  },
  cardonar_output_connector: {
    username: `Nombre de usuario`,
    password: `Contraseña`,
    authEndpoint: `URL de autenticación`,
    documentEndpoint: `URL del documento`,
  },
  asap_output_connector: {
    userName: `Nombre de usuario`,
    password: `Contraseña`,
    authEndpoint: `URL de autenticación`,
    documentEndpoint: `URL del documento`,
  },
  volotea_output_connector: {
    ApiKey: `Clave API`,
    BaseUrl: `URL base`,
    AccessKey: `Clave de acceso`,
    SecretKey: `Clave secreta`,
    ServiceName: `Nombre del servicio`,
    AwsRegion: `Región de AWS`,
    InvoiceCreateUrl: `URL para crear factura`,
  },
  comforsa_output_connector: {
    UserName: `Nombre de usuario`,
    Password: `Contraseña`,
    Company: `compañía`,
    Language: `Idioma`,
    BaseUrl: `URL base`,
  },

  odoo_output_connector: {
    apiurl: "API URL",
    dbname: "Nombre de la base de datos",
    username: "Nombre de usuario",
    password: "Contraseña",
  },

  sagex3_output_connector: {
    poolid: `ID del grupo`,
    codelanguage: `Idioma del código`,
    username: "Nombre de usuario",
    password: "Contraseña",
  },

  netsuite_docCls_actCls_required:
    "Se requieren la clase de documento y la clase de cuenta de Netsuite",
  netsuite_integration_unsupported: `No se admite la integración con Netsuite`,
  netsuite_subsidiaryid_required: `Se requiere la identificación de la subsidiaria de Netsuite`,
  netsuite_vendorid_required: `Se requiere la identificación del proveedor de Netsuite`,
  netsuite_items_required: `Se requieren los artículos de Netsuite`,
  netsuite_itemid_or_acctid_required: `Se requiere el ID del artículo de Netsuite o el ID de la cuenta (solo debe seleccionarse uno).`,
  netsuite_item_qty_required: `Se requiere la cantidad de artículos de Netsuite`,
  netsuite_item_unitprice_required: `Se requiere el precio unitario del artículo de Netsuite`,
  netsuite_item_creation_not_supported: "Creación de artículo no compatible",

  "Company default language": "Idioma predeterminado de la empresa",

  "Download from FTP (v2)": "Descargar desde FTP (v2)",
  "Duplicate workflow": "Duplicar flujo de trabajo",
  "Delete workflow": "Eliminar flujo de trabajo",
  "View workflow": "Ver flujo de trabajo",
  "You cannot connect more than one output-connector.":
    "No puedes conectar más de un conector de salida.",
  "Hello ": "Hola ",
  Yesterday: "Ayer",
  "This Week": "Esta Semana",
  "Last Week": "La Semana Pasada",
  "This Month": "Este Mes",
  "Last Month": "El Mes Pasado",
  "This Quarter": "Este Trimestre",
  "Last Quarter": "El Trimestre Pasado",
  "This Year": "Este Año",
  "Last Year": "El Año Pasado",
  All: "Todo",
  Today: "Hoy",
  "Integrations status": "Estado de las integraciones",
  "Processing overview": "Resumen de procesamiento",
  View: "Ver",
  "documents / month": "documentos / mes",
  "Your current plan is ": "Tu plan actual es ",
  enterprise: "Empresa",
  "Not convinced with your current plan?":
    "¿No estás convencido con tu plan actual?",
  "View our offers": "Ver nuestras ofertas",
  "View documents": "Ver documentos",
  "The document extraction is completed":
    "La extracción del documento está completada",
  "See more": "Ver más",
  "Documents pending Approval": "Documentos pendientes de aprobación",
  "Dost Academy": "Academia Dost",
  "My account": "Mi cuenta",
  Logout: "Cerrar sesión",
  "Users management": "Gestión de usuarios",
  processed_on: "Procesado el",
  Subscribe: "Suscribirse",
  Cancel: "Cancelar",
  browse: "Examinar",
  "Suppliers Management": "Gestión de proveedores",
  "Add a new supplier": "Añadir un nuevo proveedor",
  "You don't have any suppliers yet": "Aún no tienes ningún proveedor",
  "Add Supplier": "Añadir Proveedor",
  "Supplier Information": "Información del Proveedor",
  DiscrepancyDetected: "Discrepancia detectada",
  CustomerCompanyName: "Nombre de la empresa del cliente",
  DocumentClass: "Clase de documento",
  discrepancies_: "discrepancias",
  "document limit exceed": "límite de documentos excedido",
  "documents pending approval": "documentos pendientes de aprobación",
  "new successfull extraction": "nueva extracción exitosa",
  "Extracting Documents": "Extrayendo Documentos",
  "Awaiting Extraction...": "Esperando Extracción...",
  copy: "copiar",
  copied: "copiado",
  Close: "Cerrar",
  "View Document": "Ver Documento",
  "Download CSV": "Descargar CSV",
  "Download JSON": "Descargar JSON",
  "View original": "Ver original",
  "Download document": "Descargar documento",
  "View document": "Ver documento",
  "Download Invoice(s)": "Descargar Original(es)",
  "Download Invoice": "Descargar Original",
  "Workflow settings": "Configuración del flujo de trabajo",
  "View all documents": "Ver todos los documentos",
  "New workflow": "Nuevo flujo de trabajo",
  "Workflows configured": "Flujos de trabajo configurados",
  "Workflow name": "Nombre del flujo de trabajo",
  "Put a name for your workflow": "Pon un nombre para tu flujo de trabajo",
  And: "Y",
  "If the variable": "Si la variable",
  "Variable selected": "Variable seleccionada",
  Is: "Es",
  "Condition selected": "Condición seleccionada",
  "to the value": "al valor",
  Value: "Valor",
  Approvers: "Aprobadores",
  "Add a new email": "Añadir un nuevo correo electrónico",
  "See documents related": "Ver documentos relacionados",
  "Conditions configured": "Condiciones configuradas",
  Conditions: "Condiciones",
  Condition: "Condición",
  "Created on": "Creado el",
  "Document Name": "Nombre del Documento",
  "Processed on": `Procesado el`,
  "Status in workflow": `Estado en el flujo de trabajo`,
  "Output data": `Datos de salida`,
  "Document configuration": `Configuración del documento`,
  Mandatory: `Obligatorio`,
  "Create custom field": `Crear campo personalizado`,
  "Input format configuration": `Configuración del formato de entrada`,
  "Add Vendor Configuration": `Agregar configuración de proveedor`,
  "Create vendor configuration": `Crear configuración de proveedor`,
  "Create customer configuration": `Crear configuración de cliente`,
  "Input Mapping Configuration": `Configuración de mapeo de entrada`,
  "Input Mapping": `Mapeo de entrada`,
  download_template: `Descargar plantilla`,
  "Entries per page:": `Entradas por página:`,
  "Document status": `Estado del documento`,
  "Document name": `Nombre del documento`,
  "ERP integrated": `ERP integrado`,
  "Approval status": `Estado de aprobación`,
  "End date": `Fecha de finalización`,
  "Start date": `Fecha de inicio`,
  approved: `Aprobado`,
  rejected: `Rechazado`,
  pending: `Pendiente`,
  "Connector failed to connect. Please try again with valid credentials.": `El conector no pudo conectarse. Por favor, inténtelo de nuevo con credenciales válidas.`,
  "Password is required": "Se requiere contraseña",
  "Email is required": "Se requiere correo electrónico",
  "Document reviewed": "Documento revisado",
  "Search ...": "Buscar ...",
  of: "de",
  of_: `de`,
  "Allow review document": "Permitir revisar el documento",
  "Items per page": "Artículos por página",
  Dashboard: "Tablero",
  "This is a required field": "Este es un campo obligatorio",
  Messages: "Mensajes",
  Fields: "Campos",
  "Culture setting": "Configuración de cultura",
  "Format setting": `Configuración de formato`,
  Other: "Otro",
  "Document Type": "Tipo de documento",
  "Default Configuration": "Configuración por defecto",
  "Mapping Configuration": "Configuración de mapeo",
  "Export Configuration": "Configuración de exportación",
  "Developers settings": "Configuración de desarrolladores",
  "Developers Section": "Sección de desarrolladores",
  URL: "URL",
  Key: "Clave",
  API: "API",
  "Generate Api Secret": "Generar Api Secret",
  "Developer settings": "Configuración de desarrolladores",
  Documentation: "Documentación",
  here: "aquí",
  "developer-docs":
    "Para acceder a la documentación para desarrolladores, haga clic en el <link1>enlace</link1> proporcionado a continuación.",
  "View table": "Ver tabla",
  "Delete table": "Eliminar tabla",
  "Document review": "Revisión de documentos",
  Download: "Descargar",
  csv: "CSV",
  excel: "excel",
  XML: "XML",
  text: "texto",
  JSON: "JSON",
  zip: "zip",
  "csv(s)": "CSV(s)",
  "excel(s)": "excel(s)",
  "XML(s)": "XML(s)",
  "text(s)": "texto(s)",
  "JSON(s)": "JSON(s)",
  "zip(s)": "zip(s)",
  "Export original": "Exportar original",
  "Export original(s)": "Exportar original(es)",
  "Copy link": "Copiar enlace",
  "documents are uploaded till now": "Documentos en el Portal",
  "Are you sure you want to delete this supplier?":
    "¿Estás seguro de que quieres eliminar este proveedor?",
  hours: "horas",
  Equals: "Igual",
  Like: "Contiene",
  like: "Contiene",
  StartsWith: "Empieza con",
  startsWith: "Empieza con",
  NotStartsWith: "No empieza con",
  notStartsWith: "No empieza con",
  EndsWith: "No empieza con",
  endsWith: "No empieza con",
  NotEquals: "No es igual",
  NotLike: "Diferente a",
  "Network Error": "Error de red",
  Mapping: "Mapeo",
  Export: "Exportar",
  "ERP Sync": "Sincronización de ERP",
  Suppliers: "Proveedores",
  Save: "Guardar",
  VendorCompanyName: "Nombre de la empresa del proveedor",
  TotalwithoutTax: "Total sin impuestos",
  TotalTax: "Impuesto total",
  VendorCompanyID: "ID de la empresa del proveedor",
  VendorCompanyAddress: "Dirección de la empresa del proveedor",
  InvoiceID: "ID de factura",
  InvoiceDate: "Fecha de factura",
  CustomerCompanyID: "ID de la empresa del cliente",
  CustomerCompanyAddress: "Dirección de la empresa del cliente",
  PONumber: "Número de orden de compra",
  Filters: "Filtros",
  "Document Fields": "Campos del documento",
  "Field name": "Nombre del campo",
  Add: "Agregar",
  "Clear all": "Limpiar todo",
  send: "Enviar",
  "Add an item": "Agregar un artículo",
  Subtotal: "Subtotal",
  previous_page: "Página anterior",
  next_page: "Página siguiente",
  Line: "Línea",
  "Are you sure you want to delete this column?": `¿Estás seguro de que quieres eliminar esta columna?`,
  "Are you sure you want to delete this table?": `¿Estás seguro de que quieres eliminar esta tabla?`,
  "Delete this table?": `¿Eliminar esta tabla?`,
  "Deleting this column will remove all the rows in this table.": `Eliminar esta columna eliminará todas las filas de esta tabla.`,
  Continue: `Continuar`,
  Back: `Atrás`,
  "Coming soon!": `¡Próximamente!`,
  source: `Fuente`,
  isFailed: `Ha fallado`,
  failureReason: `Razón del fallo`,
  documentStatus: "Estado del documento",
  processingSteps: "Pasos de procesamiento",
  workflowName: "Nombre del flujo de trabajo",
  reviewedBy: "Revisado por",
  approvalStatus: "Estado de aprobación",
  approverOrRejector: "Aprobador/Rechazador",
  "Document Upload": "Subir documento",
  "Document Processed": "Documento procesado",
  "Workflow Execute": "Ejecutar flujo de trabajo",
  "Workflow Approve/Reject": "Aprobar/Rechazar flujo de trabajo",
  "Document Integrate": "Integrar documento",
  "Document Review": "Revisar documento",
  "Document History": "Historial de documentos",
  "View history": "Ver historial",
  "Add Field": "Agregar campo",
  Name: "Nombre",
  "Data type": "Tipo de dato",
  Actions: "Acciones",
  Number_Fractional: "Número (Fraccional)",
  Date: "Fecha",
  Text: "Texto",
  field_type: "Tipo de campo",
  Number_Whole: "Número (Entero)",
  Header: "Encabezado",
  TableProperty: "Propiedad de tabla",
  "To add a new field, please enter the field name in the text field below.": `Para agregar un nuevo campo, por favor ingrese el nombre del campo en el campo de texto a continuación.`,
  "Field type": "Tipo de campo",
  "Table name": "Nombre de la tabla",
  "No PDF file specified": "No se ha especificado ningún archivo PDF",
  "Error loading PDF": "Error al cargar el PDF",
  "Fields configuration": "Configuración de campos",
  "Field name should be unique": "El nombre del campo debe ser único",
  "Field name cannot contain space":
    "El nombre del campo no puede contener espacios",
  "Field name should start with capital letter":
    "El nombre del campo debe comenzar con letra mayúscula",
  "Field name should not contain special characters":
    "El nombre del campo no debe contener caracteres especiales",
  "Field name and Table name should not be same":
    "El nombre del campo y el nombre de la tabla no deben ser iguales",
  "Field Name is required": `Se requiere el nombre del campo`,
  "Data type should be same": `El tipo de dato debe ser el mismo`,
  "Data type is required": `Se requiere el tipo de dato`,
  "Table name is required": `Se requiere el nombre de la tabla`,
  "Table name should be plural": `El nombre de la tabla debe ser plural`,
  "Table name cannot contain space": `El nombre de la tabla no puede contener espacios`,
  "Table name should start with capital letter": `El nombre de la tabla debe comenzar con letra mayúscula`,
  "Table name should not contain special characters": `El nombre de la tabla no debe contener caracteres especiales`,
  "Document Discrepancy": `Discrepancia del documento`,
  "Document detail": `Detalle del documento`,
  "Related documents": `Documentos relacionados`,
  "No related documents found.": `No se encontraron documentos relacionados.`,
  "no documents": `no hay documentos`,
  "No items": `No hay elementos`,
  "No items with discrepancies found.": `No se encontraron elementos con discrepancias.`,
  "No documents Found": "No se encontraron documentos",
  "Access Forbidden": `Acceso prohibido`,
  "You don't have permission to access this page": `No tienes permiso para acceder a esta página`,
  "Go to homepage": `Ir a la página de inicio`,
  "The page you are looking for might have been removed or its name changed or is temporarily unavailable.": `La página que buscas puede haber sido eliminada o su nombre cambiado o estar temporalmente no disponible.`,
  "Page not found": `Página no encontrada`,
  User: `Usuario`,
  Role: `Rol`,
  "Edit role": `Editar rol`,
  "Default roles": `Roles por defecto`,
  "Custom roles": `Roles personalizados`,
  "Add new role": `Agregar nuevo rol`,
  "Role configuration": `Configuración de rol`,

  "Create a new role": `Crear un nuevo rol`,
  "Enter role name": `Introducir nombre de rol`,
  "Access is granted for": `El acceso se concede para`,
  Section: `Sección`,
  Read: `Leer`,
  Write: `Escribir`,
  "Discard changes": `Descartar cambios`,
  "Save changes": `Guardar cambios`,
  roleName: {
    OrgAdmin: "Administrador",
    OrgUser: "Usuario",
  },
  sectionName: {
    Documents: "Documentos",
    MappingConfigurations: "Configuraciones de mapeo",
    DocumentConfigurations: "Configuraciones de documentos",
    ExportConfigurations: "Configuraciones de exportación",
    UserManagement: "Gestión de usuarios",
    Suppliers: "Proveedores",
    RoleManagement: "Gestión de roles",
    Integrations: "Integraciones",
    WorkflowManagement: "Gestión de flujos de trabajo",
    WorkflowApproval: "Aprobación de flujos de trabajo",
    DeveloperSettings: "Configuración de desarrolladores",
    CatalogManagement: "Gestión de catálogos",
    Payments: "Pagos",
    Subscription: "Suscripción",
    ExportTemplates: "Plantillas de exportación",
    Customers: "Clientes",
  },
  permissions: {
    Documents_Read: "Documentos leer",
    Documents_Write: "Documentos escribir",
    MappingConfigurations_Read: "Configuraciones de mapeo leer",
    MappingConfigurations_Write: "Configuraciones de mapeo escribir",
    DocumentConfigurations_Read: "Documentos configuraciones leer",
    DocumentConfigurations_Write: "Documentos configuraciones escribir",
    ExportConfigurations_Read: "Exporte configuraciones leer",
    ExportConfigurations_Write: "Exporte configuraciones escribir",
    UserManagement_Read: "Gestión de usuarios leer",
    UserManagement_Write: "Gestión de usuarios escribir",
    Suppliers_Read: "Proveedores leer",
    Suppliers_Write: "Proveedores escribir",
    RoleManagement_Read: "Gestión de roles leer",
    RoleManagement_Write: "Gestión de roles escribir",
    Integrations_Read: "Integraciones leer",
    Integrations_Write: "Integraciones escribir",
    WorkflowManagement_Read: "Gestión de flujos de trabajo leer",
    WorkflowManagement_Write: "Gestión de flujos de trabajo escribir",
    WorkflowApproval_Read: "Gestión de aprobación de flujos de trabajo leer",
    WorkflowApproval_Write:
      "Gestión de aprobación de flujos de trabajo escribir",
    DeveloperSettings_Read: "Configuración de desarrolladores leer",
    DeveloperSettings_Write: "Configuración de desarrolladores escribir",
    CatalogManagement_Read: "Gestión de catálogos leer",
    CatalogManagement_Write: "Gestión de catálogos escribir",
    Payments_Read: "Pagos leer",
    Payments_Write: "Pagos escribir",
    Subscription_Read: "Suscripción leer",
    Subscription_Write: "Suscripción escribir",
    ExportTemplates_Read: "Plantillas de exportación leer",
    ExportTemplates_Write: "Plantillas de exportación escribir",
  },
  "Read and write": `Leer y escribir`,
  "Role already exists": `El rol ya existe`,
  "Role name is required": `Se requiere el nombre del rol`,
  "Permissions are required": `Se requieren permisos`,
  Permissions: `Permisos`,
  "Read / write": `Leer / escribir`,
  "Select at least one permission": `Selecciona al menos un permiso`,
  "Delete this role?": `¿Eliminar este rol?`,
  "Are you sure you want to delete this role?": `¿Estás seguro de que quieres eliminar este rol?`,
  Type: `Tipo`,
  "Role name": `Nombre del rol`,
  "User updated successfully": `Usuario actualizado con éxito`,
  "Email must be a valid email address": `El correo electrónico debe ser una dirección de correo electrónico válida`,
  "Role is required": `Se requiere el rol`,
  "Field expected values": `Valores esperados del campo`,
  "Add expected values": `Agregar valores esperados`,
  Description: `Descripción`,
  Edit: `Editar`,
  "Expected Values": `Valores esperados`,
  "Expected value already exists please add unique description and value.": `El valor esperado ya existe, por favor añade una descripción y un valor únicos.`,
  field_title: "Valores de {{field}}",
  "Add predefined value": "Agregar valor predefinido",
  add_predefined_values: `Agregar valores predefinidos`,
  upload_bulk_predefined_values: `Subir valores en bloque`,
  add_individual_predefined_value: `Añadir valor predefinido`,
  expeectedvalue_upload_csv_format_allowed: `Cargue un archivo CSV válido en el formato permitido`,
  expectedvalue_upload_file_notvalid: `El archivo cargado no es válido. Cargue un archivo válido`,
  expectedvalue_upload_only_required_lableandvalue: `Solo se permiten los campos de etiqueta y valor obligatorios para la carga.`,
  bulk_predefined_values_upload: `Cargar CSV para valores predefinidos en masa`,
  expected_value_first_text: `1. Descargue nuestra plantilla CSV de muestra para valores predefinidos.`,
  "Predefined values": "Valores predefinidos",
  download_predefined_values_csv: `Descargar valores predefinidos CSV`,

  Label: "Etiqueta",
  "Document Messages": "Mensajes del documento",
  Notifications: "Notificaciones",
  "Mark all as read": `Marcar todo como leído`,
  "No new notifications": `No hay nuevas notificaciones`,
  "Mark this notification as read": `Marcar esta notificación como leída`,
  "Download Document": "Descargar documento",
  "Delete this document?": "¿Eliminar este documento?",
  "Are you sure you want to delete this document?":
    "¿Estás seguro de que quieres eliminar este documento?",
  "Add tag for self-training": "Agregar etiqueta para autoentrenamiento",
  "Tag name not found in document":
    "Nombre de etiqueta no encontrado en el documento",
  "Note: Tag name should be unique like vendor id, vendor name, customer id, customer name and mentioned in document’s content.":
    "Nota: El nombre de la etiqueta debe ser único como el id del proveedor, el nombre del proveedor, el id del cliente, el nombre del cliente y mencionado en el contenido del documento.",
  "Tag name": "Nombre de la etiqueta",
  "Enter tag name": "Introducir nombre de la etiqueta",
  "There is no tag attached with this document, if you wish to make this part of self-training please enter tag name.":
    "No hay ninguna etiqueta adjunta a este documento, si deseas hacerlo parte del autoentrenamiento, por favor introduce el nombre de la etiqueta.",
  "Tag name is required": "Se requiere el nombre de la etiqueta",
  "Ignore & save": "Ignorar y guardar",
  documentName: `Nombre del documento`,
  documentId: `ID del documento`,
  uploadedBy: `Subido por`,
  "Original document already integrated.": `El documento original ya está integrado.`,
  "Document has been integrated.": `El documento ha sido integrado.`,
  "You have unsaved changes. Do you want to save them?": `Tienes cambios sin guardar. ¿Quieres guardarlos?`,
  doc_configuration_unsaved_changes_warning:
    "Es posible que los cambios que hayas realizado no se guarden. ¿Estás seguro de que deseas salir de esta página?",
  "Document has been updated/changed": `El documento ha sido actualizado/cambiado`,
  "Document data has been updated/changed": `Los datos del documento han sido actualizados/cambiados`,
  "Some fields need to be reviewed. Show info for details.": `Algunos campos necesitan ser revisados. Mostrar información para más detalles.`,
  "Atleast one polygon should be updated": `Al menos un polígono debe ser actualizado`,
  "To enable self-training, atleast one polygon should be updated.": `Para habilitar el autoentrenamiento, al menos un polígono debe ser actualizado.`,
  "Note: Removing tag from this document will remove it from other documents as well.": `Nota: Eliminar la etiqueta de este documento la eliminará de otros documentos también.`,
  "Are you sure you want to remove this tag?": `¿Estás seguro de que quieres eliminar esta etiqueta?`,
  "Remove tag": `Eliminar etiqueta`,
  "Failed to remove tag": `Error al eliminar la etiqueta`,
  "Tag not found": `Etiqueta no encontrada`,
  AccountReceivable: `Cuenta por Cobrar`,
  AccountPayable: `Cuenta por Pagar`,
  account_class_field_missing: `Falta el campo de clase de cuenta`,
  registrationError: `El ID de registro es obligatorio`,
  registrationSpecialChrError: `El ID de registro no debe contener caracteres especiales`,
  registrationSpaceError: `El ID de registro no debe contener espacios`,
  "Signing certificate overview": `Resumen del certificado de firma`,
  "Is valid": `Es válido`,
  Issuer: `Emisor`,
  Expiry: `Vencimiento`,
  january: `enero`,
  february: `febrero`,
  march: `marzo`,
  april: `abril`,
  may: `mayo`,
  june: `junio`,
  july: `julio`,
  august: `agosto`,
  september: `septiembre`,
  october: `octubre`,
  november: `noviembre`,
  december: `diciembre`,
  "Accounting period": `Período contable`,
  "This period is used for accounting purposes.": `Este período se utiliza con fines contables.`,
  "Open date": `Fecha de apertura`,
  "Close period": `Cerrar período`,
  "Close date": `Fecha de cierre`,
  "Are you sure you want to close the accounting period? Once the period is closed, all documents for that period will be non-editable.": `¿Estás seguro de que quieres cerrar el período contable? Una vez cerrado el período, todos los documentos para ese período no serán editables.`,
  "Close accounting period": `Cerrar período contable`,
  "View period": `Ver período`,
  "Failed to close period": `Error al cerrar el período`,
  "Period closed successfully": `Período cerrado con éxito`,
  review_accountingperiod_closed:
    "El período contable se ha cerrado con éxito. Todos los documentos para este período no serán editables.",
  "Document can not be updated because the accounting period is closed.": `El documento no puede ser actualizado porque el período contable está cerrado.`,
  "Include deleted documents": `Incluir documentos eliminados`,
  validation_accountclass_required: `Se requiere la clase de cuenta`,
  "Document has been deleted.": `El documento ha sido eliminado.`,
  Deleted: `Eliminado`,
  InvoiceDNMatching_DeliveryNoteNotFound: "Nota de entrega no encontrada.",
  InvoiceDNMatching_ItemCountNotMatched:
    "La cantidad de artículos no coincide.",
  InvoiceDNMatching_DeliveryNoteItemsNotFound:
    "Artículos de la nota de entrega no encontrados.",
  InvoiceDNMatching_InvoiceItemDescriptionAndCodeNotAttached:
    "Descripción y código del artículo de la factura no adjuntos.",
  InvoiceDNMatching_InvoiceItemNotFoundInDN:
    "Artículo de la factura no encontrado en la nota de entrega.",
  InvoiceDNMatching_ItemQuantityGreaterThan:
    "La cantidad de artículos es mayor de lo esperado.",
  InvoiceDNMatching_ItemQuantityLessThan:
    "La cantidad de artículos es menor de lo esperado.",
  InvoiceDNMatching_PaymentTerms_DueDate:
    "Desajuste en los términos de pago o fecha de vencimiento.",
  InvoicePOMatching_AmountNotMatch:
    "El monto no coincide con la orden de compra.",
  PODNMatching_PONumberNotFound: "Número de orden de compra no encontrado.",
  PODNMatching_PONotFound: "Orden de compra no encontrada.",
  PODNMatching_ItemCountNotMatched:
    "La cantidad de artículos no coincide con la orden de compra.",
  PODNMatching_POItemsNotFound:
    "Artículos de la orden de compra no encontrados.",
  PODNMatching_DNItemDescriptionAndRefrenceCodeNotFound:
    "Descripción y código de referencia del artículo de la nota de entrega no encontrados.",
  PODNMatching_DelveryNoteItemNotFoundInPO:
    "Artículo de la nota de entrega no encontrado en la orden de compra.",
  PODNMatching_DeliveryNoteItemQuantityZero:
    "La cantidad del artículo en la nota de entrega es cero.",
  PODNMatching_DeliveryNoteItemQuantityGreaterthan:
    "La cantidad del artículo en la nota de entrega es mayor de lo esperado.",
  PODNMatching_DeliveryNoteItemQuantityLessThan:
    "La cantidad del artículo en la nota de entrega es menor de lo esperado.",
  "Discrepancy configuration": `Configuración de discrepancias`,
  Discrepancy: `Discrepancia`,
  "Invoice DN Matching": `Factura <> Nota de entrega`,
  "Invoice PO Matching": `Factura <> Orden de compra`,
  "PO DN Matching": `Orden de compra <> Nota de entrega`,
  "Custom Discrepancies": `Discrepancias personalizadas`,
  CidalPOItemPriceMatchingStrategy:
    "Coincidir el precio del artículo de la orden de compra con el precio del artículo del catálogo.",
  IASOInvoiceDNStrategy:
    "Coincidir el monto total de la factura con el monto total de la nota de entrega.",
  IASOInvoicePOStrategy:
    "Coincidir el monto total de la factura con el monto total de la orden de compra.",
  InvoiceSimultedInvoiceMatching:
    "Coincidir el monto de la factura y el consumo con la factura simulada.",
  previous: `Anterior`,
  "Upload with QR code": `Subir con código QR`,
  "Document Upload via QR": `Subir documento vía QR`,
  "Scan the QR code to instantly upload your documents with the Dost App": `Escanee el código QR para subir instantáneamente sus documentos con la aplicación Dost.`,
  "Download App (Android)": `Descargar la aplicación (Android)`,
  Customers: `Clientes`,
  "Customers management": `Gestión de clientes`,
  "Add a new customer": `Añadir un nuevo cliente`,
  "You don't have any customers yet": `Aún no tienes ningún cliente`,
  "Add Customer": `Añadir Cliente`,
  customer_screen: {
    updated: "Cliente actualizado con éxito",
    notCreated: "No se pudo crear el cliente",
    created: "Cliente creado con éxito",
    customerUrl: "URL del cliente",
    edit_customer: "Editar cliente",
    delete_customer: "Eliminar cliente",
    delete_customer_dialog: `¿Eliminar cliente`,
    deleted: "Cliente eliminado con éxito",
  },
  "Customer information": `Información del cliente`,
  customers: `clientes`,
  "Insert new row": `Insertar nueva fila`,
  "Enter row number": `Introducir número de fila`,
  "Optional: Insert at row (1 to )": `Opcional: Insertar en la fila (1 a {{total}})`,
  Insert: `Insertar`,
  "Add Row": `Agregar fila`,
  "Remove column": `Eliminar columna`,
  "approval not required": `aprobación no requerida`,
  "Approval required": `Aprobación requerida`,
  "Company ID": `ID de la empresa`,
  TariffCode: `Código de tarifa`,
  PaymentTerms: `Condiciones de pago`,
  Accounts: "Cuentas",
  AmountPercentage: "Porcentaje de importe",
  GLAccount: "Cuenta Contable",
  CostCenter: "Centro de coste",
  RefCriteria: "Criterio de reparto",
  Amount: "Importe",
  OriginCountry: `País de origen`,
  Weight: `Peso`,
  ContactEmail: `Email del contacto`,
  DeliveryAddress: `Dirección de entrega`,
  PaymentMethod: `Método de pago`,
  TotalDiscount: `Descuento total`,
  DeliveryDate: `Fecha de entrega`,
  ShippingAddress: `Dirección de envío`,
  PaymetMethod: `Método de pago`,
  ShippingMethod: `Método de envío`,
  DocumentNumber: `Número de documento`,
  DocumentDate: `Fecha de documento`,
  DocumentId: `ID de documento`,
  UserId: `ID de usuario`,
  Environment: `Entorno`,
  Items: "Artículos",
  "I'm not sure": `No estoy seguro/a`,
  "Advertising & Marketing": `Publicidad y Marketing`,
  Agriculture: `Agricultura`,
  Airline: `Aerolínea`,
  Automotive: `Automotriz`,
  Banking: `Banca`,
  Biotech: `Biotecnología`,
  Construction: `Construcción`,
  Cosmetics: `Cosmética`,
  Distribution: `Distribución`,
  Education: `Educación`,
  Energy: `Energía`,
  Finance: `Finanzas`,
  Health: `Salud`,
  Hospitality: `Hospitalidad`,
  "IT & technology": `Tecnología de la información (TI)`,
  Manufacturing: `Manufactura`,
  Mining: `Minería`,
  "Professional services": `Servicios profesionales`,
  "Real estate": `Bienes raíces`,
  Retail: `Comercio minorista`,
  Telecommunications: `Telecomunicaciones`,
  Tourism: `Turismo`,
  Transport: `Transporte`,
  "Select Industry": `Seleccionar Industria`,
  CFO: `CFO`,
  "Financial Manager": `Gerente Financiero`,
  "Finance Director": "Director Financiero",
  CTO: "CTO",
  CIO: "CIO",
  "Technology Manager": "Gerente de Tecnología",
  "Technology Director": "Director de Tecnología",
  Accountant: "Contador",
  Freelancer: "Freelancer",
  "Phone number": "Número de teléfono",
  "Search country": "Buscar país",
  "Phone Number is required": "Se requiere el número de teléfono",
  "Invalid phone number": "Número de teléfono inválido",
  onboarding_status: "Estado de incorporación",
  "Click to copy": "Haz clic para copiar",
  "Copied !": "¡Copiado!",
  Payload: "Carga",
  payload: "Carga",
  NotStartWith: "No empieza con",
  StartWith: "Empieza con",
  "Add single / multiple IDs by pressing 'Enter' after each one.": `Añadir IDs individuales / múltiples presionando 'Enter' después de cada uno.`,
  "General supplier or supplier not providing on-site services/materials at a festival.":
    "Proveedor general o proveedor que no proporciona servicios",
  "Supplier linked to a festival for providing on-site services/materials.":
    "Proveedor vinculado a un festival para proporcionar servicios/materiales en el lugar.",
  "Supplier name": "Nombre del proveedor",
  "Company name": "Nombre de la empresa",
  Nationality: "Nacionalidad",
  "Tax identification number (NIF)": "Número de identificación fiscal (NIF)",
  "Registered address": "Dirección registrada",
  "Email address": "Dirección de correo electrónico",
  "T&C": "Términos y condiciones",
  "Other conditions": "Otras condiciones",
  "Company name is required": "El nombre de la empresa es obligatorio",
  "Type is required": "El tipo es obligatorio",
  "Nationality is required": "La nacionalidad es obligatoria",
  "Tax identification number (NIF) is required":
    "El número de identificación fiscal (NIF) es obligatorio",
  "Registered address is required": "La dirección registrada es obligatoria",
  "Terms and conditions is required":
    "Los términos y condiciones son obligatorios",
  "Other conditions is required": "Otras condiciones son obligatorias",
  "Terms and conditions": "Términos y condiciones",
  "T & C": "T & C",
  "Write your terms and conditions ...":
    "Escribe tus términos y condiciones ...",
  "Write your other conditions ...": "Escribe tus otras condiciones ...",
  "Company ID is required": `Se requiere el ID de la empresa`,
  selfTrainingReport: {
    status: {
      Pending: "Pendiente",
      Completed: "Completado",
      Failed: "Error",
    },
  },
  "Netsuite configurations": `Configuraciones de Netsuite`,
  "Last sync date": `Fecha de última sincronización`,
  "Sync Now": `Sincronizar`,
  "Entities being synced": `Entidades que se están sincronizando`,
  "Enable Vendor Sync For Supplier Portal": `Habilitar sincronización de proveedores para el portal de proveedores`,
  "Enable PO Sync For Discrepancy": `Habilitar sincronización de PO para discrepancia`,
  "Enable item creation": "Habilitar la creación de elementos",
  "Synced successfully": `Sincronizado con éxito`,
  "Updated successfully": `Actualizado con éxito`,
  "Failed to update": `Error al actualizar`,
  "Type keywords for description or value..":
    "Escribe palabras clave para la descripción o el valor...",
  "Sync request sent": "Solicitud de sincronización enviada",
  "Show more": "Mostrar más",
  "Show less": "Mostrar menos",
  Complete: "Completo",
  Incomplete: "Incompleto",
  "Business Central configurations": "Configuraciones de Business Central",
  "Impersonate supplier": "Personificar al proveedor",
  "Impersonate Url": "Personificar URL",
  expectedfield_parentfield_value_required:
    "Por favor selecciona {{parentField}} para rellenar {{field}}",
  connector_not_connected: "Conector no conectado",
  connector_being_synced: "Conector sincronizándose",
  connector_being_sycned: "Conector sincronizándose",
  "There were some issues encountered during the synchronization process":
    "Se encontraron algunos problemas durante el proceso de sincronización.",
  invoice_payment: "Pagos de facturas",
  bank_transactions: "Transacciones bancarias",
};

export default es;
