import { ElementType, Suspense, useEffect } from "react";
import { Navigate, Outlet, useLocation, useRoutes } from "react-router-dom";
// hooks
import useAuth from "../hooks/useAuth";
import AuthDashboardLayout from "../layouts/authdashboard";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config";
// components
import { GetAsync } from "src/common/httpRequests";
import { API_GATEWAY } from "src/constants/settings";
import PreRegister from "src/pages/auth/PreRegister";
import Impersonate from "src/pages/Impersonate";
import RedirectionPage from "src/pages/integration/Component/Redirection";
import ApprovedDocuments from "src/pages/workflows/approvedDocuments";
import Workflows from "src/pages/workflows/Workflow";
import { planUpdate } from "src/redux/slices/organization";
import { store } from "src/redux/store";
import LoadingScreen from "../components/LoadingScreen";
import lazyWithRetry from "./../lazyWithRetry";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard =
    isAuthenticated &&
    ["/dashboard", "/configuration", "/catalog", "/payments"].some((path) =>
      pathname.includes(path)
    );

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const location = useLocation();
  const email = store.getState()?.credential?.userTokenDecode.email;

  const fetchProfile = async () => {
    const res = await GetAsync(`${API_GATEWAY}/api/userservice/Profile`);
    try {
      if (res.status == 200) {
        store.dispatch(planUpdate(res.data.organizationSubscribedPlan));
      }
    } catch (e) {}
  };

  const MixpanelWrapper = ({ children }) => {
    useEffect(() => {
      window.Intercom("update");
      // if (location.pathname !== "/auth/external-identity") {
      //   MixpanelFunction({
      //     eventName: "Page Viewed",
      //     url: location.pathname,
      //   });
      // }

      if (
        location.pathname !== "/auth/external-identity" &&
        location.pathname !== "/auth/login" &&
        email
      ) {
        fetchProfile();
      }
    }, []);

    return children;
  };

  return useRoutes([
    {
      path: "auth",
      element: (
        <MixpanelWrapper>
          <GuestGuard>
            <AuthDashboardLayout />
          </GuestGuard>
        </MixpanelWrapper>
      ),
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "new-register",
          element: (
            <GuestGuard>
              <NewRegister />
            </GuestGuard>
          ),
        },
        {
          path: "login-unprotected",
          element: <Login />,
        },
        {
          path: "register-unprotected",
          element: <Register />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "new-password",
          element: <NewPassword />,
        },
        // {path: 'verify', element: <VerifyCode /> },
        {
          path: "pre-register",
          element: <PreRegister />,
        },
        {
          path: "pre-verify",
          element: <PreVerify />,
        },
        {
          path: "external-identity",
          element: <ExternalIdentity />,
        },
        {
          path: "password",
          children: [
            {
              path: "reset",
              element: <ResetPasswordV2 />,
            },
          ],
        },
      ],
    },

    {
      path: "impersonate",
      element: (
        <MixpanelWrapper>
          <Impersonate />
        </MixpanelWrapper>
      ),
    },
    {
      path: "connector",
      element: (
        <MixpanelWrapper>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </MixpanelWrapper>
      ),
      children: [
        {
          path: "oAuthRedirect",
          element: <RedirectionPage />,
        },
      ],
    },

    {
      path: "dashboard",
      element: (
        <MixpanelWrapper>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </MixpanelWrapper>
      ),
      children: [
        { element: <Components />, path: "components" },
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "app",
          element: <GeneralApp />,
        },
        {
          path: "documents",
          element: (
            <>
              <Outlet />
            </>
          ),
          children: [
            {
              path: "",
              element: <Finance />,
            },
            {
              path: "review",
              element: <FormValidation />,
            },
            { path: "mailinbox", element: <MailInbox /> },
            { path: "createdocument", element: <CreateDocument /> },
            { path: "createpayment", element: <CreatePayment /> },
          ],
        },
        { path: "selftrainingreports", element: <SelfTrainingReports /> },
        {
          path: "developers-settings",
          element: <DevelopersSettings />,
        },

        { path: "integration", element: <IntegrationV2 /> },
        {
          path: "comingSoon",
        },
        {
          path: "workflow",
          element: <Workflows />,
        },
        {
          path: "approved-documents",
          element: <ApprovedDocuments />,
        },
        {
          path: "suppliers",
          element: <Suppliers />,
        },
        {
          path: "customers",
          element: <Customers />,
        },
        {
          path: "usersV2",
          element: <Users />,
        },
        {
          path: "upgrade",
          element: <Upgrade />,
        },
        {
          path: "feedback",
          element: <FeedBack />,
        },
        {
          path: "notifications",
          element: <Notification />,
        },
        {
          path: "stripe",
          element: <Stripe />,
        },
        {
          path: "paymentSuccess",
          element: <PaymentSuccess />,
        },

        { path: "analytics", element: <Analytics /> },
        {
          path: "error",
          children: [{ path: "*", element: <Navigate to="/404" replace /> }],
        },
        {
          path: "user",
          // element: <LogoOnlyLayout />,
          children: [
            {
              path: "verification",
              element: <AccountVerification />,
            },
            {
              path: "account",
              element: <MyAccount />,
            },
          ],
        },
      ],
    },
    {
      path: "catalog",
      element: (
        <MixpanelWrapper>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </MixpanelWrapper>
      ),
      children: [{ element: <CatalogItems />, path: "items" }],
    },
    {
      path: "payments",
      element: (
        <MixpanelWrapper>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </MixpanelWrapper>
      ),
      children: [
        { path: "invoice-payments", element: <Payments /> },
        { path: "invoice-transactions", element: <Transactions /> },
      ],
    },
    {
      path: "configuration",
      element: (
        <MixpanelWrapper>
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        </MixpanelWrapper>
      ),
      children: [
        { element: <DocumentsConfiguration />, path: "document-configuration" },
        {
          element: <DocumentsConfigurationV2 />,
          path: "document-configuration-v2",
        },
        { element: <InputCSVMapping />, path: "input-csv-mapping" },
        { element: <InputCSVMappingV2 />, path: "input-csv-mapping-v2" },
        { element: <OutputCSVMapping />, path: "output-csv-mapping" },
        {
          element: <ExpectedValuesConfiguration />,
          path: "predefined-values-configuration",
        },
        { element: <ERPSyncConfiguration />, path: "erp-sync-configuration" },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: (
        <MixpanelWrapper>
          <LogoOnlyLayout />
        </MixpanelWrapper>
      ),
      children: [{ path: "*", element: <Navigate to="/404" replace /> }],
    },
    {
      path: "404",
      element: (
        <MixpanelWrapper>
          <AuthDashboardLayout />
        </MixpanelWrapper>
      ),
      children: [{ path: "", element: <Page404 /> }],
    },
    {
      path: "403",
      element: (
        <MixpanelWrapper>
          <AuthDashboardLayout />
        </MixpanelWrapper>
      ),
      children: [{ path: "", element: <Page403 /> }],
    },
    {
      path: "user",
      // element: <LogoOnlyLayout />,
      children: [
        {
          path: "verification",
          element: <AccountVerification />,
        },
        {
          path: "account",
          element: <MyAccount />,
        },
      ],
    },
    {
      path: "/",
      element: (
        <MixpanelWrapper>
          <GuestGuard>
            <AuthDashboardLayout />
          </GuestGuard>
        </MixpanelWrapper>
      ),
      children: [{ path: "/", element: <Navigate to="auth/login" replace /> }],
    },
    { path: "microsoftLogin" },
    {
      path: "showOneDrive",
      element: (
        <MixpanelWrapper>
          <ShowOneDrive />
        </MixpanelWrapper>
      ),
    },
    {
      path: "gdrive",
      element: (
        <MixpanelWrapper>
          <GDrive />
        </MixpanelWrapper>
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazyWithRetry(() => import("../pages/auth/Login")));
const Register = Loadable(
  lazyWithRetry(() => import("../pages/auth/Register"))
);
const NewRegister = Loadable(
  lazyWithRetry(() => import("../pages/auth/NewRegister"))
);

const ResetPassword = Loadable(
  lazyWithRetry(() => import("../pages/auth/ResetPassword"))
);
const ResetPasswordV2 = Loadable(
  lazyWithRetry(() => import("../pages/auth/ResetPasswordV2"))
);
const NewPassword = Loadable(
  lazyWithRetry(() => import("../pages/auth/NewPassword"))
);
// const VerifyCode = Loadable(lazyWithRetry(() => import("../pages/auth/VerifyCode")));
const PreVerify = Loadable(
  lazyWithRetry(() => import("src/pages/auth/PreVerify"))
);
const ExternalIdentity = Loadable(
  lazyWithRetry(() => import("src/pages/auth/ExternalIdentity"))
);
const IntegrationV2 = Loadable(
  lazyWithRetry(() => import("src/pages/integration/index"))
);
const DocumentsConfiguration = Loadable(
  lazyWithRetry(() => import("src/pages/configuration/DocumentConfiguration"))
);
const DocumentsConfigurationV2 = Loadable(
  lazyWithRetry(() => import("src/pages/configuration/DocumentConfigurationV2"))
);
const InputCSVMapping = Loadable(
  lazyWithRetry(() => import("src/pages/configuration/MappingConfiguration"))
);
const InputCSVMappingV2 = Loadable(
  lazyWithRetry(() => import("src/pages/configuration/MappingConfigurationV2"))
);

const OutputCSVMapping = Loadable(
  lazyWithRetry(() => import("src/pages/configuration/OutputCSVMapping/index"))
);
const ExpectedValuesConfiguration = Loadable(
  lazyWithRetry(
    () =>
      import(
        "src/pages/configuration/DocumentConfigurationV2/ExpectedValuesConfiguration/ExpectedValuesConfiguration"
      )
  )
);
const ERPSyncConfiguration = Loadable(
  lazyWithRetry(() => import("src/pages/configuration/ERPSyncConfiguration"))
);

const CatalogItems = Loadable(
  lazyWithRetry(() => import("src/pages/Catalog/Items"))
);

const GeneralApp = Loadable(
  lazyWithRetry(() => import("src/pages/DashboardV2/index"))
);
const Suppliers = Loadable(
  lazyWithRetry(() => import("src/pages/Suppliers/index"))
);
const Customers = Loadable(
  lazyWithRetry(() => import("src/pages/Customers/index"))
);
const Users = Loadable(lazyWithRetry(() => import("src/pages/Users/Users")));

const ShowOneDrive = Loadable(
  lazyWithRetry(() => import("src/pages/showOneDrive/index"))
);
const GDrive = Loadable(
  lazyWithRetry(() => import("src/pages/showGDrive/index"))
);
const Upgrade = Loadable(
  lazyWithRetry(() => import("src/pages/upgrade/index"))
);
const Notification = Loadable(
  lazyWithRetry(() => import("src/pages/Notification/Notifications"))
);

const Stripe = Loadable(
  lazyWithRetry(() => import("src/pages/PaymentMethod/stripe/index"))
);
const PaymentSuccess = Loadable(
  lazyWithRetry(() => import("src/pages/paymentSuccess/index"))
);

const Finance = Loadable(
  lazyWithRetry(() => import("src/pages/Documents/Finance/table/table"))
);

const Payments = Loadable(lazyWithRetry(() => import("src/pages/Payments")));

const Transactions = Loadable(
  lazyWithRetry(() => import("src/pages/Payments/Transactions/index"))
);

const Analytics = Loadable(lazyWithRetry(() => import("src/pages/Analytics")));

//Feedback
const FeedBack = Loadable(
  lazyWithRetry(() => import("src/pages/feedback/index"))
);

const FormValidation = Loadable(
  lazyWithRetry(() => import("src/pages/formvalidation"))
);
const MailInbox = Loadable(
  lazyWithRetry(() => import("src/pages/Documents/Finance/MailInbox"))
);
const CreateDocument = Loadable(
  lazyWithRetry(() => import("src/pages/Documents/Finance/CreateDocument"))
);
const CreatePayment = Loadable(
  lazyWithRetry(() => import("src/pages/Documents/Finance/CreatePayment"))
);

const SelfTrainingReports = Loadable(
  lazyWithRetry(() => import("src/pages/SelfTrainingReports/index"))
);

const DevelopersSettings = Loadable(
  lazyWithRetry(() => import("src/pages/developersSection/index"))
);
const AccountVerification = Loadable(
  lazyWithRetry(
    () => import("../pages/AccountVerification/AccountVerification")
  )
);

const Page404 = Loadable(lazyWithRetry(() => import("src/pages/Page404")));
const Page403 = Loadable(lazyWithRetry(() => import("src/pages/Page403")));

const MyAccount = Loadable(
  lazyWithRetry(() => import("src/pages/userAccount"))
);

const Components = Loadable(lazyWithRetry(() => import("../pages/Components")));
