import { Box, Divider, Tooltip, Typography, useTheme } from "@mui/material";
import React, { ReactElement, useState } from "react";
import { PaymentDetail } from "src/@types/processedDocuments";
import ClockIcon from "src/assets/icon/clock";
import CrossIcon from "src/assets/icon/cross";
import { DocumentIcon } from "src/assets/icon/Document";
import TickIcon from "src/assets/icon/tick";
import UserIdIcon from "src/assets/icon/UserIdIcon";
import useLocales from "src/hooks/useLocales";
import { fDateTime } from "src/utils/formatTime";

interface PaymentStatusDetailsProps {
  paymentDetail: PaymentDetail | null;
  children: ReactElement;
}

const Row = ({ icon, title, value }) => {
  // convert to table row
  return (
    <tr
      style={{
        alignItems: "top",
        gap: "5px",
        alignContent: "top",
        verticalAlign: "top",
      }}
    >
      <td
        style={{
          alignItems: "top",
        }}
      >
        {icon}
      </td>
      <td>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "12px",
            px: "5px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {title}
        </Typography>
      </td>
      <td>
        <Typography sx={{ fontSize: "12px", wordBreak: "break-word" }}>
          {value}
        </Typography>
      </td>
    </tr>
  );
};

const PaymentStatusDetails: React.FC<PaymentStatusDetailsProps> = (props) => {
  const { paymentDetail, children } = props;
  const { translate } = useLocales();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  // const [isShowTransactionDetails, setIsShowTransactionDetails] =
  //   useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const handleShowDetails = () => {
  //   setIsShowTransactionDetails(!isShowTransactionDetails);
  // };

  return (
    <Box>
      {/* {isShowTransactionDetails && (
        <TransactionDetailsDrawer
          open={isShowTransactionDetails}
          toggleDrawer={handleShowDetails}
          transactedBy={paymentDetail?.createdBy!}
          transactedOn={paymentDetail?.createdOn!}
          transactionId={paymentDetail?.transactionId!}
          id={paymentDetail?.id}
        />
      )} */}
      <Tooltip
        title={
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box p={0.5}>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                  variant="body2"
                >
                  {translate("payment_details")}
                </Typography>
              </Box>
              {/* <Box>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleShowDetails();
                    handleClose();
                  }}
                >
                  <OpenIcon height={18} width={18} />
                </IconButton>
              </Box> */}
            </Box>
            <Divider />
            <Box
              sx={{
                fontWeight: 400,
                py: 1,
              }}
            >
              <table>
                <tbody>
                  <Row
                    icon={<DocumentIcon height={18} width={18} />}
                    title={translate("transaction_id")}
                    value={paymentDetail?.transactionId}
                  />
                  <Row
                    icon={
                      paymentDetail?.transactionStatus === "Fail" ? (
                        <CrossIcon height={18} width={18} />
                      ) : (
                        <TickIcon height={18} width={18} />
                      )
                    }
                    title={translate("approval_screen.status")}
                    value={translate(paymentDetail?.transactionStatus)}
                  />
                  {paymentDetail?.reason && (
                    <Row
                      icon={<TickIcon height={18} width={18} />}
                      title={translate("reason")}
                      value={translate(paymentDetail?.reason)}
                    />
                  )}
                  <Row
                    icon={<UserIdIcon />}
                    title={translate("created_by")}
                    value={paymentDetail?.createdBy}
                  />
                  <Row
                    icon={
                      <ClockIcon
                        color={theme.palette.text.greyLight}
                        height={18}
                        width={18}
                      />
                    }
                    title={translate("created_on")}
                    value={paymentDetail && fDateTime(paymentDetail.createdOn)}
                  />
                </tbody>
              </table>
            </Box>
          </>
        }
        arrow
        placement="top"
        open={open && !!paymentDetail}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        {children}
      </Tooltip>
    </Box>
  );
};

export default PaymentStatusDetails;
