const IntegrationIllustration = ({ width = "160", height = "160" }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M133.432 51.7272L105.762 38.2985L66.2326 19.1145L49.9417 11.2012C45.8701 9.20449 41.3439 8.31915 36.8208 8.63463C32.2977 8.95012 27.938 10.4553 24.1823 12.9979C20.4267 15.5406 17.4082 19.0306 15.4321 23.1152C13.4559 27.1998 12.5921 31.7343 12.9279 36.2602L16.4017 84.9393L19.6359 128.583C19.9981 133.105 21.5353 137.454 24.095 141.198C26.6547 144.942 30.148 147.951 34.2282 149.926C38.3084 151.902 42.8338 152.775 47.3552 152.459C51.8767 152.143 56.237 150.65 60.0037 148.126L102.887 119.95C103.863 119.133 104.783 118.252 105.642 117.312L136.667 96.4497C140.421 93.8975 143.44 90.4014 145.418 86.3126C147.396 82.2237 148.265 77.6859 147.937 73.1547C147.61 68.6234 146.097 64.2582 143.551 60.4971C141.005 56.7361 137.515 53.7115 133.432 51.7272Z"
          fill="#EEF5FD"
        />
        <rect
          x="24.7856"
          y="42.3203"
          width="113.286"
          height="73.1313"
          fill="white"
          stroke="#0B427A"
          strokeDasharray="3 3"
        />
        <path
          d="M84.8232 18.3173L84.8232 29.9063L81.4715 29.9063L81.4715 18.3173C81.4715 17.9341 81.6854 17.551 82.042 17.551L84.2527 17.551C84.538 17.551 84.8232 17.8384 84.8232 18.3173Z"
          fill="white"
        />
        <path
          d="M97.9451 18.3173L97.9451 29.9062L94.5933 29.9063L94.5933 18.3173C94.5933 17.9341 94.8072 17.551 95.1638 17.551L97.3746 17.551C97.6598 17.551 97.9451 17.8384 97.9451 18.3173Z"
          fill="white"
        />
        <path
          d="M84.8232 18.3173L84.8232 29.9063L81.4715 29.9063L81.4715 18.3173C81.4715 17.9341 81.6854 17.551 82.042 17.551L84.2527 17.551C84.538 17.551 84.8232 17.8384 84.8232 18.3173Z"
          stroke="#0B427A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.9451 18.3173L97.9451 29.9062L94.5933 29.9063L94.5933 18.3173C94.5933 17.9341 94.8072 17.551 95.1638 17.551L97.3746 17.551C97.6598 17.551 97.9451 17.8384 97.9451 18.3173Z"
          stroke="#0B427A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.7888 71.1421L89.7888 83.8331C89.7888 90.6989 84.1713 96.3164 77.3055 96.3164C70.4398 96.3164 64.8222 90.6989 64.8222 83.8331L64.8222 66.2526C64.8222 60.6351 60.245 56.162 54.7316 56.162"
          stroke="#0B427A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M114.348 33.3024L112.074 47.3582C110.483 56.9924 102.982 64.3603 93.3207 65.9473L86.0463 65.9473C76.3844 64.4746 68.8828 56.9931 67.2914 47.3582L65.0182 33.3024C64.9045 32.1688 65.2455 31.0353 66.0412 30.2419C66.7231 29.335 67.746 28.8816 68.8827 28.8816L110.71 28.8816C111.733 28.8816 112.87 29.4484 113.552 30.2419C114.234 31.1487 114.575 32.1689 114.348 33.3024Z"
          fill="#E4E6EB"
          stroke="#0B427A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M93.8247 66.2157L93.8247 70.9844L85.5879 70.9844L85.5879 66.2157L93.8247 66.2157Z"
          stroke="#0B427A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.4724 42.6602L81.4724 53.4681"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.9419 42.082L97.9419 52.89"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.7161 40.8574L89.7161 55.268"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="98.3438"
          y="75.7246"
          width="29.888"
          height="29.888"
          rx="14.944"
          fill="#EEF5FD"
        />
        <path
          d="M113.288 85.9121V95.4259"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M118.045 90.6699H108.531"
          stroke="#0B427A"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="98.3438"
          y="75.7246"
          width="29.888"
          height="29.888"
          rx="14.944"
          stroke="#0B427A"
        />
      </svg>
    </>
  );
};

export default IntegrationIllustration;
