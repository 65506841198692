import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { PaymentsInfo } from "src/@types/payments";
import CloseModalButton from "src/assets/icon/close/CloseModalButton";
import IntegrationIllustration from "src/assets/illustrations/IntegrationIllustration";
import { PostAsync } from "src/common/httpRequests";
import { EmptyDataSvg } from "src/components/EmptyContent";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import { PaymentsState } from "..";
import PaymentInfoSkeleton from "../PaymentInfoSkeleton";
import PaymentDrawerInfo from "./PaymentDrawerInfo";

interface PaymentsDrawerProps {
  openDrawer: boolean;
  selectedRowIds: string[];
  handleDrawerClose: () => void;
}

const PaymentsDrawer: React.FC<PaymentsDrawerProps> = (props) => {
  const { openDrawer, selectedRowIds, handleDrawerClose } = props;
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentsState, setPaymentsState] = useState<PaymentsState>({
    loading: false,
    paymentsInfo: {} as PaymentsInfo,
    errorMsg: "",
  });

  const getPaymentDetails = async () => {
    setPaymentsState({ ...paymentsState, loading: true });
    await PostAsync(`${API_GATEWAY}/api/paymentservice/payments/info`, {
      documentIds: selectedRowIds,
    })
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          setPaymentsState({
            errorMsg: "",
            paymentsInfo: res?.data,
            loading: false,
          });
        } else {
          setPaymentsState({
            paymentsInfo: null,
            loading: false,
            errorMsg: res?.data?.Detail,
          });
        }
      })
      .catch((err) => {
        console.log("Error-->", err);
        setPaymentsState({
          errorMsg: err.error,
          paymentsInfo: null,
          loading: false,
        });
        enqueueSnackbar(translate(`something_went_wrong`), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (selectedRowIds.length > 0) getPaymentDetails();
  }, [selectedRowIds]);

  const { errorMsg, loading, paymentsInfo } = paymentsState;

  return (
    <Drawer
      open={openDrawer}
      variant="persistent"
      onClose={handleDrawerClose}
      hideBackdrop={true}
      PaperProps={{
        sx: {
          width: {
            xs: "100%",
            sm: 500,
            md: 500,
            lg: 500,
            xl: 500,
          },
          borderTopLeftRadius: "16px",
          borderBottomLeftRadius: "16px",
          bgcolor: "background.paper",
          transition: (theme) =>
            theme.transitions.create("width", {
              duration: theme.transitions.duration.standard,
            }),
          boxShadow: (theme) => theme.customShadows.z24,
        },
      }}
      anchor="right"
    >
      <Box position="relative" sx={{ m: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography id="modal-modal-title" variant="h6">
            {translate(`make_payment`)}
          </Typography>
          <IconButton size="small" onClick={handleDrawerClose}>
            <CloseModalButton />
          </IconButton>
        </Box>

        {loading ? (
          <PaymentInfoSkeleton />
        ) : errorMsg ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ mt: 2 }}>
              {errorMsg === "payment_gateway_not_connected" ? (
                <IntegrationIllustration width={"120"} height={"120"} />
              ) : (
                <EmptyDataSvg width={"120"} height={"120"} />
              )}
            </Box>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {errorMsg.includes(".,") ? (
                <ol>
                  {errorMsg.split(".,").map((err, index) => (
                    <li key={index}>{err}</li>
                  ))}
                </ol>
              ) : (
                translate(errorMsg)
              )}
            </Typography>
          </Box>
        ) : paymentsInfo && Object.keys(paymentsInfo).length > 0 ? (
          <PaymentDrawerInfo
            paymentsInfo={paymentsInfo}
            documentIds={selectedRowIds}
            handleDrawerClose={handleDrawerClose}
          />
        ) : (
          <></>
        )}
      </Box>
    </Drawer>
  );
};

export default PaymentsDrawer;
