import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Stack,
  useTheme,
} from "@mui/material";
import React from "react";

const PaymentInfoSkeleton = () => {
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Skeleton variant="text" height={40} width={160} />
        <Box sx={{ mt: 2 }}>
          <Skeleton variant="text" height={20} width={120} />
          <Skeleton variant="text" height={20} width={100} />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Skeleton variant="text" height={20} width={130} />
          <Skeleton variant="text" height={20} width={100} />
        </Box>

        <Card sx={{ my: 2 }}>
          <CardActionArea>
            <CardHeader
              avatar={<Skeleton variant="circular" width="40" height="40" />}
              title={<Skeleton variant="text" width={100} height={40} />}
            />
            <CardContent>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Skeleton variant="text" width={40} height={20} />
                    <Skeleton variant="text" width={30} height={20} />
                  </Stack>
                  <Skeleton variant="text" height={30} width={50} />
                </Box>
                <Skeleton variant="text" height={30} width={50} />
              </Stack>
            </CardContent>
          </CardActionArea>
        </Card>
        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          sx={{ mx: 3, mt: 2 }}
        >
          <Skeleton variant="text" width={70} height={30} />
          <Skeleton variant="text" width={40} height={30} />
        </Stack>
      </Grid>
      <Grid item xs={7}>
        <Skeleton variant="text" height={40} width={500} />
        <Card
          sx={{
            maxWidth: 400,
            border: `1px solid ${theme.palette.action.disabledBackground}`,
            m: 2,
          }}
        >
          <CardActionArea sx={{ pb: 2 }}>
            <CardHeader
              avatar={
                <Box
                  sx={{
                    border: "1px solid #F4F6F8",
                    borderRadius: "8px",
                    p: 1,
                    height: "40px",
                    width: "40px",
                    mb: 3,
                  }}
                ></Box>
              }
              title={<Skeleton variant="text" width={90} height={30} />}
              subheader={<Skeleton variant="text" width={60} height={30} />}
            />
          </CardActionArea>
        </Card>
        <Card
          sx={{
            maxWidth: 400,
            border: `1px solid ${theme.palette.action.disabledBackground}`,
            m: 2,
          }}
        >
          <CardActionArea sx={{ pb: 2 }}>
            <CardHeader
              avatar={
                <Box
                  sx={{
                    border: "1px solid #F4F6F8",
                    borderRadius: "8px",
                    p: 1,
                    height: "40px",
                    width: "40px",
                    mb: 3,
                  }}
                ></Box>
              }
              title={<Skeleton variant="text" width={90} height={30} />}
              subheader={<Skeleton variant="text" width={60} height={30} />}
            />
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentInfoSkeleton;
