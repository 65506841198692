export const global = {
  complete: `Complete`,
  address: `Address`,
  email: `Email`,
  accepted: `Accepted`,
  not_accepted: `Not accepted`,
  individual_information: `Individual information`,
  full_name: `Full name`,
  nationality: `Nationality`,
  document: `Document`,
  terms_and_conditions: `Terms and Conditions`,
  updated_by: `Updated by`,
  updated_on: `Updated on`,
  last_updated: `Last updated`,
  last_updated_by: `Last updated by {{updatedBy}} on {{lastUpdated}}`,
  last_updated_on: `Last updated: {{lastUpdated}}`,
  connecting: `Connecting`,
  today: `Today`,
  load_more: `Load more`,
  download_csv: `Download CSV`,
  end_of_result: `End of result`,
  recipient: `Recipient`,
  note: `Note`,
  total_balance: `Total balance`,
  file_name: `File name`,
};
