import { Box, Link, Tooltip, Typography } from "@mui/material";
import { forwardRef, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { isExternalLink } from "..";
import RoleBasedGuard from "../../../guards/RoleBasedGuard";
import useLocales from "../../../hooks/useLocales";
import { NavItemProps } from "../type";
import {
  ListItemIconStyle,
  ListItemStyle,
  ListItemStyleProps,
  ListItemTextStyle,
} from "./style";
//theme
import { useTheme } from "@mui/material/styles";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import ChevronUpIcon from "src/assets/icon/chevron/up";
import NavItemPopover from "./NavItemPopover";

// ----------------------------------------------------------------------

// HANDLE SHOW ITEM BY ROLE
const ListItem = forwardRef<
  HTMLDivElement & HTMLAnchorElement,
  ListItemStyleProps
>((props, ref) => (
  <RoleBasedGuard roles={props.roles}>
    <ListItemStyle {...props} ref={ref}>
      {props.children}
    </ListItemStyle>
  </RoleBasedGuard>
));

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  onOpen,
}: NavItemProps) {
  const { translate } = useLocales();
  const { title, path, icon, info, children, disabled, caption, roles } = item;
  const theme = useTheme();
  const textColor = theme.palette?.text?.greyLight;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const renderContent = (
    <>
      {isCollapse && icon && (
        <NavItemPopover {...{ anchorEl, handlePopoverClose, title }} />
      )}
      {icon && (
        <ListItemIconStyle
          sx={{
            ...(isCollapse
              ? {
                  mr: "0px",
                }
              : {}),
          }}
        >
          {icon}
        </ListItemIconStyle>
      )}
      <ListItemTextStyle
        sx={{ textTransform: "initial", color: textColor, fontWeight: 700 }}
        disableTypography
        primary={translate(title)}
        secondary={
          <Tooltip title={translate(caption) || ""} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: "initial" }}
            >
              {translate(caption)}
            </Typography>
          </Tooltip>
        }
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItem
        onClick={onOpen}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        activeRoot={active}
        disabled={disabled}
        roles={roles}
        sx={{
          "&:hover": {
            bgcolor: "#ffffff",
          },
          "&.activeRoot": {
            bgcolor: "#ffffff",
          },
          "&.active": {
            bgcolor: "#ffffff",
          },
        }}
      >
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem
      component={Link}
      href={path}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      target="_blank"
      rel="noopener"
      disabled={disabled}
      roles={roles}
      sx={{
        "&:hover": {
          bgcolor: "#ffffff",
        },
        "&.activeRoot": {
          bgcolor: "#ffffff",
        },
        "&.active": {
          bgcolor: "#ffffff",
        },
      }}
    >
      {renderContent}
    </ListItem>
  ) : (
    <ListItem
      component={RouterLink}
      to={path}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      activeRoot={active}
      disabled={disabled}
      roles={roles}
      sx={{
        "&:hover": {
          bgcolor: "#ffffff",
        },
        "&.activeRoot": {
          bgcolor: "#ffffff",
        },
        "&.active": {
          bgcolor: "#ffffff",
        },
      }}
    >
      {renderContent}
    </ListItem>
  );
}

// ----------------------------------------------------------------------

type NavItemSubProps = Omit<NavItemProps, "isCollapse">;

export function NavItemSub({
  item,
  open = false,
  active = false,
  onOpen,
}: NavItemSubProps) {
  const { translate } = useLocales();

  const { title, path, info, children, color, disabled, caption, roles } = item;

  const renderContent = (
    <>
      <DotIcon active={active} color={color} />
      <ListItemTextStyle
        primary={translate(title)}
        disableTypography
        sx={{
          color: "text.greyLight",
          fontWeight: active ? "bold" : "normal",
          fontSize: "12px",
        }}
        secondary={
          <Tooltip title={translate(caption) || ""} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: "initial", color: "text.greyLight" }}
            >
              {translate(caption)}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItem
        onClick={onOpen}
        activeSub={active}
        subItem
        disabled={disabled}
        roles={roles}
        sx={{
          "&:hover": {
            bgcolor: "#ffffff",
          },
          "&.activeRoot": {
            bgcolor: "#ffffff",
          },
          "&.active": {
            bgcolor: "#ffffff",
          },
        }}
      >
        {renderContent}
      </ListItem>
    );
  }

  return isExternalLink(path) ? (
    <ListItem
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
      disabled={disabled}
      roles={roles}
      sx={{
        "&:hover": {
          bgcolor: "#ffffff",
        },
        "&.activeRoot": {
          bgcolor: "#ffffff",
        },
        "&.active": {
          bgcolor: "#ffffff",
        },
      }}
    >
      {renderContent}
    </ListItem>
  ) : (
    <ListItem
      component={RouterLink}
      to={path}
      activeSub={active}
      subItem
      disabled={disabled}
      roles={roles}
      sx={{
        ml: 2,
        "&:hover": {
          bgcolor: "#ffffff",
        },
        "&.activeRoot": {
          bgcolor: "#ffffff",
        },
        "&.active": {
          bgcolor: "#ffffff",
        },
      }}
    >
      {renderContent}
    </ListItem>
  );
}

export function DotIcon({
  active,
  color,
}: {
  active: boolean;
  color: string | undefined;
}) {
  return (
    <ListItemIconStyle
      sx={{
        p: 0,
      }}
    >
      <Box
        component="span"
        sx={{
          width: "5px",
          height: "5px",
          borderRadius: "50%",
          bgcolor: active ? color : "text.disabled",
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

type ArrowIconProps = {
  open: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return <>{open ? <ChevronUpIcon /> : <ChevronDown />}</>;
}
