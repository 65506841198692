import { Grid, Typography, useTheme } from "@mui/material";
import { AccountInfo } from "src/@types/payments";
import useLocales from "src/hooks/useLocales";
import { formatCurrency } from "src/utils/formatNumber";

interface AccountDetailsDrawerProps {
  accountInfo: AccountInfo;
}

const AccountDetailsDrawer: React.FC<AccountDetailsDrawerProps> = (props) => {
  const {
    accountInfo: { accountHolder, balance, bank, iban },
  } = props;
  const { translate } = useLocales();
  const theme = useTheme();
  return (
    <Grid
      container
      rowSpacing={1}
      sx={{
        p: 1,
        pl: "-5px",
        pt: "-5px",
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" color={theme.palette.text.greyLight}>
          {translate("account_holder_name")}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography variant="subtitle1">{accountHolder}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" color={theme.palette.text.greyLight}>
          {translate("iban")}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography variant="subtitle1">****{iban.slice(-4)}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" color={theme.palette.text.greyLight}>
          {translate("balance")}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography variant="subtitle1">
          €{formatCurrency(balance, "0,0.00")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccountDetailsDrawer;
