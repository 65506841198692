import { Grid, Typography, useTheme } from "@mui/material";
import useLocales from "src/hooks/useLocales";
import { formatCurrency } from "src/utils/formatNumber";

interface PaymentDetailsDrawerProps {
  totalDocumentsAmount: number;
}
const PaymentDetailsDrawer: React.FC<PaymentDetailsDrawerProps> = (props) => {
  const { totalDocumentsAmount } = props;
  const { translate } = useLocales();
  const theme = useTheme();
  return (
    <Grid
      container
      rowSpacing={1}
      sx={{
        p: 1,
        pl: "-5px",
        pt: "-5px",
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 1,
      }}
    >
      <Grid item xs={6}>
        <Typography variant="subtitle1" color={theme.palette.text.greyLight}>
          {translate("sub_total")}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography variant="subtitle1">
          €{formatCurrency(totalDocumentsAmount, "0,0.00")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" color={theme.palette.text.greyLight}>
          {translate("fee_0%")}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography variant="subtitle1">
          €{formatCurrency(0, "0,0.00")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1" color={theme.palette.text.greyLight}>
          {translate("total")}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <Typography variant="subtitle1">
          €{formatCurrency(totalDocumentsAmount, "0,0.00")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PaymentDetailsDrawer;
