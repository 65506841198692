import { ToggleButton, Tooltip } from "@mui/material";
import { BsBank } from "react-icons/bs";
import { PaymentMethodsEntity } from "src/@types/payments";
import useLocales from "src/hooks/useLocales";
interface PaymentMethodDetailsProps {
  paymentMethods: PaymentMethodsEntity[] | null | undefined;
}
const PaymentMethodDetails: React.FC<PaymentMethodDetailsProps> = (props) => {
  const { paymentMethods } = props;
  const { translate } = useLocales();
  return (
    <Tooltip title={translate("Bnak To Bank")} placement="top">
      <ToggleButton value="check" selected={true}>
        {paymentMethods && paymentMethods[0].name === "Bnak To Bank" && (
          <BsBank size={"30px"} />
        )}
      </ToggleButton>
    </Tooltip>
  );
};

export default PaymentMethodDetails;
