import { useState } from "react";

const usePaymentDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const handleDrawerOpen = (rowIds: string[]) => {
    setOpenDrawer(true);
    setSelectedRowIds(rowIds);
  };

  const handleDrawerClose = () => {
    setSelectedRowIds([]);
    setOpenDrawer(false);
  };

  return {
    openDrawer,
    selectedRowIds,
    handleDrawerOpen,
    handleDrawerClose,
  };
};

export default usePaymentDrawer;
