import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useLocales from "src/hooks/useLocales";

interface WorkflowMultiApproveRejectDialogProps {
  isShowModal: boolean;
  showModalMethod: () => void;
  documentAction: string;
  isSubmitting: boolean;
  handleMultiApproveRejectAction: () => () => Promise<void>;
  docCount: number;
}

const WorkflowMultiApproveRejectDialog: React.FC<
  WorkflowMultiApproveRejectDialogProps
> = (props) => {
  const {
    isShowModal,
    showModalMethod,
    documentAction,
    handleMultiApproveRejectAction,
    isSubmitting,
    docCount,
  } = props;
  const { translate } = useLocales();

  return (
    <Dialog
      open={isShowModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {docCount === 1
          ? documentAction === "approve"
            ? translate("approval_screen.alertApproveTitle")
            : translate("approval_screen.alertRejectTitle")
          : documentAction === "approve"
          ? translate("approval_screen.alertApproveDocsTitle")
          : translate("approval_screen.alertRejectDocsTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {docCount === 1
            ? documentAction === "approve"
              ? translate("approval_screen.alertApproveMessage")
              : translate("approval_screen.alertRejectMessage")
            : documentAction === "approve"
            ? translate("approval_screen.alertApproveDocsMessage")
            : translate("approval_screen.alertRejectDocsMessage")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={showModalMethod}
          variant="outlined"
          size="small"
          disabled={isSubmitting}
        >
          {translate("approval_screen.no")}
        </Button>
        <LoadingButton
          onClick={() => handleMultiApproveRejectAction()()}
          autoFocus
          loading={isSubmitting}
          variant="contained"
          size="small"
        >
          {translate("approval_screen.yes")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowMultiApproveRejectDialog;
