import {
  Alert,
  Box,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  useAutocomplete,
  useTheme,
} from "@mui/material";
import React, { useEffect, useImperativeHandle, useState } from "react";
import ChevronDown from "src/assets/icon/chevron/ChevronDown";
import InfoIcon from "src/assets/icon/info";
import Multiply from "src/assets/icon/multiply/Multiply";
import { ReactComponent as SearchIcon } from "src/assets/icon/search.svg";
import EmptyItemsIllustration from "src/assets/illustrations/EmptyItemsIllustrations";
import useLocales from "src/hooks/useLocales";
import MyErrorHelperText from "../denseForm/ErrorHelperText";
import NewTextField, { BootstrapInput } from "../form/TextField";
import Loader from "../loading/loader";
import Pagination from "./../pagination/Pagination";

type Option = {
  label: string;
  value: string;
};

export type Dense = "normal" | "dense" | "denser";
export type Variant = "primary" | "secondary";

export const MenuAutocomplete = React.forwardRef(
  (
    {
      InputLabelProps,
      value,
      onChange,
      onBlur,
      disabled,
      sxProps,
      denser,
      optionTitle,
      dense = "normal",
      variant = "primary",
      multiline = true,
      placeholder,
      options: externalOptions,
      totalCount: externalTotalCount,
      loading: externalLoading,
      onSearch,
      rowsPerPage = 10,
      optionErrorMsg,
      onOpen,
      enableSearch = false,
      ...props
    }: {
      options: Option[];
      InputLabelProps?: any;
      label?: string;
      disabled?: boolean;
      value: string;
      dense?: Dense;
      variant?: Variant;
      onChange: (newValue: string) => void;
      placeholder?: string;
      onBlur: () => void;
      totalCount?: number;
      loading?: boolean;
      onSearch?: (params: {
        search: string;
        page: number;
        limit: number;
      }) => void;
      onOpen?: (params: {
        search: string;
        page: number;
        limit: number;
      }) => void;
      rowsPerPage?: number;
      optionErrorMsg?: string;
    } & any,
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const handleOpenMenu = () => {
      setOpen(true);
    };
    const handleCloseMenu = () => {
      setOpen(false);
    };
    const inputRef = React.useRef<HTMLTextAreaElement>(null);
    const theme = useTheme();

    useImperativeHandle(
      ref,
      () => ({
        handleOpen() {
          handleOpenMenu();
        },
      }),
      []
    );

    useEffect(() => {
      if (open) {
        onOpen?.();
      }
    }, [open]);
    const inputProps = {
      fullWidth: true,
      // ref: ref,
      multiline: multiline,
      value: value?.label ?? "",
      disabled: disabled,
      error: props.error,
      readOnly: true,
      placeholder: placeholder,
      onBlur: onBlur,
      sx: {
        "& .MuiInputBase-input": {
          ...sxProps,
          ...(dense === "normal"
            ? {
                padding: "5px 48px 5px 10px",
                height: "25px",
              }
            : dense === "dense"
            ? {
                padding: "5px 48px 5px 10px",
                height: "20px",
                lineHeight: "16px",
              }
            : {}),
          ...(variant === "secondary"
            ? {
                border: "1px solid #528ACC",
              }
            : {}),
          ...(props.error && {
            backgroundColor: theme.palette.error.light,
            borderColor: theme.palette.error.dark,
            color: theme.palette.error.dark,
            "& svg": {
              color: theme.palette.error.dark,
            },
            "&::placeholder": {
              opacity: 0.8,
              color: theme.palette.error.dark,
            },
            "&:focus": {
              borderColor: theme.palette.error.dark,
              boxShadow: `0 0 0 0.01rem ${theme.palette.error.dark}`,
            },
          }),
        },
        "& .MuiInputBase-root": {
          p: 0,
        },
      },
      endAdornment: (
        <InputAdornment
          position="end"
          sx={{
            zIndex: 10,
            position: "absolute",
            right: "5px",
            cursor: "pointer",
          }}
        >
          <IconButton
            size="small"
            onClick={(e) => {
              onChange(e, null);
            }}
          >
            <Multiply
              height={12}
              width={12}
              customColor={
                props?.error
                  ? theme.palette.error.dark
                  : theme.palette.action.active
              }
            />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              handleOpenMenu();
            }}
          >
            <ChevronDown
              height={15}
              width={15}
              customColor={
                props?.error
                  ? theme.palette.error.dark
                  : theme.palette.action.active
              }
            />
          </IconButton>
        </InputAdornment>
      ),
    };
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          p: 0,
          gap: "4px",
        }}
        ref={inputRef}
      >
        {props.label && (
          <InputLabel
            htmlFor="demo-customized-textbox"
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              color: theme.palette.action.active,
              ...InputLabelProps,
              ...(disabled ? { color: theme.palette.action.disabled } : {}),
            }}
          >
            {props.label}
          </InputLabel>
        )}
        <BootstrapInput {...inputProps} />
        <AutocompleteDialog
          {...{
            options: externalOptions,
            onChange,
            open,
            handleCloseMenu,
            optionTitle,
            totalCount: externalTotalCount,
            loading: externalLoading,
            onSearch,
            rowsPerPage,
            optionErrorMsg,
            enableSearch,
          }}
        />
        <MyErrorHelperText error={!!props.error} helperText={props.error} />
      </Box>
    );
  }
);

export default MenuAutocomplete;

const AutocompleteDialog = ({
  options,
  onChange,
  open,
  handleCloseMenu: handleClose,
  optionTitle,
  totalCount,
  loading,
  onSearch,
  rowsPerPage,
  optionErrorMsg,
  enableSearch,
}) => {
  const { translate } = useLocales();
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = React.useState(0);
  const handleCloseMenu = () => {
    handleClose();
    setPage(0);
  };
  const onInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: string
  ) => {
    setInputValue(value);
  };

  const handleGetOptionLabel = (option: Option) => `${option.label}`;
  const handleFilterOption = (options, state) => {
    if (state.inputValue === "") {
      return options;
    }
    if (enableSearch) {
      return options;
    }
    return options?.filter(
      (option) =>
        option?.label
          ?.toLowerCase?.()
          .includes?.(state?.inputValue?.toLowerCase?.()) ||
        option?.value
          ?.toLowerCase?.()
          .includes?.(state?.inputValue?.toLowerCase?.())
    );
  };
  const handleChange = (event, value) => onChange(value);

  const { getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      options: options,
      inputValue: inputValue,
      onInputChange: onInputChange,
      getOptionLabel: handleGetOptionLabel,
      onChange: handleChange,
      filterOptions: handleFilterOption,
    });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    enableSearch &&
      onSearch &&
      onSearch({ search: inputValue, page: newPage + 1 });
  };
  const displayedOptions: Option[] = loading
    ? []
    : groupedOptions?.length > 0
    ? groupedOptions
    : inputValue == ""
    ? options
    : [];
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      // Trigger search when Enter is pressed
      setPage(0);
      onSearch?.({ search: inputValue, page: 0, limit: rowsPerPage });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleCloseMenu}
      scroll="body"
      hideBackdrop
      maxWidth={"sm"}
      fullWidth
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          p: 2,
        }}
      >
        <InputLabel
          htmlFor="demo-customized-textbox"
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            color: theme.palette.action.active,
          }}
        >
          {optionTitle}
        </InputLabel>
        <NewTextField
          {...getInputProps?.()}
          placeholder={translate("Type keywords for description or value..")}
          fullWidth
          onMouseDown={(e) => {
            // Check if the click is within the input field
            if (e.target.tagName !== "INPUT") {
              e.preventDefault(); // Prevent focus shift or undesired behavior
            }
          }}
          onKeyDown={enableSearch ? handleKeyDown : undefined}
          startAdornment={
            <Box
              sx={{
                position: "absolute",
                left: "6px",
                top: "calc(50% - 12px)",
                pointerEvents: "none",
                color: "#657482",
                zIndex: 10,
              }}
            >
              <SearchIcon
                style={{
                  stroke: theme?.palette?.action.active,
                  height: "17px",
                  width: "17px",
                }}
              />
            </Box>
          }
          endAdornment={
            enableSearch ? (
              <IconButton
                size="small"
                onClick={() => {
                  onSearch?.({
                    search: inputValue,
                    page: 0,
                    limit: rowsPerPage,
                  });
                }}
                sx={{
                  position: "absolute",
                  right: "6px",
                  // top: "calc(50% - 12px)",
                  color: "#657482",
                  zIndex: 10,
                  cursor: "pointer",
                }}
              >
                <SearchIcon
                  style={{
                    stroke: theme?.palette?.secondary?.main,
                    height: "20px",
                    width: "20px",
                  }}
                />
              </IconButton>
            ) : undefined
          }
        />
        <Box
          {...getListboxProps()}
          component={"ul"}
          sx={{
            padding: 0,
            display: "flex",
            gap: "16px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              maxHeight: "300px",
              overflowY: "auto",
              padding: "8px 0px",
              minHeight: "200px",
            }}
          >
            {(enableSearch
              ? displayedOptions
              : displayedOptions?.slice?.(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
            )?.length > 0 && (
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ml: 1,
                    }}
                  >
                    {translate("Description")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ml: 2,
                    }}
                  >
                    {translate("Value")}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(enableSearch
              ? displayedOptions
              : displayedOptions?.slice?.(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
            )?.map((option: Option, index) => (
              <Grid
                container
                component="li"
                {...getOptionProps({ option, index })}
                key={"option_" + option.value + "_" + index}
                sx={{
                  alignItems: "flex-start",
                  padding: "8px 8px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
                onClick={() => {
                  onChange(null, option);
                  handleCloseMenu();
                }}
              >
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      color: "#657482",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {option.label}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: "11px",
                      color: "#657482",
                      ml: 2,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {option.value}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            {(enableSearch
              ? displayedOptions
              : displayedOptions?.slice?.(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
            )?.length == 0 && !loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexGrow: 1,
                  alignContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 3,
                }}
              >
                <Typography variant="subtitle2">
                  {translate("No options")}
                </Typography>
                <Box
                  sx={{
                    opacity: 0.5,
                  }}
                >
                  <EmptyItemsIllustration />
                </Box>
              </Box>
            ) : loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "250px",
                }}
              >
                <Loader />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          {optionErrorMsg ? (
            <>
              <Alert
                sx={{
                  // my: 1,
                  fontSize: "10px",
                  padding: "0px 4px",
                }}
                severity="error"
                icon={<InfoIcon customColor={theme.palette.error.dark} />}
                variant="outlined"
              >
                {optionErrorMsg}
              </Alert>
            </>
          ) : (
            <></>
          )}
          <Box
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <Pagination
              page={page}
              count={
                totalCount
                  ? totalCount
                  : groupedOptions?.length > 0
                  ? groupedOptions?.length
                  : inputValue == ""
                  ? options?.length
                  : 0
              }
              onChangePage={handlePageChange}
              record={rowsPerPage}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const ListItem = ({
  option,
  index,
  getOptionProps,
  onChange,
  handleCloseMenu,
}) => {
  const theme = useTheme();
  return (
    <Grid
      container
      component="li"
      {...getOptionProps({ option, index })}
      key={"option_" + option.value + "_" + index}
      sx={{
        alignItems: "flex-start",
        padding: "8px 8px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      }}
      onClick={() => {
        onChange(null, option);
        handleCloseMenu();
      }}
    >
      <Grid item xs={6}>
        <Typography sx={{ fontSize: "11px", color: "#657482" }}>
          {option.label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={{ fontSize: "11px", color: "#657482", ml: 2 }}>
          {option.value}
        </Typography>
      </Grid>
    </Grid>
  );
};
