import { SvgIconProps } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

const MinusIcon = ({
  color = "secondary",
  width = 24,
  height = 24,
  sx,
  customColor,
}: SvgIconProps & {
  customColor?: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 0,
        ...sx,
      }}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 12H19"
          stroke={customColor ?? theme.palette?.[color]?.main}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Box>
  );
};

MinusIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  sx: PropTypes.object,
};

MinusIcon.defaultProps = {
  color: "secondary",
  width: 24,
  height: 24,
};

export default MinusIcon;
