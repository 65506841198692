import { LoadingButton } from "@mui/lab";
import { Alert, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { PaymentsInfo } from "src/@types/payments";
import { PostAsync } from "src/common/httpRequests";
import { FormProvider } from "src/components/hook-form";
import { API_GATEWAY } from "src/constants/settings";
import useLocales from "src/hooks/useLocales";
import { ProcessPayment } from "../PaymentInfo";
import AccountDetailsDrawer from "./AccountDetailsDrawer";
import PaymentDetailsDrawer from "./PaymentDetailsDrawer";
import PaymentMethodDetails from "./PaymentMethodDetails";
import VendorDetailsDrawer from "./VendorDetailsDrawer";

interface PaymentDrawerInfoProps {
  paymentsInfo: PaymentsInfo;
  documentIds: string[];
  handleDrawerClose: () => void;
}

const PaymentDrawerInfo: React.FC<PaymentDrawerInfoProps> = (props) => {
  const {
    paymentsInfo: {
      accountInfo,
      vendorDetails,
      totalDocumentsAmount,
      paymentMethods,
    },
    documentIds,
    handleDrawerClose,
  } = props;
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      paymentMethod: paymentMethods && paymentMethods[0].key,
      documentIds,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const processPayment = async (data: ProcessPayment) => {
    await PostAsync(`${API_GATEWAY}/api/paymentService/payments`, data)
      .then((res) => {
        if (res?.status === 200) {
          enqueueSnackbar(translate(`payment_made_successfully`), {
            variant: "success",
          });
          handleDrawerClose();
        } else {
          enqueueSnackbar(
            translate(res?.data?.Detail) ?? translate("something_went_wrong"),
            { variant: "error" }
          );
        }
      })
      .catch((err) => {
        console.log("Error", err);
        enqueueSnackbar(translate("something_went_wrong"), {
          variant: "error",
        });
      });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(processPayment)}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            {vendorDetails && vendorDetails[0].message && (
              <Alert severity="warning" variant="outlined">
                {translate(vendorDetails[0].message)}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontSize="16px">
              {translate("payment_method")}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <PaymentMethodDetails {...{ paymentMethods }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontSize="16px">
              {translate("From")}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <AccountDetailsDrawer {...{ accountInfo }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontSize="16px">
              {translate("To")}
            </Typography>
          </Grid>
          <Grid container item xs={12} sx={{ width: "100%" }}>
            {vendorDetails &&
              vendorDetails.length > 0 &&
              vendorDetails.map((vendor, index) => (
                <VendorDetailsDrawer
                  vendorDetails={vendor}
                  key={vendor.vendorCompanyId}
                />
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" fontSize="16px">
              {translate("payment_details")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PaymentDetailsDrawer {...{ totalDocumentsAmount }} />
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
              >
                {translate("process_payment")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default PaymentDrawerInfo;
