import { Table, TableContainer, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import useLocales from "src/hooks/useLocales";
import DataDescription from "../../description/dataDescription";
import TableHeader from "./TableHead";

const PreProcessedTable = forwardRef(
  (
    props: {
      k: string;
      item: any;
      handleMouseScroll: any;
      removeArrowAxis: any;
      setFieldValue: any;
      handleDeleteDialog: any;
      handleItemOpen: any;
      handleDelete: any;
      title: string;
      fieldsLabelMap: any;
      getFieldValue: any;
      handleItemOpenGeneral: any;
    },
    ref
  ) => {
    const {
      k,
      item,
      handleMouseScroll,
      removeArrowAxis,
      setFieldValue,
      handleDeleteDialog,
      handleItemOpen,
      handleDelete,
      title,
      fieldsLabelMap,
      getFieldValue,
      handleItemOpenGeneral,
    } = props;
    const theme = useTheme();
    const { translate } = useLocales();
    const tableRef = useRef<HTMLDivElement>(null);
    const getTranslatedFields = (title) => {
      let result = translate(`review_screen.review_fields.${title}`);
      if (result.indexOf("review_screen") == -1) {
        return result;
      }
      return title;
    };
    const [page, setPage] = useState(0);

    const nextPage = () => {
      if (page + 1 >= item?.length) {
        return;
      }
      setPage(page + 1);
    };

    const prevPage = () => {
      if (page - 1 < 0) {
        return;
      }
      setPage(page - 1);
    };
    const goToPage = (page_) => {
      let page = parseInt(page_);
      if (page >= item?.length || page < 0) {
        return;
      }
      setPage(page);
    };

    useImperativeHandle(ref, () => ({
      nextPage,
      prevPage,
      goToPage,
    }));
    return (
      <TableContainer
        sx={{
          borderRadius: "8px",
          border: `3px solid ${theme.palette.secondary.light}`,
          mt: 2,
        }}
      >
        <Table
          aria-label="collapsible table"
          size="small"
          sx={{
            px: 2,
            maxHeight: "35vh",
          }}
        >
          <TableHeader
            {...{
              getTranslatedFields,
              k,
              handleItemOpen,
              fieldsLabelMap,
              handleDelete,
              nextPage,
              prevPage,
              setPage,
              page,
              total: item?.length,
              handleItemOpenGeneral,
            }}
          />

          {k === "general" ? (
            <div
              id={k}
              style={{
                overflow: "auto",
                maxHeight: "30vh",
              }}
            >
              {item?.map((item_: any) => {
                return (
                  <>
                    <DataDescription
                      key={item_.key}
                      title={item_.name}
                      bgColor={item_.strokeColor}
                      description={item_.expectedFieldLabel || item_.value}
                      isHighlighted={title === item_.key ? true : false}
                      item={item_}
                      handleMouseScroll={handleMouseScroll}
                      removeArrowAxis={removeArrowAxis}
                      setFieldValue={setFieldValue}
                      handleDeleteDialog={handleDeleteDialog}
                      getFieldValue={getFieldValue}
                    />
                  </>
                );
              })}
            </div>
          ) : (
            <div
              id={k}
              ref={tableRef}
              style={{
                overflow: "auto",
                maxHeight: "30vh",
              }}
            >
              {item?.[page]?.map?.((item_: any, idx: number) => {
                return (
                  <DataDescription
                    key={item_.key}
                    title={item_.name}
                    bgColor={item_.strokeColor}
                    description={item_.expectedFieldLabel || item_.value}
                    isHighlighted={title === item_.key ? true : false}
                    item={item_}
                    handleMouseScroll={handleMouseScroll}
                    removeArrowAxis={removeArrowAxis}
                    setFieldValue={setFieldValue}
                    handleDeleteDialog={handleDeleteDialog}
                    getFieldValue={getFieldValue}
                  />
                );
              })}
            </div>
          )}
        </Table>
      </TableContainer>
    );
  }
);

export default PreProcessedTable;
