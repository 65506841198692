const EmptyItemsIllustration = () => (
  <>
    <svg
      width="73"
      height="73"
      viewBox="0 0 73 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="27.5"
        width="70"
        height="42"
        fill="white"
        stroke="#05315E"
        strokeDasharray="2 2"
      />
      <path
        d="M17 25.1562C17 21.2903 20.134 18.1562 24 18.1562H47.8C51.666 18.1562 54.8 21.2903 54.8 25.1563V49.8676C54.8 53.7336 51.666 56.8676 47.8 56.8676H24C20.134 56.8676 17 53.7336 17 49.8676V25.1562Z"
        fill="#F4F6F8"
        stroke="#0B427A"
      />
      <path
        d="M15.9496 10.5486C15.656 9.99105 14.9633 9.7717 14.4024 10.0587V10.0587C13.8415 10.3456 13.6249 11.0303 13.9185 11.5878L15.6573 14.89C15.9509 15.4476 16.6436 15.6669 17.2045 15.3799V15.3799C17.7654 15.093 17.982 14.4083 17.6884 13.8508L15.9496 10.5486Z"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <path
        d="M9.99329 15.4455C9.43284 15.1693 8.74939 15.397 8.46676 15.9541V15.9541C8.18412 16.5112 8.40932 17.1868 8.96977 17.463L12.3803 19.1442C12.9407 19.4204 13.6241 19.1927 13.9068 18.6356V18.6356C14.1894 18.0785 13.9642 17.4029 13.4038 17.1266L9.99329 15.4455Z"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <path
        d="M25.4727 18.1571H31.664V17.8964C31.664 15.5568 33.5607 13.6602 35.9002 13.6602V13.6602C38.2398 13.6602 40.1364 15.5568 40.1364 17.8964V18.1571H46.3278V18.1571C46.3278 20.6406 44.3145 22.6539 41.8309 22.6539H29.9696C27.486 22.6539 25.4727 20.6406 25.4727 18.1571V18.1571Z"
        fill="#B7C1CA"
        stroke="#0B427A"
      />
      <rect
        x="32.6406"
        y="31.0078"
        width="14"
        height="2.07"
        rx="1.035"
        fill="#B7C1CA"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <rect
        x="32.6406"
        y="39.082"
        width="14"
        height="2.07"
        rx="1.035"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <rect
        x="32.6406"
        y="47.5312"
        width="14"
        height="2.07"
        rx="1.035"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <rect
        x="24.8203"
        y="30.043"
        width="3.91034"
        height="3.85448"
        rx="1.92724"
        fill="#B7C1CA"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <rect
        x="24.8203"
        y="38.1211"
        width="3.91034"
        height="3.85448"
        rx="1.92724"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <rect
        x="24.8203"
        y="46.5703"
        width="3.91034"
        height="3.85448"
        rx="1.92724"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <ellipse
        cx="54.7992"
        cy="38.5013"
        rx="9.1"
        ry="8.97"
        fill="white"
        stroke="#0B427A"
      />
      <path
        d="M53.75 34.0305C53.75 33.4506 54.2201 32.9805 54.8 32.9805V32.9805C55.3799 32.9805 55.85 33.4506 55.85 34.0305V38.1405C55.85 38.7204 55.3799 39.1905 54.8 39.1905V39.1905C54.2201 39.1905 53.75 38.7204 53.75 38.1405V34.0305Z"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
      <path
        d="M53.75 42.9884C53.75 43.5601 54.2134 44.0234 54.785 44.0234H54.815C55.3866 44.0234 55.85 43.5601 55.85 42.9884V42.9884C55.85 42.4168 55.3866 41.9534 54.815 41.9534H54.785C54.2134 41.9534 53.75 42.4168 53.75 42.9884V42.9884Z"
        stroke="#0B427A"
        strokeLinejoin="round"
      />
    </svg>
  </>
);

export default EmptyItemsIllustration;
