import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { VendorDetailsEntity } from "src/@types/payments";
import MinusIcon from "src/assets/icon/minus";
import PlusIcon from "src/assets/icon/plus";
import useLocales from "src/hooks/useLocales";
import { formatCurrency } from "src/utils/formatNumber";

interface VendorDetailsDrawerProps {
  vendorDetails: VendorDetailsEntity;
}

const VendorDetailsDrawer: React.FC<VendorDetailsDrawerProps> = (props) => {
  const {
    vendorDetails: {
      message,
      totalAmount,
      vendorCompanyName,
      vendorCompanyId,
      vendorAccountInfo,
      documentDetails,
    },
  } = props;
  const { translate } = useLocales();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 1,
        width: "100%",
        my: 0.5,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          px: 1,
          py: 0.5,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: `secondary.light`,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="subtitle1">{vendorCompanyName}</Typography>
          <Typography
            variant="subtitle2"
            sx={{ ml: 1 }}
            color={theme.palette.error.main}
          >
            {translate(message)}
          </Typography>
        </Box>

        <Box sx={{ textAlign: "right" }}>
          <IconButton onClick={() => setExpanded(!expanded)} sx={{ p: 0 }}>
            {expanded ? (
              <MinusIcon width={18} height={18} color="primary" />
            ) : (
              <PlusIcon width={18} height={18} color="primary" />
            )}
          </IconButton>
        </Box>
      </Stack>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            mx: 1,
            my: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              color={theme.palette.text.greyLight}
            >
              {translate("vendorCompanyId")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle1">{vendorCompanyId}</Typography>
          </Box>
        </Stack>
        {vendorAccountInfo && (
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mx: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.greyLight}
                >
                  {translate("account_holder_name")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  {vendorAccountInfo.accountHolderName}
                </Typography>
              </Box>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mx: 1,
                my: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.greyLight}
                >
                  {translate("iban")}
                </Typography>
              </Box>
              <Box>
                <Typography variant="subtitle1">
                  {`****${vendorAccountInfo.iban.slice(-4)}`}
                </Typography>
              </Box>
            </Stack>
          </Box>
        )}
        <Divider />

        <Box sx={{ mx: 1, mt: 1 }}>
          <Typography variant="subtitle1" color={theme.palette.text.greyLight}>
            {translate("Documents")}
          </Typography>
        </Box>
        <List>
          {documentDetails &&
            documentDetails.length > 0 &&
            documentDetails.map((document, index) => (
              <ListItemButton key={document.documentId}>
                <ListItemText
                  primary={
                    <Stack
                      direction="row"
                      sx={{
                        mx: -1,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2">
                        {document.documentName}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.text.secondary}
                      >
                        €{formatCurrency(document.amount, "0,0.00")}
                      </Typography>
                    </Stack>
                  }
                />
              </ListItemButton>
            ))}
        </List>
      </Collapse>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mx: 1,
          my: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="subtitle1" color={theme.palette.text.greyLight}>
            {translate("amount")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">
            €{formatCurrency(totalAmount, "0,0.00")}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default VendorDetailsDrawer;
